import { EditorCtx, UICtx } from '@frontend/editor/external-providers';
import { ModalTypesEnum } from '@frontend/editor/interface';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Node } from 'reactflow';

export const useModal = () => {
  const [t] = useTranslation();
  const {
    contextModalState,
    modalState,
    modalType,
    closeModal,
    openModal,
    setModalState,
    setContextModalState,
  } = useContext(UICtx);
  const { setTargetElementID } = useContext(EditorCtx);

  const openDeleteBlockModal = useCallback(
    (blockID: string) => {
      setTargetElementID(blockID);
      openModal(ModalTypesEnum.DELETE_BLOCK);
    },
    [setTargetElementID, openModal],
  );

  const openDeleteBlocksModal = useCallback(() => {
    openModal(ModalTypesEnum.DELETE_BLOCKS);
  }, [openModal]);

  const openDeleteElementModal = useCallback(
    (targetID: string) => {
      setTargetElementID(targetID);
      openModal(ModalTypesEnum.DELETE_ELEMENT);
    },
    [setTargetElementID, openModal],
  );

  const openDeleteTextButtonModal = useCallback(
    (targetID: string) => {
      setTargetElementID(targetID);
      openModal(ModalTypesEnum.DELETE_TEXT_BUTTON);
    },
    [setTargetElementID, openModal],
  );

  const openDeleteTextButtonCellModal = useCallback(
    (handleDelete: () => void) => {
      openModal(ModalTypesEnum.DELETE);
      setModalState({
        title: t('canvas.modal.deleteTextButtonCell.title'),
        content: t('canvas.modal.deleteTextButtonCell.content'),
        info: t('canvas.modal.deleteTextButtonCell.info'),
        handleConfirm: () => {
          handleDelete();
          closeModal();
        },
      });
    },
    [openModal, setModalState, closeModal, t],
  );

  const openDeleteGalleryModal = useCallback(
    (handleDelete: () => void) => {
      openModal(ModalTypesEnum.DELETE);
      setModalState({
        title: t('canvas.modal.deleteGallery.title'),
        content: t('canvas.modal.deleteGallery.content'),
        info: t('canvas.modal.deleteGallery.info'),
        handleConfirm: () => {
          handleDelete();
          closeModal();
        },
      });
    },
    [openModal, setModalState, closeModal, t],
  );
  const openDeleteGalleryImageModal = useCallback(
    (handleDelete: () => void) => {
      openModal(ModalTypesEnum.DELETE);
      setModalState({
        title: t('canvas.modal.deleteGalleryImage.title'),
        content: t('canvas.modal.deleteGallery.content'),
        info: t('canvas.modal.deleteGallery.info'),
        handleConfirm: () => {
          handleDelete();
          closeModal();
        },
      });
    },
    [openModal, setModalState, closeModal, t],
  );

  const openDeleteConfirmModal = useCallback(
    (handleDelete: () => void) => {
      openModal(ModalTypesEnum.DELETE);
      setModalState({
        title: t('common.delete'),
        content: t('canvas.modal.deleteTextButtonCell.content'),
        info: t('canvas.modal.deleteTextButtonCell.info'),
        handleConfirm: () => {
          handleDelete();
          closeModal();
        },
      });
    },
    [openModal, setModalState, closeModal, t],
  );

  const openAddPersistentMenuModal = useCallback(() => {
    openModal(ModalTypesEnum.ADD_PERSISTENT_MENU);
  }, [openModal]);

  const openAddCommentAutoReplyModal = useCallback(() => {
    openModal(ModalTypesEnum.ADD_COMMENT_AUTO_REPLY);
  }, [openModal]);

  const openContextMenu = useCallback(
    (
      e: React.MouseEvent,
      type:
        | ModalTypesEnum.NODE_CONTEXT_MENU
        | ModalTypesEnum.SELECTION_CONTEXT_MENU,
      node?: Node,
    ) => {
      e.preventDefault();
      openModal(type);
      setContextModalState({
        node,
        mouseX: e.clientX + 2,
        mouseY: e.clientY - 6,
      });
    },
    [openModal, setContextModalState],
  );

  return {
    openModal: modalType,
    modalState: modalState,
    closeModal: closeModal,
    openContextMenu,
    openAddCommentAutoReplyModal,
    openDeleteTextButtonCellModal,
    openAddPersistentMenuModal,
    openDeleteBlockModal,
    openDeleteBlocksModal,
    openDeleteElementModal,
    openDeleteTextButtonModal,
    openDeleteConfirmModal,
    openDeleteGalleryModal,
    openDeleteGalleryImageModal,
  };
};

export default useModal;

import { AutocompleteV2, Button, OptionTypeV2 } from '@frontend/components/ui';
import {
  useGetCouponOption,
  useGetProjectID,
  useIsDrawerDataUpdate,
} from '@frontend/editor/data-access';
import { EditorCtx, UICtx } from '@frontend/editor/external-providers';
import {
  CouponType,
  CouponTypesEnum,
  DrawerTypesEnum,
  ElementTypesEnum,
} from '@frontend/editor/interface';
import { PAGE_COUPON_LIST, usePath } from '@frontend/sorghum/utils';
import { Link, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { isEmpty } from 'lodash';
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { EditorDrawer } from '../editor-drawer/editor-drawer';

const ContainerStyled = styled('div')(({ theme }) => ({
  whiteSpace: 'break-spaces',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  padding: '24px',
}));

const TopWrapperStyled = styled('div')(({ theme }) => ({}));

export const DrawerCoupon = () => {
  const { data: projectID } = useGetProjectID();
  const [t] = useTranslation();
  const { navigateToProjectPage } = usePath();
  const state = useContext(EditorCtx);
  const uiState = useContext(UICtx);
  const element = state.getElement(state.onFocusID) as CouponType;

  const { data: couponOptions } = useGetCouponOption({
    projectID: projectID as string,
  });

  const { isDrawerDataUpdate } = useIsDrawerDataUpdate();

  const [couponValue, setCouponValue] = useState<string | null | undefined>(
    null,
  );

  const { title, subtitle1, subtitle2 } = useMemo(() => {
    switch (element?.type) {
      case CouponTypesEnum.SEND:
        return {
          title: t('canvas.coupon.send.title'),
          subtitle1: t('canvas.coupon.send.subtitle1'),
          subtitle2: t('canvas.coupon.send.subtitle2'),
        };
      case CouponTypesEnum.SHOW:
        return {
          title: t('canvas.coupon.show.title'),
          subtitle1: t('canvas.coupon.show.subtitle1'),
          subtitle2: t('canvas.coupon.show.subtitle2'),
        };
      case CouponTypesEnum.REDEEM:
      default:
        return {
          title: t('canvas.coupon.redeem.title'),
          subtitle1: t('canvas.coupon.redeem.subtitle1'),
          subtitle2: t('canvas.coupon.redeem.subtitle2'),
        };
    }
  }, [element?.type, t]);

  const valueOptions = useMemo(() => {
    if (couponOptions) {
      return couponOptions.map((i) => ({
        label: i.name,
        value: i.id,
      }));
    } else {
      return [];
    }
  }, [couponOptions]);

  const handleApply = useCallback(() => {
    if (isEmpty(couponValue)) {
      return;
    }
    state.updateElementData(element.id, 'couponValue', couponValue);
    uiState.setDrawerType(DrawerTypesEnum.CLOSE);
  }, [couponValue, state, element?.id, uiState]);

  const handleChange = useCallback(
    (_: object, newValue: OptionTypeV2 | null) => {
      if (!newValue) {
        setCouponValue('');
        return;
      }
      setCouponValue(newValue.value);
    },
    [],
  );

  const gotoCouponPage = useCallback(() => {
    uiState.setDrawerType(DrawerTypesEnum.CLOSE);
    navigateToProjectPage(PAGE_COUPON_LIST);
  }, [navigateToProjectPage, uiState]);

  useEffect(() => {
    if (element) {
      setCouponValue(element.couponValue);
    }
  }, [element]);

  useEffect(() => {
    if (element && uiState.drawerType === DrawerTypesEnum.COUPON) {
      if (
        isDrawerDataUpdate(
          { couponValue },
          { couponValue: element.couponValue },
        )
      ) {
        state.setIsDrawerUpdate(true);
      } else {
        state.setIsDrawerUpdate(false);
      }
    }
  }, [couponValue, element, state, uiState.drawerType, isDrawerDataUpdate]);

  if (element && element.elementType === ElementTypesEnum.COUPON) {
    return (
      <EditorDrawer drawerType={DrawerTypesEnum.COUPON}>
        <ContainerStyled>
          <TopWrapperStyled>
            <Typography
              sx={{
                mb: '12px',
              }}
              variant="h6"
              color="grey.900"
            >
              {title}
            </Typography>
            <Typography
              sx={{
                mb: '24px',
              }}
              variant="body2"
            >
              {subtitle1}
              <Link onClick={gotoCouponPage} color="info.main">
                <Trans i18nKey="canvas.coupon.click" />
              </Link>
              {subtitle2}
            </Typography>
            <AutocompleteV2
              // 必填顯示紅框
              required
              errorMessage={couponValue ? undefined : ' '}
              value={couponValue}
              onChange={handleChange}
              noOptionsText={t('canvas.coupon.empty')}
              placeholder={t('canvas.coupon.placeholder')}
              label={t('canvas.coupon.title')}
              options={valueOptions}
            />
          </TopWrapperStyled>
          <Button
            onClick={handleApply}
            size="large"
            variant="contained"
            fullWidth
            disabled={!state.isDrawerUpdate}
            sx={{ marginTop: '24px' }}
          >
            <Trans i18nKey="common.apply" />
          </Button>
        </ContainerStyled>
      </EditorDrawer>
    );
  } else {
    return null;
  }
};

export default memo(DrawerCoupon);

export enum ProjectStatus {
  TRIAL = 1,
  ADVANCE = 2,
  VIEW_ONLY = 3,
}

export interface Project {
  projectId: string;
  projectName: string;
  picture?: string;
  isLinked: boolean;
  shortCode: string;
  timezone: string;
  timezoneOffset: number;
  paymentStatus: number;
  step: number;
  status: ProjectStatus;
  expiryDate?: string;
  permission: boolean;
}

import { MenuItem } from '@frontend/components/ui';
import { useCanvasCollect, useCanvasCopy } from '@frontend/editor/data-access';
import { UICtx } from '@frontend/editor/external-providers';
import {
  BlockTypesEnum,
  ModalTypesEnum,
  NodeTypesEnum,
} from '@frontend/editor/interface';
import { isValueInEnum } from '@frontend/editor/utils';
import { ClickAwayListener, Fade, Menu } from '@mui/material';
import { find, isArray } from 'lodash';
import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Node } from 'reactflow';

const NodeContextMenu: FC = () => {
  const [t] = useTranslation();
  const { contextModalState, modalType, closeModal } = useContext(UICtx);
  const { selectedNodes, selectedNodeIDs } = useCanvasCollect();
  const { copyBlocks } = useCanvasCopy();

  const handleDelete = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const handleCopy = useCallback(() => {
    if (selectedNodeIDs.length > 0) {
      copyBlocks(selectedNodeIDs);
    } else if (contextModalState?.node) {
      copyBlocks([contextModalState.node.id]);
    }

    closeModal();
    return;
  }, [selectedNodeIDs, contextModalState?.node, closeModal, copyBlocks]);

  const getMenuItemByNodeType = useCallback(
    (currentNode?: Node[] | Node) => {
      const COPY_ITEM = {
        content: t('common.copy'),
        onClick: handleCopy,
      };
      const DELETE_ITEM = {
        content: t('common.delete'),
        onClick: handleDelete,
      };

      if (!currentNode) return [COPY_ITEM, DELETE_ITEM];

      if (isArray(currentNode)) {
        if (
          find(currentNode, (i) =>
            isValueInEnum(i.type as BlockTypesEnum, BlockTypesEnum),
          )
        ) {
          return [COPY_ITEM, DELETE_ITEM];
        }

        if (find(currentNode, (i) => i.type === NodeTypesEnum.NOTE_NODE)) {
          return [DELETE_ITEM];
        } else return [];
      } else {
        switch (currentNode.type) {
          case NodeTypesEnum.ENTRY_POINT_NODE: {
            return [];
          }
          case NodeTypesEnum.NOTE_NODE: {
            return [DELETE_ITEM];
          }
          default: {
            return [COPY_ITEM, DELETE_ITEM];
          }
        }
      }
    },
    [handleCopy, handleDelete, t],
  );

  const isOpen = useMemo(
    () =>
      modalType === ModalTypesEnum.NODE_CONTEXT_MENU ||
      modalType === ModalTypesEnum.SELECTION_CONTEXT_MENU,
    [modalType],
  );

  const menuItems = useMemo(() => {
    if (modalType === ModalTypesEnum.SELECTION_CONTEXT_MENU) {
      return getMenuItemByNodeType(selectedNodes);
    } else {
      return getMenuItemByNodeType(contextModalState?.node);
    }
  }, [
    contextModalState?.node,
    getMenuItemByNodeType,
    modalType,
    selectedNodes,
  ]);

  useEffect(() => {
    const handleRightClick = (e: MouseEvent) => {
      e.preventDefault();
    };

    if (isOpen) {
      document.addEventListener('contextmenu', handleRightClick);
    } else {
      document.removeEventListener('contextmenu', handleRightClick);
    }

    return () => {
      document.removeEventListener('contextmenu', handleRightClick);
    };
  }, [isOpen]);

  if (menuItems.length <= 0) {
    return null;
  }

  return (
    <ClickAwayListener onClickAway={closeModal}>
      <Menu
        open={isOpen}
        PaperProps={{
          sx: {
            minWidth: '200px',
          },
        }}
        anchorReference="anchorPosition"
        anchorPosition={
          contextModalState
            ? {
                top: contextModalState.mouseY,
                left: contextModalState.mouseX,
              }
            : undefined
        }
        onClose={closeModal}
        onClick={closeModal}
        TransitionComponent={Fade}
      >
        {menuItems?.map((item) => (
          <MenuItem
            key={item.content}
            content={item.content}
            onClick={item.onClick}
          />
        ))}
      </Menu>
    </ClickAwayListener>
  );
};

export default React.memo(NodeContextMenu);

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box,
  ListItemText,
  Autocomplete as MuiAutocomplete,
  Paper,
  TextField,
  Tooltip,
  createFilterOptions,
} from '@mui/material';
import { styled } from '@mui/system';
import { isNull } from 'lodash';
import {
  FC,
  FocusEventHandler,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import AutocompleteNestedGroup from '../autocomplete-nested-group/autocomplete-nested-group';

export interface AutocompleteWithNestedOptionType {
  label: string;
  value: string | number | undefined | null;
  group?: string | null;
  divider?: boolean;
  onClick?: () => void;
  // if true 不會出現在搜尋結果
  exclude?: boolean;
  key?: string;
}

export interface AutocompleteWithNestedOptionsProps {
  value: string | number | null | undefined;
  disabled?: boolean;
  noOptionsText?: string;
  label?: string;
  placeholder?: string;
  options: AutocompleteWithNestedOptionType[];
  customMessage?: JSX.Element;
  errorMessage?: string;
  required?: boolean;
  maxHeight?: string;
  onChange?: (
    event: SyntheticEvent<Element, Event>,
    newValue: AutocompleteWithNestedOptionType | null,
  ) => void;
  onFocus?: (val: AutocompleteWithNestedOptionType | null | undefined) => void;
  autoFocus?: boolean;
  inputReadOnly?: boolean;
  disableClearable?: boolean;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  blurOnSelect?: boolean;
  isShowTooltip?: boolean;
}

const PaperStyled = styled(Paper)<{ $maxHeight?: string }>(
  ({ theme, $maxHeight }) => ({
    width: '312px',
    height: 'fit-content',

    ul: {
      height: '100%',
      padding: 0,
      maxHeight: $maxHeight ? $maxHeight : '70vh',
      overflow: 'auto',
    },
  }),
);

const OptionItemStyled = styled(ListItemText)(({ theme }) => ({
  wordBreak: 'break-all',
  paddingLeft: '36px',
}));

// 排除某些選項出現在搜尋結果
const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option: AutocompleteWithNestedOptionType) =>
    option.exclude ? '' : option.label,
});

export const AutocompleteWithNestedOptions: FC<
  AutocompleteWithNestedOptionsProps
> = ({
  value,
  label,
  disabled,
  required,
  maxHeight,
  noOptionsText,
  options,
  placeholder,
  customMessage,
  errorMessage,
  autoFocus = false,
  inputReadOnly = false,
  disableClearable = false,
  blurOnSelect = true,
  isShowTooltip = false,
  onChange = () => undefined,
  onFocus = () => undefined,
  onBlur = (event) => undefined,
}: AutocompleteWithNestedOptionsProps) => {
  const defaultValue = options.find((x) => x.value === value);
  const [inputValue, setInputValue] = useState(defaultValue?.label || '');
  const [selectValue, setSelectValue] = useState<
    AutocompleteWithNestedOptionType | null | undefined
  >(defaultValue || null);

  useEffect(() => {
    if (defaultValue) {
      setSelectValue(defaultValue);
    } else {
      setSelectValue(null);
    }
  }, [defaultValue]);

  const onOptionChange = useCallback(
    (
      event: SyntheticEvent<Element, Event>,
      newValue: AutocompleteWithNestedOptionType | null | undefined,
    ) => {
      if (newValue) {
        setSelectValue(newValue);
      } else {
        setSelectValue(null);
      }
      onChange && onChange(event, newValue ?? null);
    },
    [onChange],
  );

  const handleFocus = useCallback(() => {
    onFocus(selectValue);
  }, [onFocus, selectValue]);

  return (
    <MuiAutocomplete
      sx={{
        width: '100%',
      }}
      disableClearable={disableClearable}
      filterOptions={filterOptions}
      noOptionsText={noOptionsText}
      isOptionEqualToValue={(
        option: AutocompleteWithNestedOptionType,
        value: AutocompleteWithNestedOptionType,
      ) => option.value === value.value}
      value={selectValue}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      disabled={disabled}
      popupIcon={<KeyboardArrowDownIcon />}
      size="small"
      options={options}
      getOptionLabel={(option: AutocompleteWithNestedOptionType) =>
        option.label
      }
      groupBy={(option) => option.group || ''}
      PaperComponent={(props) => (
        <PaperStyled elevation={8} $maxHeight={maxHeight} {...props} />
      )}
      blurOnSelect={blurOnSelect}
      renderGroup={(params) => (
        <AutocompleteNestedGroup {...params} inputValue={inputValue} />
      )}
      renderOption={(props, option: AutocompleteWithNestedOptionType) => (
        <Box component="li" {...props}>
          {option.group === 'All' ? (
            <ListItemText primary={option.label} />
          ) : (
            <OptionItemStyled primary={option.label} />
          )}
        </Box>
      )}
      onChange={onOptionChange}
      renderInput={(params) => {
        if (isShowTooltip && !isNull(params.inputProps.value)) {
          return (
            <Tooltip
              placement="right"
              title={params.inputProps.value as string}
            >
              <TextField
                {...params}
                required
                onFocus={handleFocus}
                variant="outlined"
                size="small"
                label={label}
                disabled={disabled}
                placeholder={placeholder}
                fullWidth
                error={!!errorMessage || !!customMessage}
                autoFocus={autoFocus}
                onBlur={onBlur}
                inputProps={{
                  ...params.inputProps,
                  readOnly: inputReadOnly,
                  ...(inputReadOnly && { style: { cursor: 'pointer' } }),
                }}
                helperText={errorMessage}
              />
            </Tooltip>
          );
        }

        return (
          <TextField
            {...params}
            required
            onFocus={handleFocus}
            variant="outlined"
            size="small"
            label={label}
            disabled={disabled}
            placeholder={placeholder}
            fullWidth
            error={!!errorMessage || !!customMessage}
            helperText={errorMessage}
            autoFocus={autoFocus}
            onBlur={onBlur}
            inputProps={{
              ...params.inputProps,
              readOnly: inputReadOnly,
              ...(inputReadOnly && { style: { cursor: 'pointer' } }),
            }}
          />
        );
      }}
    />
  );
};

export default AutocompleteWithNestedOptions;

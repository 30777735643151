import { EditorCtx, UICtx } from '@frontend/editor/external-providers';
import {
  ActionCellTypesEnum,
  CategoryValueTypesEnum,
  CouponCellTypesEnum,
  DrawerTypesEnum,
  ElementTypesEnum,
  NodeTypesEnum,
  Rules,
  TextButtonCellTypesEnum,
} from '@frontend/editor/interface';
import { getUniquePosition, uuid } from '@frontend/editor/utils';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Node, XYPosition, useReactFlow, useViewport } from 'reactflow';
import useCanvasCollect from '../use-canvas-collect/use-canvas-collect';
import { useCanvasTemplate } from '../use-canvas-template/use-canvas-template';
import useGetCategoryValue from '../use-get-category-value/use-get-category-value';
import useGetProjectID from '../use-get-project-id/use-get-project-id';

export function useCanvasAdd() {
  const state = useContext(EditorCtx);
  const uiState = useContext(UICtx);
  const { setCenter } = useReactFlow();
  const [t] = useTranslation();
  const { data: projectID } = useGetProjectID();
  const { data: categoryValues } = useGetCategoryValue({
    projectID: projectID as string,
    type: CategoryValueTypesEnum.RN_TOPIC,
  });
  const { zoom, x: viewportCenterX, y: viewportCenterY } = useViewport();
  const innerWidth = state.ref.current?.clientWidth || 0;
  const innerHeight = state.ref.current?.clientHeight || 0;
  const centerX = (innerWidth / 2 - viewportCenterX) / zoom;
  const centerY = (innerHeight / 2 - viewportCenterY) / zoom;
  const {
    addSendCouponTemplate,
    addShowCouponTemplate,
    addRedeemCouponTemplate,
  } = useCanvasTemplate();
  const { NEW_ELEMENT_POSITION_DISTANCE } = useCanvasCollect();

  const focusToNode = useCallback(
    (newPosition: XYPosition, id?: string) => {
      // 要注意不要被開啟的 drawer 擋住
      setCenter(newPosition.x, newPosition.y, {
        zoom,
        duration: 1000,
      });

      if (id) {
        state.setOnFocusID(id);
      }
    },
    [setCenter, state, zoom],
  );

  const generateNewElement = useCallback(
    (type: ElementTypesEnum, additionalData?: any) => {
      switch (type) {
        case ElementTypesEnum.BLOCK:
          return {
            id: uuid(),
            inputID: uuid(),
            outputID: uuid(),
            elementType: type,
            position: {
              x: 0,
              y: 0,
            },
            targetID: '',
            children: [],
            data: {},
            createDate: new Date().getTime(),
            ...additionalData,
          };
        case ElementTypesEnum.SHARE_LINK:
          return {
            id: uuid(),
            inputID: uuid(),
            outputID: uuid(),
            shareLinkID: uuid(),
            cellID: uuid(),
            elementType: type,
            position: {
              x: 0,
              y: 0,
            },
            targetID: '',
            children: [],
            createDate: new Date().getTime(),
            data: {
              fileID: '',
              fileUrl: '',
              title: '',
              buttonText: '',
            },
            ...additionalData,
          };
        case ElementTypesEnum.COUPON:
          return {
            id: uuid(),
            elementID: uuid(),
            inputID: uuid(),
            position: {
              x: 0,
              y: 0,
            },
            data: {},
            targetID: '',
            elementType: type,
            children: [],
            createDate: new Date().getTime(),
            ...additionalData,
          };
        case ElementTypesEnum.COUPON_CELL:
          return {
            id: uuid(),
            outputID: uuid(),
            elementType: type,
            createDate: new Date().getTime(),
            cellType: CouponCellTypesEnum.SUCCESS,
            ...additionalData,
          };
        case ElementTypesEnum.TEXT_BUTTON:
          return {
            id: uuid(),
            outputID: uuid(),
            elementType: type,
            targetID: '',
            children: [],
            data: {},
            createDate: new Date().getTime(),
            ...additionalData,
          };
        case ElementTypesEnum.TEXT_BUTTON_CELL:
          return {
            id: uuid(),
            outputID: uuid(),
            elementType: type,
            targetID: '',
            children: [],
            data: {},
            createDate: new Date().getTime(),
            label: '',
            ...additionalData,
          };
        case ElementTypesEnum.RECURRING_NOTIFICATION:
          return {
            id: uuid(),
            outputID: uuid(),
            cellID: uuid(),
            elementType: type,
            elementName: t('canvas.recurringNotification.drawer.title'),
            targetID: '',
            children: [],
            data: {},
            createDate: new Date().getTime(),
            fileID: '',
            fileUrl: '',
            topic:
              categoryValues && categoryValues.length > 0
                ? categoryValues[0].id
                : '',
            title: '',
            sourceID: '',
            imageType: 1,
            reoptin: false,
            ...additionalData,
          };
        case ElementTypesEnum.ENTRY_POINT:
          return {
            id: uuid(),
            outputID: uuid(),
            elementType: type,
            position: {
              x: 0,
              y: 0,
            },
            targetID: '',
            children: [],
            data: {},
            createDate: new Date().getTime(),
            ...additionalData,
          };
        case ElementTypesEnum.IMAGE:
          return {
            id: uuid(),
            elementType: type,
            children: [],
            createDate: new Date().getTime(),
            elementName: t('canvas.editorImage.label'),
            ...additionalData,
          };
        case ElementTypesEnum.ACTION:
          return {
            id: uuid(),
            inputID: uuid(),
            outputID: uuid(),
            position: {
              x: 0,
              y: 0,
            },
            data: {},
            children: [],
            targetID: '',
            elementType: type,
            createDate: new Date().getTime(),
            ...additionalData,
          };
        case ElementTypesEnum.ACTION_ELEMENT:
          return {
            id: uuid(),
            elementType: type,
            createDate: new Date().getTime(),
            ...additionalData,
          };
        case ElementTypesEnum.CONDITION:
          return {
            id: uuid(),
            elementID: uuid(),
            inputID: uuid(),
            outputID: uuid(),
            position: {
              x: 0,
              y: 0,
            },
            data: {},
            targetID: '',
            elementType: type,
            children: [],
            createDate: new Date().getTime(),
            ...additionalData,
          };
        case ElementTypesEnum.CONDITION_CELL:
          return {
            id: uuid(),
            outputID: uuid(),
            elementType: type,
            createDate: new Date().getTime(),
            ...additionalData,
          };
        case ElementTypesEnum.COLLECT_USER_ANSWER:
          return {
            id: uuid(),
            outputID: uuid(),
            cellID: uuid(),
            elementType: type,
            elementName: t('canvas.blockMenu.collectUserAnswerButton'),
            targetID: '',
            children: [],
            data: {
              text: '',
              attributeId: '',
              periodType: 4,
              periodValue: 30,
              isAllowFreeInput: false,
              freeInputID: uuid(),
            },
            createDate: new Date().getTime(),
            title: '',
            sourceID: '',
            ...additionalData,
          };
        default:
          return {
            id: uuid(),
            inputID: uuid(),
            outputID: uuid(),
            elementType: type,
            children: [],
            createDate: new Date().getTime(),
            ...additionalData,
          };
      }
    },
    [categoryValues, t],
  );

  // 取得預設的 elements
  const getInitialElements = () => {
    // 新增 flow 一定會有 entry-point 跟一個 block
    const block = generateNewElement(ElementTypesEnum.BLOCK, {
      position: {
        x: 400,
        y: 0,
      },
      label: t('canvas.blockDefaultTitle', {
        count: 1,
      }),
    });

    const entry = generateNewElement(ElementTypesEnum.ENTRY_POINT, {
      targetID: block.id,
    });

    const newChart = [entry, block];
    return newChart;
  };

  const addTextButton = useCallback(
    (parentID: string, addIndex?: number) => {
      const cellItem = generateNewElement(ElementTypesEnum.TEXT_BUTTON, {
        nodeID: parentID,
        parentID,
      });

      state.addElement(cellItem, parentID, addIndex);
      state.setOnFocusCellID(cellItem.id);
    },
    [generateNewElement, state],
  );

  const addCollectUserAnswer = useCallback(
    (parentID: string, addIndex?: number) => {
      const cellItem = generateNewElement(
        ElementTypesEnum.COLLECT_USER_ANSWER,
        {
          nodeID: parentID,
          parentID,
        },
      );
      state.addElement(cellItem, parentID, addIndex);
      state.setOnFocusCellID(cellItem.id);
    },
    [generateNewElement, state],
  );

  const addRecurringNotification = useCallback(
    (parentID: string, addIndex?: number) => {
      const cellItem = generateNewElement(
        ElementTypesEnum.RECURRING_NOTIFICATION,
        {
          nodeID: parentID,
          parentID,
        },
      );
      state.addElement(cellItem, parentID, addIndex);
      state.setOnFocusCellID(cellItem.id);
    },
    [generateNewElement, state],
  );

  // 新增 image 元件
  const addImage = useCallback(
    (parentID: string, addIndex?: number) => {
      const image = generateNewElement(ElementTypesEnum.IMAGE, {
        nodeID: parentID,
        parentID,
      });
      state.addElement(image, parentID, addIndex);
    },
    [generateNewElement, state],
  );

  // 新增 Gallery 元件
  const addGallery = useCallback(
    (parentID: string, addIndex?: number) => {
      const newElement = generateNewElement(ElementTypesEnum.GALLERY, {
        nodeID: parentID,
        parentID,
        ratio: 2,
      });
      state.addElement(newElement, parentID, addIndex);
      const newCell = generateNewElement(ElementTypesEnum.GALLERY_IMAGE, {
        nodeID: parentID,
        parentID: newElement.id,
      });
      state.addElement(newCell, newElement.id);

      state.setOnFocusCellID(newCell.id);
    },
    [generateNewElement, state],
  );

  // 新增 Gallery 元件
  const addGalleryImage = useCallback(
    (nodeID: string, parentID: string, addIndex?: number) => {
      const newElement = generateNewElement(ElementTypesEnum.GALLERY_IMAGE, {
        nodeID,
        parentID,
      });
      state.addElement(newElement, parentID, addIndex);
    },
    [generateNewElement, state],
  );

  const addCellButton = useCallback(
    (parentID: string) => {
      const textButtonCell = generateNewElement(
        ElementTypesEnum.TEXT_BUTTON_CELL,
        {
          buttonType: TextButtonCellTypesEnum.BLOCK,
          buttonData: {},
          parentID,
        },
      );

      state.addElement(textButtonCell, parentID);
      state.setOnFocusCellID(textButtonCell.id);
    },
    [generateNewElement, state],
  );

  const addOptionCellButton = useCallback(
    (parentID: string, type: string) => {
      const textButtonCell = generateNewElement(ElementTypesEnum.OPTION_CELL, {
        data: {
          type,
        },
        parentID,
      });

      state.addElement(textButtonCell, parentID);
    },
    [generateNewElement, state],
  );

  const addBlock = useCallback(
    (
      id?: string,
      position?: XYPosition,
      focus = true,
      initialData?: object,
    ): string => {
      // 新 block 的 x,y 座標
      const newPosition =
        position ||
        getUniquePosition(
          centerX,
          centerY,
          state.elements,
          NEW_ELEMENT_POSITION_DISTANCE,
        );

      const block = generateNewElement(ElementTypesEnum.BLOCK, {
        position: {
          x: newPosition.x,
          y: newPosition.y,
        },
        label: t('canvas.blockDefaultTitle', {
          count: state.blockSerialNumber + 1,
        }),
        ...initialData,
      });

      state.addElement(block);
      state.setBlockSerialNumber(state.blockSerialNumber + 1);

      if (id) {
        state.connectElement(id, block.id);
      }

      if (focus) {
        focusToNode(newPosition, block.id);
      }

      return block.id;
    },
    [
      NEW_ELEMENT_POSITION_DISTANCE,
      centerX,
      centerY,
      focusToNode,
      generateNewElement,
      state,
      t,
    ],
  );

  const addNodeEditorMenu = useCallback(
    (sourceID: string, nodeID: string) => {
      const id = uuid();
      const edgeID = uuid();
      const parentBlock = state.getElement(nodeID);

      const _position = parentBlock?.position || { x: 0, y: 0 };

      if (parentBlock) {
        const newNode = {
          id,
          position: {
            x: _position.x + 500,
            y: _position.y,
          },
          data: { sourceOutputID: sourceID },
          type: NodeTypesEnum.CREATE_MENU_NODE,
        } as Node;

        state.setNodes([...state.nodes, newNode]);
        state.setEdges([
          ...state.edges,
          {
            id: edgeID,
            source: nodeID,
            sourceHandle: sourceID,
            target: id,
          },
        ]);
        // 為避免透過 go to block 新增 block 時 create block menu 的位置超出畫布，調整 focus 位置以避免其發生
        focusToNode({ x: newNode.position.x, y: newNode.position.y + 200 });
      }
    },
    [focusToNode, state],
  );

  const addShareLink = useCallback(
    (id?: string, position?: XYPosition) => {
      // 新 block 的 x,y 座標
      const newPosition =
        position ||
        getUniquePosition(
          centerX,
          centerY,
          state.elements,
          NEW_ELEMENT_POSITION_DISTANCE,
        );

      const shareLink = generateNewElement(ElementTypesEnum.SHARE_LINK, {
        position: {
          x: newPosition.x ?? 0,
          y: newPosition.y ?? 0,
        },
        label: t('canvas.shareLinkDefaultTitle', {
          count: state.blockSerialNumber + 1,
        }),
      });

      state.addElement(shareLink);
      state.setBlockSerialNumber(state.blockSerialNumber + 1);

      if (id) {
        state.connectElement(id, shareLink.id);
      }

      focusToNode(newPosition, shareLink.id);
      return shareLink.id;
    },
    [
      NEW_ELEMENT_POSITION_DISTANCE,
      centerX,
      centerY,
      focusToNode,
      generateNewElement,
      state,
      t,
    ],
  );

  const addSendCoupon = useCallback(
    (id?: string, position?: XYPosition, focus = true) => {
      const newPosition =
        position ||
        getUniquePosition(
          centerX,
          centerY,
          state.elements,
          NEW_ELEMENT_POSITION_DISTANCE,
        );

      const template = addSendCouponTemplate(newPosition);
      const couponElement = template.find(
        (i) => i.elementType === ElementTypesEnum.COUPON,
      );

      if (couponElement) {
        if (id) {
          state.connectElement(id, couponElement.id);
        }

        if (focus) {
          focusToNode(newPosition, couponElement.id);
        } else {
          state.setOnFocusID(couponElement.id);
        }
        uiState.setDrawerType(DrawerTypesEnum.COUPON);
      }
      return couponElement?.id ?? '';
    },
    [
      NEW_ELEMENT_POSITION_DISTANCE,
      addSendCouponTemplate,
      centerX,
      centerY,
      focusToNode,
      state,
      uiState,
    ],
  );

  const addShowCoupon = useCallback(
    (id?: string, position?: XYPosition, focus = true) => {
      const newPosition =
        position ||
        getUniquePosition(
          centerX,
          centerY,
          state.elements,
          NEW_ELEMENT_POSITION_DISTANCE,
        );

      const template = addShowCouponTemplate(newPosition);
      const couponElement = template.find(
        (i) => i.elementType === ElementTypesEnum.COUPON,
      );

      if (couponElement) {
        if (id) {
          state.connectElement(id, couponElement.id);
        }
        if (focus) {
          focusToNode(newPosition, couponElement.id);
        } else {
          state.setOnFocusID(couponElement.id);
        }
        uiState.setDrawerType(DrawerTypesEnum.COUPON);
      }
      return couponElement?.id ?? '';
    },
    [
      NEW_ELEMENT_POSITION_DISTANCE,
      addShowCouponTemplate,
      centerX,
      centerY,
      focusToNode,
      state,
      uiState,
    ],
  );

  const addRedeemCoupon = useCallback(
    (id?: string, position?: XYPosition, focus = true) => {
      const newPosition =
        position ||
        getUniquePosition(
          centerX,
          centerY,
          state.elements,
          NEW_ELEMENT_POSITION_DISTANCE,
        );

      const template = addRedeemCouponTemplate(newPosition);
      const couponElement = template.find(
        (i) => i.elementType === ElementTypesEnum.COUPON,
      );
      if (couponElement) {
        if (id) {
          state.connectElement(id, couponElement.id);
        }
        if (focus) {
          focusToNode(newPosition, couponElement.id);
        } else {
          state.setOnFocusID(couponElement.id);
        }
        uiState.setDrawerType(DrawerTypesEnum.COUPON);
      }
      return couponElement?.id ?? '';
    },
    [
      NEW_ELEMENT_POSITION_DISTANCE,
      addRedeemCouponTemplate,
      centerX,
      centerY,
      focusToNode,
      state,
      uiState,
    ],
  );

  const addCondition = useCallback(
    (id?: string, position?: XYPosition, focus = true) => {
      // 新 block 的 x,y 座標
      const newPosition =
        position ||
        getUniquePosition(
          centerX,
          centerY,
          state.elements,
          NEW_ELEMENT_POSITION_DISTANCE,
        );

      const newElement = generateNewElement(ElementTypesEnum.CONDITION, {
        position: {
          x: newPosition.x ?? 0,
          y: newPosition.y ?? 0,
        },
      });

      newElement.label = t('canvas.conditionDefaultTitle', {
        count: state.blockSerialNumber + 1,
      });

      state.addElement(newElement);
      state.setBlockSerialNumber(state.blockSerialNumber + 1);

      if (id) {
        state.connectElement(id, newElement.id);
      }

      const newCell = generateNewElement(ElementTypesEnum.CONDITION_CELL, {
        nodeID: id,
        parentID: id,
        rule: Rules.IS,
      });
      state.addElement(newCell, newElement.id);

      if (focus) {
        focusToNode(newPosition, newElement.id);
      }
      state.setOnFocusCellID(newCell.id);
      uiState.setDrawerType(DrawerTypesEnum.CONDITION_CELL);

      return newElement.id;
    },
    [
      NEW_ELEMENT_POSITION_DISTANCE,
      centerX,
      centerY,
      focusToNode,
      generateNewElement,
      state,
      t,
      uiState,
    ],
  );

  const addConditionElse = useCallback(
    (parentID: string, addIndex?: number) => {
      const newElement = generateNewElement(ElementTypesEnum.CONDITION_CELL, {
        nodeID: parentID,
        parentID,
        rule: Rules.ELSE,
      });

      state.addElement(newElement, parentID, addIndex);
      state.setOnFocusCellID(newElement.id);
    },
    [generateNewElement, state],
  );

  const addAction = useCallback(
    (id?: string, position?: XYPosition, focus = true) => {
      // 新 block 的 x,y 座標
      const newPosition =
        position ||
        getUniquePosition(
          centerX,
          centerY,
          state.elements,
          NEW_ELEMENT_POSITION_DISTANCE,
        );

      const newElement = generateNewElement(ElementTypesEnum.ACTION, {
        position: {
          x: newPosition.x ?? 0,
          y: newPosition.y ?? 0,
        },
      });

      newElement.label = t('canvas.actionDefaultTitle', {
        count: state.blockSerialNumber + 1,
      });

      state.addElement(newElement);
      state.setBlockSerialNumber(state.blockSerialNumber + 1);

      if (id) {
        state.connectElement(id, newElement.id);
      }
      if (focus) {
        focusToNode(newPosition, newElement.id);
      }
      uiState.setDrawerType(DrawerTypesEnum.CONDITION_CELL);

      return newElement.id;
    },
    [
      NEW_ELEMENT_POSITION_DISTANCE,
      centerX,
      centerY,
      focusToNode,
      generateNewElement,
      state,
      t,
      uiState,
    ],
  );

  const addActionSetAttribute = useCallback(
    (parentID: string, addIndex?: number) => {
      const cellItem = generateNewElement(ElementTypesEnum.ACTION_ELEMENT, {
        nodeID: parentID,
        parentID,
        actionType: ActionCellTypesEnum.SET,
      });

      state.addElement(cellItem, parentID, addIndex);
      state.setOnFocusCellID(cellItem.id);
      uiState.setDrawerType(DrawerTypesEnum.ACTION_ATTRIBUTE);
    },
    [generateNewElement, state, uiState],
  );

  const addActionRemoveAttribute = useCallback(
    (parentID: string, addIndex?: number) => {
      const cellItem = generateNewElement(ElementTypesEnum.ACTION_ELEMENT, {
        nodeID: parentID,
        parentID,
        actionType: ActionCellTypesEnum.REMOVE,
      });

      state.addElement(cellItem, parentID, addIndex);
      state.setOnFocusCellID(cellItem.id);
      uiState.setDrawerType(DrawerTypesEnum.ACTION_ATTRIBUTE);
    },
    [generateNewElement, state, uiState],
  );

  const addActionSubscribeSequence = useCallback(
    (parentID: string, addIndex?: number) => {
      const cellItem = generateNewElement(ElementTypesEnum.ACTION_ELEMENT, {
        nodeID: parentID,
        parentID,
        actionType: ActionCellTypesEnum.SUBSCRIBE_SEQUENCE,
      });

      state.addElement(cellItem, parentID, addIndex);
      state.setOnFocusCellID(cellItem.id);
      uiState.setDrawerType(DrawerTypesEnum.ACTION_SEQUENCE);
    },
    [generateNewElement, state, uiState],
  );

  const addActionUnsubscribeSequence = useCallback(
    (parentID: string, addIndex?: number) => {
      const cellItem = generateNewElement(ElementTypesEnum.ACTION_ELEMENT, {
        nodeID: parentID,
        parentID,
        actionType: ActionCellTypesEnum.UNSUBSCRIBE_SEQUENCE,
      });

      state.addElement(cellItem, parentID, addIndex);
      state.setOnFocusCellID(cellItem.id);
      uiState.setDrawerType(DrawerTypesEnum.ACTION_SEQUENCE);
    },
    [generateNewElement, state, uiState],
  );

  const addActionExportGoogleSheet = useCallback(
    (parentID: string, addIndex?: number) => {
      const cellItem = generateNewElement(ElementTypesEnum.ACTION_ELEMENT, {
        nodeID: parentID,
        parentID,
        actionType: ActionCellTypesEnum.EXPORT_GOOGLE_SHEET,
        data: {
          properties: [],
        },
      });

      state.addElement(cellItem, parentID, addIndex);
      state.setOnFocusCellID(cellItem.id);
      uiState.setDrawerType(DrawerTypesEnum.ACTION_EXPORT_GOOGLE_SHEET);
    },
    [generateNewElement, state, uiState],
  );

  const addNote = useCallback(
    (position?: XYPosition, focus = true) => {
      // 新 block 的 x,y 座標
      const newPosition =
        position ||
        getUniquePosition(
          centerX,
          centerY,
          state.elements,
          NEW_ELEMENT_POSITION_DISTANCE,
        );

      const newElement = generateNewElement(ElementTypesEnum.NOTE, {
        position: {
          x: newPosition.x ?? 0,
          y: newPosition.y ?? 0,
        },
      });

      state.addElement(newElement);
      if (focus) {
        focusToNode(newPosition, newElement.id);
      }
      return newElement.id;
    },
    [
      NEW_ELEMENT_POSITION_DISTANCE,
      centerX,
      centerY,
      focusToNode,
      generateNewElement,
      state,
    ],
  );

  const addJumpToFlow = useCallback(
    (id?: string, position?: XYPosition, focus = true) => {
      const newPosition =
        position ||
        getUniquePosition(
          centerX,
          centerY,
          state.elements,
          NEW_ELEMENT_POSITION_DISTANCE,
        );

      const newElement = generateNewElement(ElementTypesEnum.JUMP_TO_FLOW, {
        position: {
          x: newPosition.x ?? 0,
          y: newPosition.y ?? 0,
        },
        data: {
          elementID: uuid(),
        },
      });

      newElement.label = t('canvas.conditionDefaultTitle', {
        count: state.blockSerialNumber + 1,
      });

      state.addElement(newElement);
      state.setBlockSerialNumber(state.blockSerialNumber + 1);

      if (id) {
        state.connectElement(id, newElement.id);
      }

      if (focus) {
        focusToNode(newPosition, newElement.id);
      }

      return newElement.id;
    },
    [
      NEW_ELEMENT_POSITION_DISTANCE,
      centerX,
      centerY,
      focusToNode,
      generateNewElement,
      state,
      t,
    ],
  );

  return {
    getInitialElements,
    generateNewElement,
    addCellButton,
    addRecurringNotification,
    addTextButton,
    addBlock,
    addNodeEditorMenu,
    addShareLink,
    addSendCoupon,
    addShowCoupon,
    addRedeemCoupon,
    addCondition,
    addConditionElse,
    addAction,
    addActionSetAttribute,
    addActionRemoveAttribute,
    addActionSubscribeSequence,
    addActionUnsubscribeSequence,
    addActionExportGoogleSheet,
    addImage,
    addGallery,
    addGalleryImage,
    addNote,
    addCollectUserAnswer,
    addOptionCellButton,
    addJumpToFlow,
  };
}

export default useCanvasAdd;

import { globalTheme } from '@frontend/components/external-providers';
import {
  ChipEditorChipType,
  ChipEditorSelectOptionType,
} from '@frontend/components/interface';
import { Button, ChipEditor, Typography } from '@frontend/components/ui';
import {
  useGetCategoryValue,
  useGetProjectID,
} from '@frontend/editor/data-access';
import { CategoryValueTypesEnum } from '@frontend/editor/interface';
import {
  useGetAttributeKey,
  useGetTagsByFrequency,
  usePatchContactAttribute,
  usePatchContactTag,
} from '@frontend/sorghum/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import {
  ConditionItemType,
  LoadingModalTypeEnum,
  PeopleTableFilterConditionPropsType,
} from '@frontend/sorghum/interface';
import { sendGAEvent } from '@frontend/sorghum/utils';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/system';
import { get } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface PeopleBulkEditModalProps {
  isOpenModal: boolean;
  setIsOpenModal: (isOpenModal: boolean) => void;
  bulkEditType: 1 | 2;
  peopleIDList: string[];
  filterConditions: PeopleTableFilterConditionPropsType[];
  filterCriteria: 1 | 2;
  filterCount: number;
}

const PeopleBulkEditModalContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  height: '400px',
  maxHeight: '400px',
  overflowY: 'scroll',
  overflowX: 'hidden',
}));

const DialogTitleContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  svg: {
    marginLeft: '8px',
  },
}));

const AttributeContent = ({
  tagList,
  setTagList,
}: {
  tagList: ChipEditorChipType[];
  setTagList: (tagList: ChipEditorChipType[]) => void;
}) => {
  const [t] = useTranslation();

  const [attributeID, setAttributeID] = useState('');
  // attribute name 對應的 value 選項
  const [valueList, setValueList] = useState<ChipEditorSelectOptionType[]>([]);

  const { data: projectID } = useGetProjectID();
  const { data: attributeData } = useGetAttributeKey(projectID as string);
  const { data: valueData } = useGetCategoryValue({
    projectID: projectID as string,
    type: CategoryValueTypesEnum.ATTRIBUTE,
    attributeID,
  });

  const onChangeAttributeName = useCallback(
    (chipID: string, option: ChipEditorSelectOptionType) => {
      setAttributeID(option.value);

      const newList = tagList.map((item) =>
        item.id === chipID
          ? {
              ...item,
              label: option.label, // attribute name
              value: option.value, // attribute id
            }
          : item,
      );

      setTagList(newList);
    },
    [setTagList, tagList],
  );

  const onUpdateAttributeValue = useCallback(
    (
      chipID: string,
      option: ChipEditorSelectOptionType,
      type?: 'add' | 'delete',
    ) => {
      if (type === 'add') {
        const newList = tagList.map((item) =>
          item.id === chipID
            ? {
                ...item,
                attrValue: option.label,
                ...(option?.newTag && { newTag: true }),
              }
            : item,
        );
        setTagList(newList);
      }
    },
    [setTagList, tagList],
  );

  const handleAddClick = useCallback(() => {
    // 追蹤點擊 add attribute 彈窗內的 + add attribute 按鈕的數量，點一下+1
    sendGAEvent(
      'Customers',
      '＋add attribute',
      'Customers - Add attribute - popup - ＋add attribute - click',
      '',
    );
  }, []);

  const formattedAttributeNameList = useMemo(() => {
    if (attributeData) {
      return attributeData.map((item) => ({
        label: item.key,
        value: item.id,
      }));
    } else {
      return [] as ChipEditorSelectOptionType[];
    }
  }, [attributeData]);

  useEffect(() => {
    if (valueData) {
      const formattedValueList: ChipEditorSelectOptionType[] = valueData.map(
        (item) => ({
          label: item.value,
          value: item.id,
        }),
      );

      setValueList(formattedValueList);
    }
  }, [valueData]);

  return (
    <ChipEditor
      tagList={tagList}
      handleAddClick={handleAddClick}
      setTagList={setTagList}
      addButtonText={t('people.bulkEditModal.addAttributeButton')}
      firstOptionList={formattedAttributeNameList}
      secondOptionList={valueList}
      hasSecondValue
      isAllowSecondSelectAddOption
      onUpdateFirstChipValue={onChangeAttributeName}
      onUpdateSecondChipValue={onUpdateAttributeValue}
      chipBackgroundColor={`${get(
        globalTheme,
        'palette.secondary.main',
        '',
      )}1A`}
      chipFirstTextColor={get(globalTheme, 'palette.secondary.dark', '')}
      chipSecondTextColor={get(globalTheme, 'palette.grey.800', '')}
      cancelButtonColor="secondary.main"
      chipTooltipLabelName={t('people.bulkEditModal.tooltipName')}
      chipTooltipValueName={t('people.bulkEditModal.tooltipValue')}
    />
  );
};

const TagContent = ({
  tagList,
  setTagList,
}: {
  tagList: ChipEditorChipType[];
  setTagList: (tagList: ChipEditorChipType[]) => void;
}) => {
  const [t] = useTranslation();

  const { data: projectID } = useGetProjectID();
  const { data: tagOptionList } = useGetTagsByFrequency({
    projectID: projectID as string,
  });

  const formattedTagList = tagOptionList
    ? tagOptionList.map((item) => ({
        label: item.name,
        value: item.id,
      }))
    : [];

  const onUpdateTag = (
    chipID: string,
    props: ChipEditorSelectOptionType,
    type?: 'add' | 'delete',
  ) => {
    if (type === 'add') {
      const newList = tagList.map((item) =>
        item.id === chipID
          ? {
              ...item,
              label: props.label,
              value: props.value,
              ...(props?.newTag && { newTag: true }),
            }
          : item,
      );
      setTagList(newList);
    }
  };

  const handleAddClick = useCallback(() => {
    // 追蹤點擊 add tag 彈窗內的 + add attribute 按鈕的數量，點一下+1
    sendGAEvent(
      'Customers',
      '＋add tag',
      'Customers - Add tag - popup - ＋add tag- click',
      '',
    );
  }, []);

  return (
    <ChipEditor
      tagList={tagList}
      setTagList={setTagList}
      addButtonText="+ Add tag"
      isAllowFirstSelectAddOption
      handleAddClick={handleAddClick}
      firstOptionList={formattedTagList}
      onUpdateFirstChipValue={onUpdateTag}
      chipBackgroundColor={`${get(globalTheme, 'palette.primary.main', '')}0D`}
      chipFirstTextColor={get(globalTheme, 'palette.primary.main', '')}
      cancelButtonColor="primary.main"
      chipTooltipLabelName={t('people.bulkEditModal.tooltipTag')}
    />
  );
};

export function PeopleBulkEditModal({
  isOpenModal,
  setIsOpenModal,
  bulkEditType, // 1: attribute, 2: tag
  peopleIDList,
  filterConditions,
  filterCriteria,
  filterCount,
}: PeopleBulkEditModalProps) {
  const [t] = useTranslation();
  const UIState = useContext(UICtx);
  const { mutate: updateTag } = usePatchContactTag();
  const { mutate: updateAttribute } = usePatchContactAttribute();

  const [tagList, setTagList] = useState<
    {
      id: string;
      label: string;
      value: string;
      attrValue?: string;
      newTag?: boolean;
    }[]
  >([]);

  const closeModal = useCallback(() => {
    setIsOpenModal(false);
    setTagList([]);
  }, [setIsOpenModal]);

  const handleSaveBulkEditTag = useCallback(() => {
    const updateTagProps = {
      ...(peopleIDList.length > 0 && { contactsIds: peopleIDList }),
      create: tagList.map((item) => ({
        id: item.value,
        name: item.label,
        ...(item.newTag && { newTag: true }),
      })),
      delete: [],
      ...(filterConditions.length > 0 && {
        filterCondition: {
          filterCriteria,
          condition: filterConditions.map((item) => item as ConditionItemType),
        },
      }),
    };
    updateTag(updateTagProps, {
      onSuccess: () => {
        UIState.setIsLoading('bulk-edit', LoadingModalTypeEnum.MASK);
      },
    });

    closeModal();
  }, [
    UIState,
    closeModal,
    filterConditions,
    filterCriteria,
    peopleIDList,
    tagList,
    updateTag,
  ]);

  const handleSaveBulkEditAttribute = useCallback(() => {
    const updateAttributeProps = {
      ...(peopleIDList.length > 0 && { contactsIds: peopleIDList }),
      create: tagList.map((item) => ({
        id: item.value,
        name: item.label,
        value: item.attrValue ?? '',
        ...(item.newTag && { newAttribute: true }),
      })),
      delete: [],
      ...(filterConditions.length > 0 && {
        filterCondition: {
          filterCriteria,
          condition: filterConditions.map((item) => item as ConditionItemType),
        },
      }),
    };

    updateAttribute(updateAttributeProps, {
      onSuccess: () => {
        UIState.setIsLoading('bulk-edit', LoadingModalTypeEnum.MASK);
      },
    });

    closeModal();
  }, [
    UIState,
    closeModal,
    filterConditions,
    filterCriteria,
    peopleIDList,
    tagList,
    updateAttribute,
  ]);

  const onSaveBulkEdit = useCallback(() => {
    if (bulkEditType === 1) {
      handleSaveBulkEditAttribute();
      // 追蹤點擊 add attribute 彈窗內的 save 的數量，點一下+1
      sendGAEvent(
        'Customers',
        'save',
        'Customers - Add attribute - popup - save - click',
        '',
      );
    } else {
      handleSaveBulkEditTag();
      // 追蹤點擊 add tag 彈窗內的 save 的數量，點一下+1
      sendGAEvent(
        'Customers',
        'save',
        'Customers - Add tag - popup - save - click',
        '',
      );
    }
  }, [bulkEditType, handleSaveBulkEditAttribute, handleSaveBulkEditTag]);

  const handleClose = useCallback(() => {
    // 追蹤點擊 add attribute 彈窗內的 cancel 的數量，點一下+1
    if (bulkEditType === 1) {
      sendGAEvent(
        'Customers',
        'cancel',
        'Customers - Add attribute - popup - cancel - click',
        '',
      );
      // 追蹤點擊 add tag彈窗內的 cancel 的數量，點一下+1
    } else {
      sendGAEvent(
        'Customers',
        'cancel',
        'Customers - Add tag - popup - cancel - click',
        '',
      );
    }

    setIsOpenModal(false);
  }, [bulkEditType, setIsOpenModal]);

  return (
    <Dialog
      open={isOpenModal}
      fullWidth
      maxWidth="s"
      title={t('people.bulkEditModal.title', {
        count: peopleIDList.length === 0 ? filterCount : peopleIDList.length,
        bulkType:
          bulkEditType === 1
            ? t('people.bulkEditModal.attribute')
            : t('people.bulkEditModal.tag'),
      })}
      onClose={closeModal}
    >
      <DialogTitle>
        <DialogTitleContainerStyled>
          <Typography variant="h6">
            {t('people.bulkEditModal.title', {
              count:
                peopleIDList.length === 0 ? filterCount : peopleIDList.length,
              bulkType:
                bulkEditType === 1
                  ? t('people.bulkEditModal.attribute')
                  : t('people.bulkEditModal.tag'),
            })}
          </Typography>
          <Tooltip
            title={t('people.bulkEditModal.infoTooltip')}
            placement="top"
          >
            <InfoIcon
              sx={{ width: '20px', height: '20px', color: 'bluegrey.300' }}
            />
          </Tooltip>
        </DialogTitleContainerStyled>
      </DialogTitle>
      <DialogContent sx={{ padding: '8px 24px' }}>
        <PeopleBulkEditModalContainerStyled>
          {bulkEditType === 1 ? (
            <AttributeContent tagList={tagList} setTagList={setTagList} />
          ) : (
            <TagContent tagList={tagList} setTagList={setTagList} />
          )}
        </PeopleBulkEditModalContainerStyled>
      </DialogContent>
      <DialogActions sx={{ padding: '20px 24px' }}>
        <Button onClick={handleClose}>{t('common.cancel')}</Button>
        <Button
          variant="contained"
          onClick={onSaveBulkEdit}
          disabled={tagList.length === 0}
        >
          {t('common.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PeopleBulkEditModal;

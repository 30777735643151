import {
  Box,
  Tab as MuiTab,
  TabProps as MuiTabProps,
  Tabs as MuiTabs,
  TabsProps,
} from '@mui/material';
import { styled } from '@mui/system';
import { FC, PropsWithChildren } from 'react';
import Typography from '../typography/typography';

const TabStyled = styled(MuiTab)(({ theme }) => ({
  padding: '10px 16px',
  minHeight: '8px',
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const TabPanel: FC<PropsWithChildren<TabPanelProps>> = ({
  children,
  value,
  index,
  ...other
}: TabPanelProps) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

/* eslint-disable-next-line */
export interface TabProps extends MuiTabProps {
  id?: string;
}

export const Tab: FC<TabProps> = ({ label, id, ...props }) => {
  return (
    <TabStyled
      id={id}
      {...props}
      label={
        <Typography variant="buttonLarge" sx={{ textTransform: 'none' }}>
          {label}
        </Typography>
      }
    />
  );
};

export const Tabs: FC<TabsProps> = ({ children, ...props }) => {
  return <MuiTabs {...props}>{children}</MuiTabs>;
};

export default Tab;

import { globalTheme } from '@frontend/components/external-providers';
import { Button } from '@frontend/components/ui';
import {
  useCanvasAdd,
  useCanvasCollect,
  useCanvasGet,
  useCanvasRemove,
  useCanvasUpdate,
  useCanvasView,
} from '@frontend/editor/data-access';
import { EditorCtx } from '@frontend/editor/external-providers';
import {
  BaseElement,
  ElementTypesEnum,
  PublishErrorsEnum,
} from '@frontend/editor/interface';
import AddIcon from '@mui/icons-material/Add';
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined';
import CommentBankOutlinedIcon from '@mui/icons-material/CommentBankOutlined';
import TextIcon from '@mui/icons-material/FormatColorText';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import MessageIcon from '@mui/icons-material/Message';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { Box, Popover } from '@mui/material';
import { styled } from '@mui/system';
import { get } from 'lodash';
import {
  Dispatch,
  RefObject,
  SetStateAction,
  memo,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableElementProps,
} from 'react-sortable-hoc';
import { NodeProps, Position, useViewport } from 'reactflow';
import EditorBlockBorder from '../editor-block-border/editor-block-border';
import EditorBlockContainer from '../editor-block-container/editor-block-container';
import { EditorCollectUserAnswer } from '../editor-collect-user-answer/editor-collect-user-answer';
import EditorFloatPanel from '../editor-float-panel/editor-float-panel';
import EditorGallery from '../editor-gallery/editor-gallery';
import EditorImage from '../editor-image/editor-image';
import EditorMenu from '../editor-menu/editor-menu';
import RecurringNotification from '../editor-recurring-notification/editor-recurring-notification';
import TextButton from '../editor-text-button/editor-text-button';
import { HandlePoint } from '../handle-point/handle-point';
import { NodeHeader } from '../node-header/node-header';

interface SortableListType {
  items: string[];
  draggable: boolean;
}

interface SortableItemType {
  value: BaseElement;
  draggable: boolean;
  setIsHoverMenu: Dispatch<SetStateAction<boolean>>;
  itemIndex: number;
}

const BodyStyled = styled(Box)(({ theme }) => ({
  width: '316px',
  borderRadius: '0 0 12px 12px',
  display: 'flex',
  justifyContent: 'end',
  flexDirection: 'column',
  padding: '12px',
}));

const SortableItemStyled = styled(Box)(({ theme }) => ({
  zIndex: 20,
}));

const SortableItem: React.ComponentClass<
  SortableElementProps & SortableItemType
> = SortableElement(
  ({
    value,
    draggable,
    itemIndex,
    setIsHoverMenu,
  }: {
    value: BaseElement;
    draggable: boolean;
    setIsHoverMenu: Dispatch<SetStateAction<boolean>>;
    itemIndex: number;
  }) => {
    const { onHoverElement } = useCanvasView();
    let element = <div></div>;

    switch (value.elementType) {
      case ElementTypesEnum.TEXT_BUTTON: {
        element = (
          <TextButton
            key={value.id}
            id={value.id}
            index={itemIndex}
            draggable={draggable}
            label={value.label}
            setIsHoverMenu={setIsHoverMenu}
            parentID={value.parentID as string}
          />
        );
        break;
      }
      case ElementTypesEnum.RECURRING_NOTIFICATION: {
        element = (
          <RecurringNotification
            key={value.id}
            id={value.id}
            parentID={value.parentID as string}
            index={itemIndex}
            draggable={draggable}
            setIsHoverMenu={setIsHoverMenu}
          />
        );
        break;
      }
      case ElementTypesEnum.IMAGE: {
        element = (
          <EditorImage
            key={value.id}
            id={value.id}
            parentID={value.parentID as string}
            index={itemIndex}
            draggable={draggable}
            setIsHoverMenu={setIsHoverMenu}
          />
        );
        break;
      }
      case ElementTypesEnum.GALLERY: {
        element = (
          <EditorGallery
            key={value.id}
            id={value.id}
            parentID={value.parentID as string}
            index={itemIndex}
            draggable={draggable}
            setIsHoverMenu={setIsHoverMenu}
          />
        );
        break;
      }
      case ElementTypesEnum.COLLECT_USER_ANSWER: {
        element = (
          <EditorCollectUserAnswer
            key={value.id}
            id={value.id}
            parentID={value.parentID as string}
            index={itemIndex}
            draggable={draggable}
            setIsHoverMenu={setIsHoverMenu}
          />
        );
        break;
      }
    }

    return (
      <SortableItemStyled
        mb={2}
        key={value.id}
        onMouseEnter={() => onHoverElement(value.id, 2)}
        onMouseLeave={() => onHoverElement(value.id, 1)}
        style={{
          cursor: 'pointer',
        }}
      >
        {element}
      </SortableItemStyled>
    );
  },
);

const SortableList: React.ComponentClass<
  SortableContainerProps & SortableListType
> = SortableContainer(
  ({ items, draggable }: { items: string[]; draggable: boolean }) => {
    const { getTargetElement } = useCanvasGet();
    // 檢查是否 hover 在側邊的選單來決定是否開放 sort event
    const [isHoverMenu, setIsHoverMenu] = useState(false);

    return (
      <Box>
        {items.map((item, index) => {
          const childItem = getTargetElement(item);

          return (
            <SortableItem
              key={childItem.id}
              value={childItem}
              index={index}
              itemIndex={index}
              draggable={draggable}
              disabled={!isHoverMenu}
              setIsHoverMenu={setIsHoverMenu}
            />
          );
        })}
      </Box>
    );
  },
);

export const NodeBlock = ({ id, selected }: NodeProps) => {
  const [t] = useTranslation();
  const { zoom } = useViewport();
  const { tourMode } = useContext(EditorCtx);
  const { getTargetElement } = useCanvasGet();
  const {
    addTextButton,
    addRecurringNotification,
    addImage,
    addGallery,
    addCollectUserAnswer,
  } = useCanvasAdd();
  const { canvasUpdateLabel, canvasSortElement } = useCanvasUpdate();
  const { onFocusID } = useCanvasView();
  const { getErrorStatusAfterPublished } = useCanvasCollect();
  const { canvasRemoveBlockWithValidation } = useCanvasRemove();

  const [isHoverBlock, setIsHoverBlock] = useState<boolean>(false);
  const [isHoverElement, setIsHoverElement] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const open = Boolean(anchorEl);

  const { header, inputID, outputID, targetID, children } = useMemo(() => {
    const elementData = getTargetElement(id);
    const header = get(elementData, 'label', '');
    const inputID = get(elementData, 'inputID', '');
    const outputID = get(elementData, 'outputID', '');
    const targetID = get(elementData, 'targetID', '');
    const children = get(elementData, 'children', []) as string[];
    return {
      header,
      inputID,
      outputID,
      targetID,
      children,
    };
  }, [getTargetElement, id]);

  const handleAddClick = (ref: RefObject<HTMLDivElement>) => {
    setAnchorEl(ref.current as SetStateAction<HTMLButtonElement | null>);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onAddButtonClick = useCallback(
    (type: ElementTypesEnum) => {
      switch (type) {
        case ElementTypesEnum.TEXT_BUTTON: {
          addTextButton(id);
          break;
        }
        case ElementTypesEnum.RECURRING_NOTIFICATION: {
          addRecurringNotification(id);
          break;
        }
        case ElementTypesEnum.IMAGE: {
          addImage(id);
          break;
        }
        case ElementTypesEnum.GALLERY: {
          addGallery(id);
          break;
        }
        case ElementTypesEnum.COLLECT_USER_ANSWER: {
          addCollectUserAnswer(id);
          break;
        }
      }
    },
    [
      addGallery,
      addImage,
      addRecurringNotification,
      addTextButton,
      addCollectUserAnswer,
      id,
    ],
  );

  const handleMouseEnter = useCallback(() => {
    setIsHoverBlock(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHoverBlock(false);
  }, []);

  const handleElementMouseEnter = useCallback(() => {
    setIsHoverElement(true);
  }, []);

  const handleElementMouseLeave = useCallback(() => {
    setIsHoverElement(false);
  }, []);

  const onTextButtonSortEnd = useCallback(
    ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
      canvasSortElement(id, oldIndex, newIndex);
    },
    [canvasSortElement, id],
  );

  const onRemoveButtonClick = useCallback(() => {
    canvasRemoveBlockWithValidation(id);
  }, [id, canvasRemoveBlockWithValidation]);

  const menuList = [
    {
      dropdownItems: [
        {
          id: 'option_text_button',
          icon: <TextIcon color="primary" />,
          title: t('canvas.blockMenu.contentButton'),
          onClick: () => onAddButtonClick(ElementTypesEnum.TEXT_BUTTON),
        },
        {
          id: 'option_image',
          icon: <ImageOutlinedIcon color="primary" />,
          title: t('canvas.blockMenu.image'),
          onClick: () => onAddButtonClick(ElementTypesEnum.IMAGE),
        },
        {
          id: 'option_gallery',
          icon: <CollectionsOutlinedIcon color="primary" />,
          title: t('canvas.blockMenu.gallery'),
          onClick: () => onAddButtonClick(ElementTypesEnum.GALLERY),
          tooltip: <Box>{t('canvas.blockMenu.showGalleryTooltip')}</Box>,
        },
        {
          id: 'option_theme_subscription',
          icon: <NotificationsNoneIcon color="primary" />,
          title: t('canvas.blockMenu.recurringNotificationsButton'),
          onClick: () =>
            onAddButtonClick(ElementTypesEnum.RECURRING_NOTIFICATION),
          tooltip: <Box>{t('canvas.blockMenu.rnTooltip')}</Box>,
        },
        {
          id: 'option_collect_user_answer',
          icon: <CommentBankOutlinedIcon color="primary" />,
          title: t('canvas.blockMenu.collectUserAnswerButton'),
          onClick: () => onAddButtonClick(ElementTypesEnum.COLLECT_USER_ANSWER),
          tooltip: <Box>{t('canvas.blockMenu.collectUserAnswerTooltip')}</Box>,
        },
      ],
    },
  ];

  return (
    <EditorBlockContainer nodeID={id}>
      {inputID && (
        <HandlePoint
          id={inputID}
          type="target"
          position={Position.Left}
          styles={{
            top: '5%',
          }}
          isConnected={false}
          setIsHoverBlock={setIsHoverBlock}
        />
      )}
      {outputID && (
        <HandlePoint
          id={outputID}
          type="source"
          position={Position.Right}
          styles={{
            top: 'calc(100% - 30px)',
          }}
          isConnected={!!targetID}
          isConnectable={!tourMode}
          setIsHoverBlock={setIsHoverBlock}
          isFocus={onFocusID === id}
        />
      )}
      <EditorBlockBorder
        zoom={zoom}
        nodeID={id}
        selected={selected}
        color={get(globalTheme, 'palette.info.main', '')}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {/* Block 上方的刪除和 ... 按鈕 */}
        {isHoverBlock && !isHoverElement && !tourMode && (
          <EditorFloatPanel
            direction="row"
            type="block"
            backgroundColor="rgba(96, 125, 139, 0.1)"
            handleDelete={onRemoveButtonClick}
          />
        )}

        <NodeHeader
          color={globalTheme?.palette?.['primary']?.['main'] as string}
          background={globalTheme.palette?.['blue'][50]}
          icon={<MessageIcon fontSize="small" />}
          title={header}
          onBlur={(val: string) => canvasUpdateLabel(id, val)}
        />

        <BodyStyled
          onMouseEnter={handleElementMouseEnter}
          onMouseLeave={handleElementMouseLeave}
        >
          {/* 包含所有 Elements 的 container */}
          <Box>
            {children && (
              <SortableList
                items={children}
                lockAxis="y"
                useDragHandle
                draggable={children.length > 1}
                onSortEnd={onTextButtonSortEnd}
                // 避免縮放時拖曳會改變大小
                helperClass="node_item_dragged_style"
              />
            )}
          </Box>
          {/* Add Element Button */}
          <Box ref={buttonRef}>
            <Button
              id="add_element"
              onClick={() => handleAddClick(buttonRef)}
              startIcon={<AddIcon fontSize="small" />}
              variant="outlined"
              color={
                getErrorStatusAfterPublished(id, PublishErrorsEnum.BLOCK_EMPTY)
                  ? 'error'
                  : 'bluegrey300'
              }
              dash
              fullWidth
            >
              {t('canvas.addElement')}
            </Button>
          </Box>
        </BodyStyled>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          onClick={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <EditorMenu itemList={menuList} />
        </Popover>
      </EditorBlockBorder>
    </EditorBlockContainer>
  );
};

export default memo(NodeBlock);

import { Button, Typography } from '@frontend/components/ui';
import { BREAK_POINT, useRwd } from '@frontend/sorghum/utils';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FC } from 'react';
import styled from 'styled-components';
import { Box } from '../box/box';

export interface BackToProps {
  arrowText: string;
  handleClick: () => void;
}

const ContainerStyled = styled(Box)(({ theme }) => ({
  position: 'fixed',
  left: 'calc(35% + 24px)',
  top: '24px',

  [theme.breakpoints.down('md')]: {
    left: '20px',
  },
}));

export const BackTo: FC<BackToProps> = ({
  handleClick,
  arrowText,
}: BackToProps) => {
  const { breakPoint } = useRwd();
  return (
    <ContainerStyled>
      <Button
        startIcon={<ArrowBackIcon />}
        sx={{
          color: 'text.secondary',
        }}
        onClick={handleClick}
      >
        {breakPoint >= BREAK_POINT.MD && (
          <Typography variant="body2">{arrowText}</Typography>
        )}
      </Button>
    </ContainerStyled>
  );
};

export default BackTo;

import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { StandardTextFieldProps, TextField } from '@mui/material';
import { styled } from '@mui/system';
import { FC } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import Typography from '../typography/typography';

const TextFieldStyled = styled(TextField)(({ theme }) => ({}));

export interface SelectProps extends StandardTextFieldProps {
  register?: UseFormRegisterReturn;
  formName?: string;
  error?: boolean;
  errorMessage?: string;
}

export const Select: FC<SelectProps> = ({
  value,
  label,
  children,
  register,
  formName,
  error = false,
  errorMessage,
  ...props
}: SelectProps) => {
  return (
    <>
      <TextFieldStyled
        {...(register && register)}
        {...props}
        select
        label={label}
        variant="outlined"
        value={value}
        SelectProps={{
          IconComponent: KeyboardArrowDownOutlinedIcon,
        }}
        error={error}
      >
        {children}
      </TextFieldStyled>
      {error && (
        <Typography variant="body2" color="error.main">
          {errorMessage}
        </Typography>
      )}
    </>
  );
};

export default Select;

import { Checkbox, Dialog, Typography } from '@frontend/components/ui';
import { useGetProjectID } from '@frontend/editor/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import { ModalTypesEnum } from '@frontend/sorghum/interface';
import {
  VIEWER_PERMISSION_EN,
  VIEWER_PERMISSION_ZH,
  getProjectStatus,
  setProjectStatus,
} from '@frontend/sorghum/utils';
import { Box, Link } from '@mui/material';
import { styled } from '@mui/system';
import { useContext, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

/* eslint-disable-next-line */
export interface ViewerModeModalProps {}

const ContentContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '34px',
}));

export function ViewerModeModal(props: ViewerModeModalProps) {
  const [t, i18next] = useTranslation();
  const uiState = useContext(UICtx);
  const [isCheck, setIsCheck] = useState(false);
  const { data: projectID } = useGetProjectID();

  const localStorageIsChecked = useMemo(() => {
    if (projectID) {
      return getProjectStatus({ projectID });
    }
    return false;
  }, [projectID]);

  const viewerLearnMoreUrl =
    i18next.language === 'en_us' ? VIEWER_PERMISSION_EN : VIEWER_PERMISSION_ZH;

  return (
    <Dialog
      // 打開的依據：是否是 viewer 而且沒有勾選 checkbox
      open={
        uiState.modalType === ModalTypesEnum.VIEWER_MODE &&
        !localStorageIsChecked
      }
      size="xs"
      title={t('dashboard.viewerModal.title')}
      handleClose={() => null}
      handleConfirm={() => {
        uiState.closeModal();
        if (projectID) {
          setProjectStatus({ projectID, checked: isCheck });
        }
      }}
      confirmBtnText={t('dashboard.viewerModal.button')}
      uppercase={false}
      closeBtn={false}
    >
      <ContentContainerStyled>
        <Typography variant="body1" color="grey.800">
          <Trans i18nKey="dashboard.viewerModal.content">
            You are in the Viewer Mode, any edit will not be saved in the
            system. Check to learn more.
            <Link
              sx={{ color: 'info.main' }}
              onClick={() => window.open(viewerLearnMoreUrl, '_blank')}
            >
              {t('dashboard.viewerModal.link')}
            </Link>
          </Trans>
        </Typography>

        <Checkbox
          variant="body2"
          color="primary"
          fontWeight={400}
          labelColor="grey.700"
          label={t('dashboard.viewerModal.checkbox')}
          checked={isCheck}
          onChange={(e) => setIsCheck(e.target.checked)}
        />
      </ContentContainerStyled>
    </Dialog>
  );
}

export default ViewerModeModal;

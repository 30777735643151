import {
  useGetProjectID,
  useProjectPermission,
} from '@frontend/editor/data-access';
import { EditorCtx } from '@frontend/editor/external-providers';
import { usePatchEditorFlow } from '@frontend/sorghum/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import { ModalTypesEnum, Response } from '@frontend/sorghum/interface';
import { PAGE_FLOW, PAGE_FLOW_VIEW } from '@frontend/sorghum/utils';
import { useCallback, useContext } from 'react';
import { useLocation } from 'react-router-dom';

export const useModal = () => {
  const uiState = useContext(UICtx);
  const editorState = useContext(EditorCtx);
  const { pathname } = useLocation();
  const { isViewer } = useProjectPermission();

  const { data: projectID } = useGetProjectID();

  const { mutate: renameFlow } = usePatchEditorFlow({
    onSuccess: (data: Response) => {
      uiState.closeModal();
    },
  });

  const onFlowRename = useCallback(
    (name: string) => {
      if (uiState.flowID) {
        renameFlow({
          projectId: projectID as string,
          flowID: uiState.flowID,
          name,
        });
      }
    },
    [uiState.flowID, renameFlow, projectID],
  );

  const handleModalConfirm = useCallback(() => {
    if (uiState.modalData.onConfirm) {
      uiState.modalData.onConfirm();
    }
    uiState.closeModal();
  }, [uiState]);

  const beforeUnload = useCallback(
    (handleConfirm: () => void) => {
      if (isViewer) {
        handleConfirm();
        return;
      }
      if (pathname.includes(PAGE_FLOW) && !pathname.includes(PAGE_FLOW_VIEW)) {
        const isUpdated = editorState.checkDataUpdated();
        if (isUpdated) {
          uiState.openNavigateModal(handleConfirm);
        } else {
          handleConfirm();
        }
      } else if (uiState.isUpdated) {
        uiState.openNavigateModal(handleConfirm);
        uiState.setIsUpdated(false);
      } else {
        handleConfirm();
      }
    },
    [editorState, isViewer, pathname, uiState],
  );

  return {
    renameFlowModalOpen: uiState.modalType === ModalTypesEnum.RENAME_FLOW,
    modalOpen: uiState.modalType === ModalTypesEnum.COMMON,
    modalTitle: uiState.title,
    modalColor: uiState.modalData.color,
    modalContent: uiState.content,
    modalConfirm: uiState.confirm,
    modalCancel: uiState.cancel,
    beforeUnload,
    handleModalConfirm,
    onFlowRename,
    openCreateProjectModal: uiState.openCreateProjectModal,
    openRenameProjectModal: uiState.openRenameProjectModal,
    closeModal: uiState.closeModal,
  };
};

import {
  Box,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  Typography,
  TypographyVariant,
} from '@mui/material';
import { styled } from '@mui/system';
import { FC } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

/* eslint-disable-next-line */
export interface CheckboxProps extends MuiCheckboxProps {
  label?: string;
  variant?: TypographyVariant;
  fontWeight?: number;
  register?: UseFormRegisterReturn;
  labelColor?: string;
  checkedColor?: string;
}

const CheckboxStyled = styled(MuiCheckbox)(({ theme }) => ({
  width: '20px',
  height: '20px',
  marginRight: '8px',

  '.MuiTouchRipple-root': {
    color: theme.palette['grey']['400'],
  },
}));

export const Checkbox: FC<CheckboxProps> = ({
  register,
  label,
  variant = 'h6',
  fontWeight = 400,
  color = 'default',
  size = 'small',
  labelColor = 'grey.700',
  ...props
}) => {
  return (
    <Box display="inline-flex" alignItems="center" justifyContent="left">
      <CheckboxStyled {...props} {...register} size={size} color={color} />
      <Typography variant={variant} fontWeight={fontWeight} color={labelColor}>
        {label}
      </Typography>
    </Box>
  );
};

export default Checkbox;

import { useCanvasUpdate } from '@frontend/editor/data-access';
import { TextButtonCellType } from '@frontend/editor/interface';
import { REGEX_CHECK_ENGLISH, REGEX_CHECK_URL } from '@frontend/editor/utils';
import {
  HOW_TO_TRACK_WITH_UTM,
  HOW_TO_TRACK_WITH_UTM_EN,
} from '@frontend/sorghum/utils';
import { Box, Link, TextField, styled } from '@mui/material';
import { isEmpty } from 'lodash';
import { ChangeEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToggleIconType } from './text-button-cell-modal';

const ContentWrapper = styled(Box)(({ theme }) => ({
  '& > div': {
    marginBottom: theme.spacing(2),
  },

  '.MuiFormHelperText-root': {
    color: theme.palette['grey'][500],
  },
}));

export const UrlSetting = ({ elementID, getTargetElement }: ToggleIconType) => {
  const [t, i18next] = useTranslation();
  // 只能輸入英文、數字、符號
  const urlRegex = new RegExp(REGEX_CHECK_ENGLISH);

  const { canvasUpdateData } = useCanvasUpdate();

  const elementData = useMemo(
    () => getTargetElement(elementID) as TextButtonCellType,
    [elementID, getTargetElement],
  );

  const [error, setError] = useState<boolean>(
    elementData.data.url ? !elementData.data.url.match(REGEX_CHECK_URL) : false,
  );
  const [urlData, setUrlData] = useState(elementData.data.url);

  // const urlSizeList = [
  //   { value: 'Native', label: 'Native' },
  //   { value: '100%', label: '100%' },
  //   { value: '75%', label: '75%' },
  //   { value: '50%', label: '50%' },
  // ];

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.match(urlRegex)) {
      setUrlData(e.target.value);
      canvasUpdateData(elementID, 'data.url', e.target.value);
    }
  };

  return (
    <ContentWrapper>
      <Box>
        <TextField
          error={error || isEmpty(urlData)}
          fullWidth
          size="small"
          placeholder={t('canvas.modal.editTextButton.urlPlaceholder')}
          onChange={handleChange}
          value={urlData || ''}
          onBlur={() => {
            if (!isEmpty(elementData.data.url)) {
              setError(
                !(elementData?.data?.url as string).match(REGEX_CHECK_URL),
              );
            }
          }}
          helperText={
            <span>
              {t('canvas.modal.editTextButton.urlHelperText')}
              <Link
                color="info.main"
                href={
                  i18next.language.includes('en')
                    ? HOW_TO_TRACK_WITH_UTM_EN
                    : HOW_TO_TRACK_WITH_UTM
                }
                target="_blank"
              >
                {t('canvas.modal.editTextButton.addingUTM')}
              </Link>
            </span>
          }
        />
      </Box>

      {/* <Box sx={{ position: 'relative' }}>
        <EditorSelect
          placeholder="Native"
          options={urlSizeList}
          value={elementData.data.webview || ''}
          onChange={(selectedItem) => {
            if (selectedItem) {
              canvasUpdateData(elementID, 'data.webview', selectedItem.value);
            }
          }}
        />
      </Box> */}
    </ContentWrapper>
  );
};

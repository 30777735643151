import { Loading, Typography } from '@frontend/components/ui';
import { UICtx } from '@frontend/sorghum/external-providers';
import { ModalTypesEnum } from '@frontend/sorghum/interface';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

const ContainerStyled = styled(Box)(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 9999,
  position: 'fixed',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  width: '100vw',
  background: theme.palette['grey']['white'],
  gap: '16px',
}));

export const TapPayLoadingModal: FC = () => {
  const [t] = useTranslation();
  const uiState = useContext(UICtx);

  if (uiState.modalType !== ModalTypesEnum.TAP_PAY_LOADING) return null;

  return (
    <ContainerStyled>
      <Loading
        sx={{
          mb: '16px',
        }}
      />
      <Typography variant="h6" color="grey.900">
        {t('tapPayCallback.loading.title')}
      </Typography>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="body2" color="grey.900">
          <Trans
            i18nKey="tapPayCallback.loading.description"
            components={{ br: <br /> }}
          />
        </Typography>
      </Box>
    </ContainerStyled>
  );
};

export default TapPayLoadingModal;

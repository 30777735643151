import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import Loading from '../loading/loading';

export interface ButtonProps extends MuiButtonProps {
  component?: string;
  dash?: boolean;
  loading?: boolean;
  loadingColor?: string;
}

export const Button: FC<ButtonProps> = ({
  children,
  dash = false,
  size = 'medium',
  loading = false,
  loadingColor = '',
  ...props
}: ButtonProps) => {
  let variant: 'buttonLarge' | 'button' | 'buttonSmall' = 'button';

  // 不同的 button size 會使用不同的 text variant
  switch (size) {
    case 'large': {
      variant = 'buttonLarge';
      break;
    }
    case 'medium': {
      variant = 'button';
      break;
    }
    case 'small': {
      variant = 'buttonSmall';
      break;
    }
  }

  return (
    <MuiButton
      {...props}
      size={size}
      sx={{
        ...(dash && {
          borderStyle: 'dashed',
          '&:hover': {
            borderStyle: 'dashed',
          },
          '&:focus': {
            borderStyle: 'dashed',
          },
        }),
        ...props.sx,
      }}
      endIcon={loading && <Loading color={loadingColor} />}
    >
      <Typography
        variant={variant}
        sx={{ textTransform: 'none', textWrap: 'nowrap' }}
      >
        {children}
      </Typography>
    </MuiButton>
  );
};

export default Button;

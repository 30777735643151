import {
  useAccountQuestion,
  useGetAccountInfo,
  usePostAccountQuestion,
} from '@frontend/sorghum/data-access';
import { queryClient } from '@frontend/sorghum/external-providers';
import {
  PostAccountQuestionProps,
  SorghumFeatureMicroFormValue,
} from '@frontend/sorghum/interface';
import {
  PAGE_LOGIN,
  PAGE_MAIN,
  PAGE_PAGES,
  sendGAEvent,
  useDocumentTitle,
  usePath,
} from '@frontend/sorghum/utils';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

const industries = [
  'Tech',
  'Retail',
  'Luxury',
  'FMCG',
  'GOV/NGO',
  'EC',
  'Media & Entertainment',
  'Agency',
  'F&B',
  'Others',
];

export const useSignupMicro = () => {
  const [t] = useTranslation();
  const { navigate, navigateToOtherProject, navigateToProjectPage } = usePath();
  const token = localStorage.getItem('token');
  const [searchParams] = useSearchParams();
  const projectID = searchParams.get('p');
  const urlP = projectID ? `/${projectID}` : '';
  const queryP = projectID ? `?p=${projectID}` : '';
  const { data: account, isFetched } = useGetAccountInfo();
  const { data: question } = useAccountQuestion();

  const { mutate: sendMicro, isLoading: isSendingMicro } =
    usePostAccountQuestion(question?.userName || '', queryClient, {
      onSuccess: () => {
        sendGAEvent(
          'Survey',
          'Survey submit success',
          'Survey - Survey submit - success',
          '',
        );
        if (projectID) {
          navigateToOtherProject(projectID);
        } else {
          navigateToProjectPage();
        }
      },
    });

  const industryItems = useMemo(
    () =>
      industries.map((item, index) => {
        return {
          label: t(`micro.industry.${item}`),
          value: index.toString(),
        };
      }),
    [t],
  );
  const submit = (data: SorghumFeatureMicroFormValue) => {
    const props: PostAccountQuestionProps = {
      companyName: data.company,
      email: data.email,
      phone: data.phone,
    };

    sendMicro(props);
  };

  useEffect(() => {
    if (isFetched) {
      if (!token) {
        navigate(PAGE_LOGIN);
      } else if (account?.step === 'fb_connect') {
        navigate(PAGE_PAGES + queryP);
      } else if (account?.step === 'complete') {
        navigate(PAGE_MAIN + urlP);
      }
    }
  }, [token, account, navigate, projectID, urlP, queryP, isFetched]);

  useDocumentTitle(t('title.micro'));

  return {
    pageName: question?.pageName || '',
    userName: question?.userName || '',
    email: account?.userName || '',
    industryItems,
    submit,
    isSendingMicro,
  };
};

import { Dialog, Select, SelectItem } from '@frontend/components/ui';
import { internalLinkToast } from '@frontend/components/utils';
import { useGetProjectID } from '@frontend/editor/data-access';
import {
  useGetEditorUniqueName,
  useGetProject,
  usePostFlowCopy,
} from '@frontend/sorghum/data-access';
import { PAGE_FLOW, usePath } from '@frontend/sorghum/utils';
import { FC, useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { FormInput } from '../form-input/form-input';

/* eslint-disable-next-line */
export interface CopyFlowModalProps {
  open: boolean;
  projectId: string;
  flowID: string;
  flowName: string;
  handleClose: () => void;
}

const formName = 'name';

type FormValues = {
  name: string;
  projectId: string;
  flowGroupId?: string;
};

export const CopyFlowModal: FC<CopyFlowModalProps> = ({
  projectId,
  flowID,
  flowName,
  open,
  handleClose,
}) => {
  const [t] = useTranslation();
  const { getUniqueName } = useGetEditorUniqueName();
  const { mutate: copyFlow } = usePostFlowCopy();
  const { navigateToProjectPage } = usePath();
  const { data: projectID } = useGetProjectID();
  const { data: projectData } = useGetProject(projectID);

  const [projectList, setProjectList] = useState<
    { label: string; value: string }[]
  >([]);

  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    defaultValues: {
      name: 'name',
      projectId: projectId,
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (open) {
      getUniqueName({
        projectId: projectId,
        type: 'flow',
        name: flowName + ' duplicate',
      }).then((data) => {
        reset({ name: data.data.name, projectId: projectId });
      });
    }
    // 讓 reset 不會觸發無限迴圈
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, projectId, flowName]);

  useEffect(() => {
    if (projectData) {
      setProjectList([
        { label: projectData.projectName, value: projectData.projectId },
      ]);
    }
  }, [projectData]);

  const onConfirm = useCallback(
    (data: FormValues) => {
      copyFlow(
        {
          flowId: flowID,
          // flowGroupId: data.flowGroupId,
          name: data.name,
          projectId: projectId,
        },
        {
          onSuccess: (data, variables) => {
            if (data.code === 20000) {
              internalLinkToast(
                t('flows.modal.copy.successInfo'),
                t('flows.modal.copy.successInfoLink'),
                'success',
                () =>
                  navigateToProjectPage(`${PAGE_FLOW}/${data?.data?.flowId}`),
              );
              handleClose();
            } else if (data.msg === 'Repeat flow name') {
              setError(formName, {
                message: t('flows.modal.copy.copyFlowError'),
              });
            } else {
              toast.error(t('flows.modal.copy.errorInfo'));
              handleClose();
            }
          },
        },
      );
    },
    [
      copyFlow,
      flowID,
      handleClose,
      navigateToProjectPage,
      projectId,
      setError,
      t,
    ],
  );

  const onSubmit = handleSubmit(onConfirm);

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <Dialog
          size="xs"
          title={t('flows.modal.copy.copyFlow')}
          open={open}
          handleClose={handleClose}
          cancelBtnText={t('common.cancel')}
          confirmBtnText={t('flows.modal.copy.confirmButton')}
          handleConfirm={onSubmit}
          cancelButtonID="copy-cancel"
          confirmButtonID="copy-copyflow"
          closeBtn={false}
        >
          <FormInput
            formName={formName}
            formError={errors}
            label={t('flows.modal.flowNameLabel')}
            register={register(formName, {
              required: t('flows.modal.copy.errorEmptyFlowName'),
              maxLength: {
                value: 100,
                message: t('flows.modal.copy.errorMaxLength'),
              },
            })}
          />
          <Select
            sx={{ width: '100%' }}
            label="Bot"
            disabled
            value={projectID}
            size="small"
            InputLabelProps={{ shrink: true }}
          >
            {projectList.map((item, index) => (
              <SelectItem
                key={item.value + index.toString()}
                value={item.value}
              >
                {item.label}
              </SelectItem>
            ))}
          </Select>
          {/* <FlowGroupSelect projectId={projectId} /> */}
        </Dialog>
      </form>
    </FormProvider>
  );
};

export default CopyFlowModal;

import { IconSizeType } from '@frontend/components/interface';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Box, Fade, Menu } from '@mui/material';
import { styled } from '@mui/system';
import { FC, PropsWithChildren, useState } from 'react';
import IconButton from '../icon-button/icon-button';

/* eslint-disable-next-line */
export interface EllipsisMenuProps {
  disabled?: boolean;
  color?: string;
}

const ContainerStyled = styled(Box)<{ $disabled?: boolean }>(
  ({ theme, $disabled }) => ({
    cursor: $disabled ? 'not-allowed' : 'pointer',
  }),
);

const MenuStyled = styled(Menu)(({ theme }) => ({}));

export const EllipsisMenu: FC<PropsWithChildren<EllipsisMenuProps>> = ({
  children,
  disabled = false,
  color,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ContainerStyled $disabled={disabled}>
      <IconButton
        $size={IconSizeType.S}
        onClick={handleClick}
        disabled={disabled}
      >
        <MoreHorizIcon sx={{ color }} />
      </IconButton>
      <MenuStyled
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            minWidth: '200px',
          },
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        TransitionComponent={Fade}
      >
        {children}
      </MenuStyled>
    </ContainerStyled>
  );
};

export default EllipsisMenu;

import { EditorCtx } from '@frontend/editor/external-providers';
import { ControlProps } from '@frontend/editor/interface';
import { styled } from '@mui/system';
import { memo, useContext } from 'react';
import { ControlButton, Controls } from 'reactflow';
import { ReactComponent as ArrowIcon } from '../../../images/arrow-icon.svg';
import { ReactComponent as GrabIcon } from '../../../images/grab-icon.svg';

const ControlsWrapperStyled = styled(Controls)(({ theme }) => ({
  '&.react-flow__panel': {
    display: 'flex',
    flexDirection: 'column',
  },

  '&.react-flow__controls': {
    boxShadow: 'none',
  },

  '& .react-flow__controls-button': {
    boxSizing: 'border-box',
    border: `1px solid ${theme.palette['grey']['300']}`,
    width: '36px',
    height: '36px',
    padding: '4px',
  },

  '& .react-flow__controls-button svg': {
    maxHeight: '14px',
    maxWidth: '14px',
  },

  '& .drag svg': {
    maxHeight: '16px',
    maxWidth: '16px',
  },

  '& .select svg': {
    maxHeight: '16px',
    maxWidth: '16px',
  },

  '& .react-flow__controls-zoomin': {
    marginTop: '4px',
    borderBottom: '0',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
  },

  '& .react-flow__controls-zoomout': {
    borderTop: '0',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
  },

  '& .react-flow__controls-fitview': {
    marginTop: '4px',
    borderRadius: '4px',
  },
}));

const ControlButtonStyled = styled(ControlButton)<{ $isActive: boolean }>(
  ({ theme, $isActive }) => ({
    backgroundColor: $isActive
      ? theme.palette['background']['primary']['10']
      : theme.palette['grey']['white'],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '&:hover': {
      backgroundColor: $isActive
        ? theme.palette['background']['primary']['10']
        : theme.palette['grey']['100'],
    },

    '&.drag': {
      order: -2,
      borderBottom: '0',
      paddingBottom: '2px',
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
    },

    '&.select': {
      order: -1,
      borderTop: '0',
      paddingTop: '2px',
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
    },
  }),
);

// const IconWrapperStyled = styled(Box)<{ $isActive: boolean }>(
//   ({ theme, $isActive }) => ({
//     backgroundColor: $isActive
//       ? theme.palette['background']['primary']['10']
//       : 'transparent',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     width: '26px',
//     height: '26px',

//     '&:hover': {
//       backgroundColor: $isActive
//         ? theme.palette['background']['primary']['10']
//         : theme.palette['grey']['100'],
//     },
//   }),
// );

export const Control = ({
  showInteractive = false,
  addBlock,
  onRestore,
  onSave,
}: ControlProps) => {
  const state = useContext(EditorCtx);
  const { setIsFlowSelectable, readonly } = state;

  return (
    <ControlsWrapperStyled showInteractive={showInteractive}>
      {!readonly && (
        <>
          <ControlButtonStyled
            title="drag"
            aria-label="drag"
            className="drag"
            $isActive={!state.isFlowSelectable}
            onClick={() => setIsFlowSelectable(false)}
          >
            <GrabIcon />
          </ControlButtonStyled>
          <ControlButtonStyled
            title="select"
            aria-label="select"
            className="select"
            $isActive={state.isFlowSelectable}
            onClick={() => setIsFlowSelectable(true)}
          >
            <ArrowIcon />
          </ControlButtonStyled>
        </>
      )}
    </ControlsWrapperStyled>
  );
};

export default memo(Control);

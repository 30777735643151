import { StepperProps } from '@frontend/components/interface';

import { Step as MuiStep, Stepper as MuiStepper, styled } from '@mui/material';
import Box from '@mui/material/Box';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import { FC } from 'react';
import Button from '../button/button';
import { FinishStepIcon } from '../icons';
import Typography from '../typography/typography';

const StepLabelWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
}));

const StepLabelTitleTypographyStyled = styled(Typography)(({ theme }) => ({
  width: '360px',
}));

const StepperContainerStyled = styled(Box)(({ theme }) => ({
  '.MuiStepLabel-iconContainer': {
    '& > svg': {
      text: {
        fontSize: '14px',
      },
    },
  },
}));

export const Stepper: FC<StepperProps> = ({
  steps,
  activeStep,
  labelDone,
  labelNext,
  labelBack,
  readOnly,
}) => {
  return (
    <StepperContainerStyled>
      <MuiStepper activeStep={activeStep} orientation="vertical">
        {steps.map(
          (
            {
              label,
              description,
              isCompleted,
              content,
              handleNext,
              handleBack,
              disabled,
            },
            index,
          ) => (
            <MuiStep key={label} {...(readOnly && { active: true })}>
              <StepLabel
                StepIconComponent={
                  activeStep > index || isCompleted
                    ? () => <FinishStepIcon />
                    : undefined
                }
              >
                <StepLabelWrapperStyled>
                  <StepLabelTitleTypographyStyled variant="subtitle1">
                    {label}
                  </StepLabelTitleTypographyStyled>
                  {/* // step 收合並完成時才會顯示的提示內容 */}
                  {isCompleted &&
                    activeStep !== index &&
                    !readOnly &&
                    description &&
                    description()}
                </StepLabelWrapperStyled>
              </StepLabel>
              <StepContent>
                {content && content()}

                {/* readOnly 會全部展開，不會有按鈕 */}
                {!readOnly && (
                  <Box sx={{ mb: '-20px' }}>
                    <div>
                      {handleNext && (
                        <Button
                          variant="contained"
                          onClick={handleNext}
                          sx={{ mt: 1, mr: 1 }}
                          disabled={disabled}
                        >
                          {index === steps.length - 1 ? labelDone : labelNext}
                        </Button>
                      )}
                      {handleBack && (
                        <Button
                          disabled={index === 0}
                          onClick={handleBack}
                          sx={{ mt: 1, mr: 1 }}
                          variant="text"
                        >
                          {labelBack}
                        </Button>
                      )}
                    </div>
                  </Box>
                )}
              </StepContent>
            </MuiStep>
          ),
        )}
      </MuiStepper>
    </StepperContainerStyled>
  );
};

export default Stepper;

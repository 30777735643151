import { GetBillingOrderResultResponse } from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { BILLING_ORDER_RESULT } from '../apis';

const defaultData = {
  status: -1,
  shortCode: '',
  projectId: '',
};

async function getBillingOrderResult(
  uuid: string,
  projectID?: string,
  methodID?: string,
): Promise<GetBillingOrderResultResponse> {
  const token = localStorage.getItem('token') as string;
  const newAxios = axios.create({
    headers: {
      'gosky-api-token': token,
      ...(projectID && { 'project-id': projectID }),
    },
  });
  return newAxios
    .get(
      `${BILLING_ORDER_RESULT}?paymentId=${uuid}${
        methodID ? `&methodId=${methodID}` : ''
      }`,
    )
    .then((res) => res.data.data)
    .catch((err) => {
      return defaultData;
    });
}

export function useGetBillingOrderResult(
  paymentID: string,
  projectID?: string,
  methodID?: string,
) {
  return useQuery<GetBillingOrderResultResponse>({
    queryKey: [
      'get-project-billing-order-result',
      { paymentID, projectID, methodID },
    ],
    queryFn: () => {
      if (paymentID) {
        return getBillingOrderResult(paymentID, projectID, methodID);
      } else {
        return defaultData;
      }
    },
  });
}

import {
  AccountPermission,
  ResponseWithData,
  UsePostProjectInviteResponse,
} from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { PROJECT_INVITE } from '../apis';

const defaultData: UsePostProjectInviteResponse = {
  url: '',
};

async function postProjectInvite(
  permission: number,
): Promise<ResponseWithData<UsePostProjectInviteResponse>> {
  return axios
    .post(PROJECT_INVITE, {
      permission,
    })
    .then((res) => res.data)
    .catch((err) => {
      return defaultData;
    });
}

export function usePostProjectInvite() {
  return useMutation({
    mutationKey: ['post-project-invite'],
    // 2: admin, 3: viewer
    mutationFn: (permission: AccountPermission) =>
      postProjectInvite(permission),
  });
}

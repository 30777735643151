import { globalTheme } from '@frontend/components/external-providers';
import { Typography } from '@frontend/components/ui';
import {
  REGEX_CHECK_EMAIL,
  REGEX_CHECK_TAIWAN_PHONE_NUMBER,
} from '@frontend/editor/utils';
import { initTapPay } from '@frontend/sorghum/utils';
import { Box, styled } from '@mui/material';
import { get } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';

export interface TapPayFormValue {
  name: string;
  email: string;
  phoneNumber: string;
}

interface TapPayFormProps {
  appID: number;
  appKey: string;
  value: TapPayFormValue;
  onChange: (data: TapPayFormValue) => void;
  onValidate: (result: boolean) => void;
}

type TypPayFormKey = 'name' | 'email' | 'phone';

const ContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',

  '& .tpfield': {
    height: '40px',
    width: '100%',
    padding: '8.5px 14px',
    border: `1px solid ${theme.palette['grey']['500']}`,
    borderRadius: '4px',
  },

  '& .tappay-field-focus': {
    borderWidth: '2px',
    borderColor: theme.palette['primary']['main'],
  },

  '& .has-error': {
    borderColor: theme.palette['error']['main'],
  },

  '& .display': {
    display: 'block',
  },
}));

const InputWrapperStyled = styled(Box)<{ $error?: boolean }>(
  ({ theme, $error }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',

    '& > input': {
      color: theme.palette['grey']['500'],
      height: '40px',
      width: '100%',
      padding: '8.5px 14px',
      border: `1px solid ${theme.palette['grey']['500']}`,
      borderRadius: '4px',
      '&:focus': {
        borderColor: theme.palette['primary']['main'],
      },

      ...($error && {
        borderColor: theme.palette['error']['main'],
      }),
    },

    // phone input css
    '& .react-tel-input .form-control': {
      width: '100% !important',
      height: '40px !important',
    },
    '& .flag-dropdown': {
      backgroundColor: 0,
    },
  }),
);

export const TapPayForm: FC<TapPayFormProps> = ({
  appID,
  appKey,
  value,
  onChange,
  onValidate,
}) => {
  const [t] = useTranslation();
  const [canGetPrime, setCanGetPrime] = useState(false);
  const [CVCError, setCVCError] = useState(false);
  const [cardNumberError, setCardNumberError] = useState(false);
  const [expiryDateError, setExpiryDateError] = useState(false);

  const [inputError, setInputError] = useState<{
    [key in TypPayFormKey]: boolean;
  }>({
    name: false,
    email: false,
    phone: false,
  });

  const checkInputValue = useCallback(
    (key: TypPayFormKey) => {
      switch (key) {
        case 'name': {
          if (value.name === '') {
            setInputError((prev) => ({ ...prev, name: true }));
          } else {
            setInputError((prev) => ({ ...prev, name: false }));
          }
          break;
        }
        case 'email': {
          const checkMail = new RegExp(REGEX_CHECK_EMAIL);
          if (!checkMail.test(value.email)) {
            setInputError((prev) => ({ ...prev, email: true }));
          } else {
            setInputError((prev) => ({ ...prev, email: false }));
          }
          break;
        }
        case 'phone': {
          if (value.phoneNumber.startsWith('886')) {
            const checkPhone = new RegExp(REGEX_CHECK_TAIWAN_PHONE_NUMBER);

            if (checkPhone.test(value.phoneNumber)) {
              setInputError((prev) => ({ ...prev, phone: false }));
            } else {
              setInputError((prev) => ({ ...prev, phone: true }));
            }
          } else {
            if (value.phoneNumber === '') {
              setInputError((prev) => ({ ...prev, phone: true }));
            } else {
              setInputError((prev) => ({ ...prev, phone: false }));
            }
          }
          break;
        }
        default:
          break;
      }
    },
    [value.email, value.name, value.phoneNumber],
  );

  const handleSetError = useCallback(
    (key: 'number' | 'expiry' | 'CVC', value: boolean) => {
      switch (key) {
        case 'number': {
          setCardNumberError(value);
          break;
        }
        case 'expiry': {
          setExpiryDateError(value);
          break;
        }
        case 'CVC': {
          setCVCError(value);
          break;
        }
        default:
          break;
      }
    },
    [],
  );

  useEffect(() => {
    initTapPay(appID, appKey, setCanGetPrime, handleSetError);
  }, [appID, appKey, handleSetError]);

  useEffect(() => {
    if (!canGetPrime) {
      onValidate(false);
    } else {
      if (!value.name || !value.email || !value.phoneNumber) {
        onValidate(false);
      } else if (inputError.name || inputError.email || inputError.phone) {
        onValidate(false);
      } else {
        onValidate(true);
      }
    }
  }, [
    canGetPrime,
    inputError.email,
    inputError.name,
    inputError.phone,
    onValidate,
    value.email,
    value.name,
    value.phoneNumber,
  ]);

  return (
    <ContainerStyled>
      <InputWrapperStyled>
        <Typography variant="caption" color="grey.700">
          {t('setting.payment.form.cardNumber.label')}
        </Typography>
        <div className="tpfield" id="card-number" />
        {cardNumberError && (
          <Typography variant="caption" color="error">
            {t('setting.payment.form.cardNumber.error')}
          </Typography>
        )}
      </InputWrapperStyled>
      <Box
        sx={{
          display: 'inline-flex',
          gap: '12px',
        }}
      >
        <Box
          sx={{
            flex: 1,
          }}
        >
          <InputWrapperStyled>
            <Typography variant="caption" color="grey.700">
              {t('setting.payment.form.expiry.label')}
            </Typography>

            <div className="tpfield" id="card-expiration-date" />
            {expiryDateError && (
              <Typography variant="caption" color="error">
                {t('setting.payment.form.expiry.error')}
              </Typography>
            )}
          </InputWrapperStyled>
        </Box>
        <Box
          sx={{
            flex: 1,
          }}
        >
          <InputWrapperStyled>
            <Typography variant="caption" color="grey.700">
              {t('setting.payment.form.cvc.label')}
            </Typography>

            <div className="tpfield" id="card-ccv" />
            {CVCError && (
              <Typography variant="caption" color="error">
                {t('setting.payment.form.cvc.error')}
              </Typography>
            )}
          </InputWrapperStyled>
        </Box>
      </Box>
      <InputWrapperStyled $error={inputError.name}>
        <Typography variant="caption" color="grey.700">
          {t('setting.payment.form.cardName.label')}
        </Typography>

        <input
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChange({
              ...value,
              name: e.target.value,
            })
          }
          onBlur={() => checkInputValue('name')}
          value={value.name}
        />
        {inputError.name && (
          <Typography variant="caption" color="error">
            {t('setting.payment.form.cardName.error')}
          </Typography>
        )}
      </InputWrapperStyled>
      <InputWrapperStyled $error={inputError.email}>
        <Typography variant="caption" color="grey.700">
          {t('setting.payment.form.email.label')}
        </Typography>
        <input
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChange({ ...value, email: e.target.value })
          }
          onBlur={() => checkInputValue('email')}
          value={value.email}
        />
        {inputError.email && (
          <Typography variant="caption" color="error">
            {t('setting.payment.form.email.error')}
          </Typography>
        )}
      </InputWrapperStyled>
      <InputWrapperStyled $error={inputError.phone}>
        <Typography variant="caption" color="grey.700">
          {t('setting.payment.form.phoneNumber.label')}
        </Typography>

        <PhoneInput
          country={'tw'}
          masks={{ tw: '..........' }}
          onChange={(_value: string) =>
            onChange({ ...value, phoneNumber: _value })
          }
          onBlur={() => checkInputValue('phone')}
          value={value.phoneNumber}
          placeholder="+886"
          inputProps={{
            style: {
              color: get(globalTheme, 'palette.grey.500'),
              ...(inputError.phone && {
                borderColor: get(globalTheme, 'palette.error.main'),
              }),
            },
          }}
          buttonStyle={{
            ...(inputError.phone && {
              borderColor: get(globalTheme, 'palette.error.main'),
            }),
          }}
        />
        {inputError.phone && (
          <Typography variant="caption" color="error">
            {t('setting.payment.form.phoneNumber.error')}
          </Typography>
        )}
      </InputWrapperStyled>
    </ContainerStyled>
  );
};

export default TapPayForm;

import { TourMaskProps } from '@frontend/components/interface';
import { Alert } from '@frontend/components/utils';
import {
  FlowTemplateEnum,
  LoadingModalTypeEnum,
  MissionModalTypeEnum,
  ModalContext,
  ModalTypesEnum,
} from '@frontend/sorghum/interface';
import { get } from 'lodash';
import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

export const UICtx = createContext<ModalContext>({} as ModalContext);

export const UIProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const outletRef = useRef<HTMLDivElement>(null);
  const [t] = useTranslation();
  // 控制打開的 modal
  const [modalType, setModalType] = useState<ModalTypesEnum>(
    ModalTypesEnum.CLOSE,
  );
  // flow id
  const [flowID, setFlowID] = useState<string>('');
  const [name, setName] = useState<string>('');

  const [isUpdated, setIsUpdated] = useState<boolean>(false);

  // sequence id
  const [sequenceID, setSequenceID] = useState<string>('');
  const [sequenceName, setSequenceName] = useState<string>('');

  const [couponID, setCouponID] = useState<string>('');
  const [couponName, setCouponName] = useState<string>('');
  // 共用提示訊息
  // TODO: 之後可以整理成一整個 object

  const [tutorialModalType, setTutorialModalType] = useState<FlowTemplateEnum>(
    FlowTemplateEnum.RN,
  );
  const [tours, setTours] = useState<TourMaskProps[]>([]);
  const [tourStep, setTourStep] = useState(0);
  const [isTouring, setIsTouring] = useState(false);

  // 共用 id
  const [dataID, setDataID] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [confirm, setConfirm] = useState<string>('');
  const [cancel, setCancel] = useState<string>('');
  const [needRedirect, setNeedRedirect] = useState<boolean>(false);
  const [modalData, setModalData] = useState<{
    color?: 'error' | 'primary';
    onConfirm: () => void;
  }>({
    onConfirm: () => null,
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loadingList, setLoadingList] = useState<string[]>([]);
  const [loadingModalType, setLoadingModalType] = useState(
    LoadingModalTypeEnum.BLOCK,
  );
  // project 側邊欄開啟的狀態
  const [isOpenLeftSidebar, setIsOpenLeftSidebar] = useState<boolean>(false);
  // 任務 modal 的開啟狀態
  const [openMissionModal, setOpenMissionModal] =
    useState<MissionModalTypeEnum>(MissionModalTypeEnum.CLOSE);
  const [isOpenMissionListModal, setIsOpenMissionListModal] =
    useState<boolean>(false);

  // leftSidebar 第二層的開啟狀態
  const [isOpenProjectList, setIsOpenProjectList] = useState<boolean>(false);
  const [isOpenViewOnlyModeModal, setIsOpenViewOnlyModeModal] = useState(false);

  const handleSetName = (_name: string) => setName(_name);
  // set flow id
  const handleSetID = (_id: string) => setFlowID(_id);
  // set sequence id
  const handleSetSequenceID = (_id: string) => setSequenceID(_id);
  // set sequence name
  const handleSetSequenceName = (_name: string) => setSequenceName(_name);
  const handleSetCouponName = (_name: string) => setCouponName(_name);

  const openModal = useCallback((type: ModalTypesEnum) => {
    setModalType(type);
  }, []);

  const closeModal = useCallback(() => {
    setModalType(ModalTypesEnum.CLOSE);
  }, []);

  const openCommonModal = useCallback(
    (
      title: string,
      content: string,
      handleConfirm: () => void,
      confirmBtnText?: string,
      confirmBtnColor?: 'error' | 'primary',
    ) => {
      setTitle(title);
      setContent(content);
      setModalType(ModalTypesEnum.COMMON);
      setConfirm(confirmBtnText ? confirmBtnText : t('common.ok'));
      setCancel(t('common.cancel'));
      setModalData({
        color: confirmBtnColor || 'primary',
        onConfirm: handleConfirm,
      });
    },
    [t],
  );

  // 打開確認是否要離開本頁面的 modal，要傳入欲執行的 callback
  const openNavigateModal = useCallback(
    (handleConfirm: () => void) => {
      setTitle(t('modal.navigate.title'));
      setContent(t('modal.navigate.content'));
      setModalType(ModalTypesEnum.COMMON);
      setConfirm(t('modal.navigate.confirm'));
      setCancel(t('modal.navigate.cancel'));
      setModalData({ onConfirm: handleConfirm });
    },
    [t],
  );

  const getFlowDataInPersistentMenu = (flowData: {
    id: string;
    label: string;
  }) => {
    return Alert.info(
      t('basicSetting.persistentMenu.entryToast.content', {
        flowName: flowData.label,
      }),
    );
  };

  const openRenameBroadcastModal = useCallback((id: string) => {
    setFlowID(id);
    setModalType(ModalTypesEnum.RENAME_BROADCAST);
  }, []);

  const openRenameCouponModal = useCallback((id: string) => {
    setCouponID(id);
    setModalType(ModalTypesEnum.RENAME_COUPON);
  }, []);

  const openCreateProjectModal = useCallback(() => {
    setModalType(ModalTypesEnum.CREATE_PROJECT);
  }, []);

  const openRenameProjectModal = useCallback((projectID: string) => {
    setDataID(projectID);
    setModalType(ModalTypesEnum.RENAME_PROJECT);
  }, []);

  const openRenameCommentAutoReplyModal = useCallback((id: string) => {
    setDataID(id);
    setModalType(ModalTypesEnum.RENAME_COMMENT_AUTO_REPLY);
  }, []);

  const openDeleteCommentAutoReplyModal = useCallback(
    (id: string, needRedirect?: boolean) => {
      setDataID(id);
      setNeedRedirect(needRedirect || false);
      setModalType(ModalTypesEnum.DELETE_COMMENT_AUTO_REPLY);
    },
    [],
  );

  const openTutorialModal = useCallback((modalType: FlowTemplateEnum) => {
    setModalType(ModalTypesEnum.TUTORIAL_MODAL);
    setTutorialModalType(modalType);
  }, []);

  const openChangePaymentMethodModal = useCallback(() => {
    setModalType(ModalTypesEnum.CHANGE_PAYMENT_METHOD);
  }, []);

  const scrollToBottom = useCallback(() => {
    if (outletRef) {
      outletRef.current?.scrollTo({ top: 999999999, behavior: 'smooth' });
    }
  }, []);

  const nextTour = useCallback(() => {
    setTourStep((prev) => prev + 1);
  }, []);

  const prevTour = useCallback(() => {
    setTourStep((prev) => prev - 1);
  }, []);

  const setTour = useCallback((newTour: TourMaskProps[]) => {
    setTours(newTour);
    setTourStep(0);
  }, []);

  const addLoadingStatus = useCallback(
    (id: string) => {
      if (!loadingList.includes(id)) {
        setLoadingList((prev) => [...prev, id]);
      }
    },
    [loadingList],
  );

  const removeLoadingStatus = useCallback(
    (id: string) => {
      if (loadingList.includes(id)) {
        setLoadingList(loadingList.filter((i) => i !== id));
      }
    },
    [loadingList],
  );

  const resetLoadingStatus = useCallback(() => {
    setLoadingList([]);
  }, []);

  const handleLoadingModalOpen = useCallback(
    (id: string, modalType?: LoadingModalTypeEnum) => {
      addLoadingStatus(id);
      setLoadingModalType(modalType || LoadingModalTypeEnum.BLOCK);
    },
    [addLoadingStatus],
  );

  const openViewerModeModal = useCallback(() => {
    setModalType(ModalTypesEnum.VIEWER_MODE);
  }, []);

  return (
    <UICtx.Provider
      value={{
        outletRef,
        dataID,
        flowID,
        name,
        modalType,
        title,
        content,
        cancel,
        confirm,
        needRedirect,
        modalData,
        isUpdated,
        tutorialModalType,
        tour: get(tours, tourStep, null),
        isTouring,
        setIsTouring,
        nextTour,
        prevTour,
        setTour,
        openTutorialModal,
        scrollToBottom,
        isLoading,
        loadingModalType,
        setIsLoading: handleLoadingModalOpen,
        setDataID,
        setIsUpdated,
        handleSetName,
        handleSetID,
        openModal,
        closeModal,
        openCommonModal,
        openNavigateModal,
        getFlowDataInPersistentMenu,
        handleSetSequenceID,
        sequenceID,
        sequenceName,
        handleSetSequenceName,
        openRenameBroadcastModal,
        openRenameCouponModal,
        openCreateProjectModal,
        openRenameCommentAutoReplyModal,
        openDeleteCommentAutoReplyModal,
        openRenameProjectModal,
        couponID,
        handleSetCouponName,
        couponName,
        isOpenViewOnlyModeModal,
        setIsOpenViewOnlyModeModal,
        openChangePaymentMethodModal,
        isOpenLeftSidebar,
        setIsOpenLeftSidebar,
        openMissionModal,
        setOpenMissionModal,
        isOpenMissionListModal,
        setIsOpenMissionListModal,
        toggleMissionListModal: () =>
          setIsOpenMissionListModal(!isOpenMissionListModal),
        isOpenProjectList,
        setIsOpenProjectList,
        addLoadingStatus,
        removeLoadingStatus,
        loadingList,
        resetLoadingStatus,
        openViewerModeModal,
      }}
    >
      {children}
    </UICtx.Provider>
  );
};

import { UICtx } from '@frontend/editor/external-providers';
import { DrawerTypesEnum } from '@frontend/editor/interface';
import { useContext } from 'react';
import DrawerActionAttribute from '../drawer-action-attribute/drawer-action-attribute';
import DrawerActionSequence from '../drawer-action-sequence/drawer-action-sequence';
import DrawerActionSheet from '../drawer-action-sheet/drawer-action-sheet';
import DrawerBotLink from '../drawer-bot-link/drawer-bot-link';
import DrawerCollectUserAnswer from '../drawer-collect-user-answer/drawer-collect-user-answer';
import DrawerCondition from '../drawer-condition/drawer-condition';
import DrawerCoupon from '../drawer-coupon/drawer-coupon';

export const EditorDrawerGroup = () => {
  const { drawerType } = useContext(UICtx);

  switch (drawerType) {
    case DrawerTypesEnum.BOT_LINK: {
      return <DrawerBotLink />;
    }
    case DrawerTypesEnum.CONDITION_CELL: {
      return <DrawerCondition />;
    }
    case DrawerTypesEnum.COUPON: {
      return <DrawerCoupon />;
    }
    case DrawerTypesEnum.ACTION_EXPORT_GOOGLE_SHEET: {
      return <DrawerActionSheet />;
    }
    case DrawerTypesEnum.ACTION_SEQUENCE: {
      return <DrawerActionSequence />;
    }
    case DrawerTypesEnum.ACTION_ATTRIBUTE: {
      return <DrawerActionAttribute />;
    }
    case DrawerTypesEnum.COLLECT_USER_ANSWER: {
      return <DrawerCollectUserAnswer />;
    }
    default:
      return null;
  }
};

export default EditorDrawerGroup;

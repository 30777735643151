import { EditorCtx } from '@frontend/editor/external-providers';
import {
  FlowEntryPoint,
  TextButtonCellTypesEnum,
} from '@frontend/editor/interface';
import { useCallback, useContext } from 'react';
import { useUpdateNodeInternals } from 'reactflow';

export interface UseCanvasUpdate {
  canvasUpdateButtonType: (
    elementID: string,
    type: TextButtonCellTypesEnum,
  ) => void;
  canvasUpdateLabel: (id: string, value: string) => void;
  canvasConnectBlock: (outputID: string, inputBlockID: string) => void;
  canvasSortElement: (id: string, oldIndex: number, newIndex: number) => void;
  canvasDisconnectBlock: (outputID: string) => void;
  canvasUpdateFlowEntryPoint: (newFlowEntries: FlowEntryPoint[]) => void;
  canvasUpdateData: (id: string, path: string, value: any) => void;
}

export function useCanvasUpdate(): UseCanvasUpdate {
  const state = useContext(EditorCtx);
  const updateNodeInternals = useUpdateNodeInternals();

  const canvasUpdateLabel = useCallback(
    (id: string, value: string) => {
      state.updateElementData(id, 'label', value);
    },
    [state],
  );

  const canvasConnectBlock = useCallback(
    (outputID: string, inputBlockID: string) => {
      state.connectElement(outputID, inputBlockID);
    },
    [state],
  );

  const canvasDisconnectBlock = useCallback(
    (outputID: string) => {
      state.removeConnect(outputID);
    },
    [state],
  );

  const canvasSortElement = useCallback(
    (id: string, oldIndex: number, newIndex: number) => {
      const el = state.getElement(id);
      state.sortElement(id, oldIndex, newIndex);

      // 觸發 node rerender 以更新 handle point position
      if (el?.nodeID) {
        updateNodeInternals(el.nodeID);
      } else if (el) {
        // updateNodeInternals 會執行得比 sortElement 快，所以設 setTimeout 確保 sortElement 會先執行
        setTimeout(() => updateNodeInternals(el.id), 500);
      }
    },
    [state, updateNodeInternals],
  );

  const canvasUpdateFlowEntryPoint = useCallback(
    (newFlowEntries: FlowEntryPoint[]) => {
      state.handleSetFlowEntryPoint(newFlowEntries);
    },
    [state],
  );

  const canvasUpdateButtonType = useCallback(
    (id: string, type: TextButtonCellTypesEnum) => {
      state.updateElementData(id, 'buttonType', type);
    },
    [state],
  );

  const canvasUpdateData = useCallback(
    (id: string, path: string, value: any) => {
      state.updateElementData(id, path, value);
    },
    [state],
  );

  return {
    canvasUpdateButtonType,
    canvasUpdateLabel,
    canvasConnectBlock,
    canvasSortElement,
    canvasDisconnectBlock,
    canvasUpdateFlowEntryPoint,
    canvasUpdateData,
  };
}

export default useCanvasUpdate;

import {
  Button,
  CountrySelect,
  Select,
  SelectItem,
  Typography,
} from '@frontend/components/ui';
import { Alert } from '@frontend/components/utils';
import { useGetProjectID } from '@frontend/editor/data-access';
import {
  REGEX_CHECK_CHARITY_CODE,
  REGEX_CHECK_CITIZEN_CODE,
  REGEX_CHECK_ONLY_DIGIT,
  REGEX_MOBILE_BARCODE,
} from '@frontend/editor/utils';
import {
  useGetBillingPayment,
  usePatchBillingPayment,
} from '@frontend/sorghum/data-access';
import { EditInvoiceFormType } from '@frontend/sorghum/interface';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { styled } from '@mui/system';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Control,
  FieldErrorsImpl,
  useForm,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormInput from '../form-input/form-input';

const ModalContentContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

export interface EditInvoiceModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const SelectContainerStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: '8px 0 20px 0',
}));

const FormContainerStyled = styled(Box)(({ theme }) => ({
  width: '100%',
}));

const ButtonContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
  flexDirection: 'row',
  gap: '16px',
}));

export const InvoiceContent = ({
  entityType,
  setEntityType,
  onSubmitForm,
  register,
  errors,
  onCancel,
  cancelButtonText,
  formData,
  control,
  setValue,
  confirmButtonID,
}: {
  entityType: number;
  setEntityType: (entityType: 1 | 2) => void;
  onSubmitForm: (e: any) => void;
  register: UseFormRegister<EditInvoiceFormType>;
  errors: FieldErrorsImpl<{
    country: string;
    address: string;
    zip: string;
    tax: string;
    name: string;
    carrierType: number;
  }>;
  onCancel: () => void;
  cancelButtonText: string;
  formData: EditInvoiceFormType;
  control: Control<EditInvoiceFormType>;
  setValue: UseFormSetValue<EditInvoiceFormType>;
  confirmButtonID?: string;
}) => {
  const [t] = useTranslation();
  const mobileBarcodeRegex = new RegExp(REGEX_MOBILE_BARCODE);
  const citizenRegex = new RegExp(REGEX_CHECK_CITIZEN_CODE);
  const charityRegex = new RegExp(REGEX_CHECK_CHARITY_CODE);
  const onlyDigitRegex = new RegExp(REGEX_CHECK_ONLY_DIGIT);

  const invoiceCarrierList = [
    {
      id: 1,
      name: t('setting.paymentDetails.editInvoiceModal.invoiceList.mobile'),
    },
    {
      id: 2,
      name: t('setting.paymentDetails.editInvoiceModal.invoiceList.citizen'),
    },
    {
      id: 3,
      name: t('setting.paymentDetails.editInvoiceModal.invoiceList.eInvoice'),
    },
    {
      id: 4,
      name: t('setting.paymentDetails.editInvoiceModal.invoiceList.donation'),
    },
  ];

  const codeLabelName = (type: number) => {
    switch (type) {
      case 1:
        return t('setting.paymentDetails.editInvoiceModal.mobileBarcode');
      case 2:
        return t('setting.paymentDetails.editInvoiceModal.citizenCode');
      case 4:
        return t('setting.paymentDetails.editInvoiceModal.charityNumber');
      default:
        return '';
    }
  };

  const codeErrorText = (type: number) => {
    switch (type) {
      case 1:
        return t('setting.paymentDetails.editInvoiceModal.mobileBarcodeError');
      case 2:
        return t('setting.paymentDetails.editInvoiceModal.citizenCodeError');
      case 4:
        return t('setting.paymentDetails.editInvoiceModal.charityNumberError');
      default:
        return '';
    }
  };

  const personalCountryIsNotTaiwan = useMemo(() => {
    if (formData.country) {
      return formData.country !== 'TW';
    }
    return false;
  }, [formData]);

  const filteredInvoiceCarrierList = personalCountryIsNotTaiwan
    ? invoiceCarrierList.filter((item) => item.id === 3)
    : invoiceCarrierList;

  const isDisableContinue = useCallback(() => {
    if (entityType === 1) {
      if (
        !formData.name ||
        !formData.country ||
        !formData.address ||
        !formData.carrierType
      ) {
        return true;
      }
      return false;
    } else if (entityType === 2) {
      if (
        !formData.companyName ||
        !formData.companyCountry ||
        !formData.companyAddress
      ) {
        return true;
      }

      if (formData.companyCountry === 'TW') {
        if (!formData.tax) {
          return true;
        }
        return false;
      }

      return false;
    }
    return false;
  }, [
    entityType,
    formData.address,
    formData.carrierType,
    formData.companyAddress,
    formData.companyCountry,
    formData.companyName,
    formData.country,
    formData.name,
    formData.tax,
  ]);

  useEffect(() => {
    if (personalCountryIsNotTaiwan) {
      setValue('carrierType', 3);
    } else {
      setValue('carrierType', 0);
    }
  }, [personalCountryIsNotTaiwan, setValue]);

  return (
    <Box>
      <RadioGroup row value={entityType}>
        <FormControlLabel
          value="1"
          control={<Radio size="small" />}
          label={
            <Typography variant="body1" color="grey.800">
              {t('setting.paymentDetails.editInvoiceModal.personal')}
            </Typography>
          }
          onClick={() => setEntityType(1)}
        />
        <FormControlLabel
          value="2"
          control={<Radio size="small" />}
          label={
            <Typography variant="body1" color="grey.800">
              {t('setting.paymentDetails.editInvoiceModal.company')}
            </Typography>
          }
          onClick={() => setEntityType(2)}
        />
      </RadioGroup>
      <form onSubmit={onSubmitForm}>
        {entityType === 1 && (
          <FormContainerStyled>
            <FormInput
              formName="name"
              label={t('setting.paymentDetails.editInvoiceModal.name')}
              register={register('name', {
                required: t(
                  'setting.paymentDetails.editInvoiceModal.nameError',
                ),
              })}
              formError={errors}
            />
            <SelectContainerStyled>
              <CountrySelect
                control={control}
                formName="country"
                label={t('setting.paymentDetails.editInvoiceModal.country')}
                errorMessage={t(
                  'setting.paymentDetails.editInvoiceModal.countryError',
                )}
              />
            </SelectContainerStyled>
            <FormInput
              formName="address"
              label={t('setting.paymentDetails.editInvoiceModal.address')}
              register={register('address', {
                required: t(
                  'setting.paymentDetails.editInvoiceModal.addressError',
                ),
              })}
              formError={errors}
            />
            <FormInput
              formName="zip"
              label={t('setting.paymentDetails.editInvoiceModal.zip')}
              register={register('zip')}
              formError={errors}
            />
            <SelectContainerStyled>
              <Select
                fullWidth
                size="small"
                formName="carrierType"
                label={t('setting.paymentDetails.editInvoiceModal.invoice')}
                value={formData.carrierType ?? 0}
                register={register('carrierType', {
                  required: t(
                    'setting.paymentDetails.editInvoiceModal.invoiceError',
                  ),
                })}
                error={errors && !!errors['carrierType']}
                errorMessage={t(
                  'setting.paymentDetails.editInvoiceModal.invoiceError',
                )}
                defaultValue={0}
              >
                <SelectItem value={0} hidden sx={{ display: 'none' }}>
                  <Typography color="grey.black" sx={{ opacity: 0.6 }}>
                    {t('setting.paymentDetails.editInvoiceModal.invoice')}
                  </Typography>
                </SelectItem>
                {filteredInvoiceCarrierList.map((item) => (
                  <SelectItem key={item.id} value={item.id}>
                    {item.name}
                  </SelectItem>
                ))}
              </Select>
            </SelectContainerStyled>
            {formData.carrierType === 1 ||
            formData.carrierType === 2 ||
            formData.carrierType === 4 ? (
              <FormInput
                formName="code"
                label={codeLabelName(formData.carrierType)}
                register={register('code', {
                  required: codeErrorText(formData.carrierType),
                  validate: (val) => {
                    if (formData.carrierType === 1) {
                      if (!mobileBarcodeRegex.test(val)) {
                        return false;
                      }
                      return true;
                    } else if (formData.carrierType === 2) {
                      if (!citizenRegex.test(val)) {
                        return false;
                      }
                      return true;
                    } else if (formData.carrierType === 4) {
                      if (!charityRegex.test(val)) {
                        return false;
                      }
                      return true;
                    }
                    return true;
                  },
                })}
                formError={errors}
              />
            ) : null}
          </FormContainerStyled>
        )}
        {entityType === 2 && (
          <FormContainerStyled>
            <FormInput
              formName="companyName"
              label={t('setting.paymentDetails.editInvoiceModal.companyName')}
              register={register('companyName', {
                required: t(
                  'setting.paymentDetails.editInvoiceModal.companyNameError',
                ),
              })}
              formError={errors}
            />
            <SelectContainerStyled>
              <CountrySelect
                control={control}
                formName="companyCountry"
                label={t('setting.paymentDetails.editInvoiceModal.country')}
                errorMessage={t(
                  'setting.paymentDetails.editInvoiceModal.countryError',
                )}
              />
            </SelectContainerStyled>
            <FormInput
              formName="companyAddress"
              label={t(
                'setting.paymentDetails.editInvoiceModal.companyAddress',
              )}
              register={register('companyAddress', {
                required: t(
                  'setting.paymentDetails.editInvoiceModal.companyAddressError',
                ),
              })}
              formError={errors}
            />
            <FormInput
              formName="companyZip"
              label={t('setting.paymentDetails.editInvoiceModal.zip')}
              register={register('companyZip')}
              formError={errors}
            />
            <FormInput
              formName="tax"
              label={`${t('setting.paymentDetails.editInvoiceModal.taxID')}${
                formData.companyCountry === 'TW' ? ' *' : ''
              }`}
              register={register('tax', {
                validate: (val) => {
                  if (formData.companyCountry === 'TW') {
                    if (isEmpty(val)) {
                      return t(
                        'setting.paymentDetails.editInvoiceModal.taxIDError',
                      );
                    }
                  }

                  if (!isEmpty(val) && !onlyDigitRegex.test(val)) {
                    return t(
                      'setting.paymentDetails.editInvoiceModal.taxIDError',
                    );
                  }

                  return true;
                },
              })}
              formError={errors}
            />
          </FormContainerStyled>
        )}
        <ButtonContainerStyled>
          <Button sx={{ textTransform: 'capitalize' }} onClick={onCancel}>
            {cancelButtonText}
          </Button>
          <Button
            id={confirmButtonID}
            sx={{ textTransform: 'capitalize' }}
            type="submit"
            variant="contained"
            disabled={isDisableContinue()}
          >
            {t('setting.paymentDetails.editInvoiceModal.continue')}
          </Button>
        </ButtonContainerStyled>
      </form>
    </Box>
  );
};

export function EditInvoiceModal({ isOpen, setIsOpen }: EditInvoiceModalProps) {
  const [t] = useTranslation();
  const { data: projectID } = useGetProjectID();
  const { mutate: updateBillingData } = usePatchBillingPayment();
  const { data: invoiceData } = useGetBillingPayment(2, projectID);

  const [entityType, setEntityType] = useState<number>(
    invoiceData?.invoiceDetail?.type ?? 1,
  ); // 1: personal, 2: company

  const getCarrierCode = useCallback(
    (type: number | undefined, loveCode: string | undefined) => {
      if (type === 1 || type === 2) {
        return invoiceData?.invoiceDetail?.carrierNumber;
      } else if (loveCode) {
        return loveCode;
      } else {
        return '';
      }
    },
    [invoiceData?.invoiceDetail?.carrierNumber],
  );

  // 目前後端 API 只有 type 1 - 手機條碼 / 2 - 自然人憑證 / 3 - 商家電子發票，但為了前端方便 mapping，加上 4 - 捐贈碼
  const getCarrierType = (
    type: number | undefined,
    loveCode: string | undefined,
  ) => {
    if (type === 1 || type === 2 || type === 3) {
      return type;
    } else if (loveCode) {
      return 4;
    } else {
      return undefined;
    }
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm<EditInvoiceFormType>({
    mode: 'onBlur',
    defaultValues:
      invoiceData?.invoiceDetail?.type === 1
        ? {
            name: invoiceData?.invoiceDetail?.name ?? '',
            country: invoiceData?.invoiceDetail?.country ?? '',
            address: invoiceData?.invoiceDetail?.address ?? '',
            zip: invoiceData?.invoiceDetail?.zip ?? '',
            carrierType: getCarrierType(
              invoiceData?.invoiceDetail?.carrierType,
              invoiceData?.invoiceDetail?.loveCode,
            ),
            code: getCarrierCode(
              invoiceData?.invoiceDetail?.carrierType,
              invoiceData?.invoiceDetail?.loveCode,
            ),
            companyName: '',
            companyCountry: '',
            companyAddress: '',
            companyZip: '',
            tax: '',
          }
        : {
            name: '',
            country: '',
            address: '',
            zip: '',
            carrierType: undefined,
            code: '',
            companyName: invoiceData?.invoiceDetail?.name ?? '',
            companyCountry: invoiceData?.invoiceDetail?.country ?? '',
            companyAddress: invoiceData?.invoiceDetail?.address ?? '',
            companyZip: invoiceData?.invoiceDetail?.zip ?? '',
            tax: invoiceData?.invoiceDetail?.tax ?? '',
          },
  });

  const data = watch();

  const onSubmit = (data: EditInvoiceFormType) => {
    updateBillingData(
      entityType === 1
        ? {
            invoiceDetail: {
              type: 1,
              name: data.name,
              country: data.country,
              address: data.address,
              zip: data.zip,
              ...(data.carrierType !== 4 && {
                carrierType: data.carrierType,
                carrierNumber: data.code,
              }),
              ...(data.carrierType === 4 && { loveCode: data.code }),
            },
          }
        : {
            invoiceDetail: {
              type: 2,
              name: data.companyName,
              country: data.companyCountry,
              address: data.companyAddress,
              zip: data.companyZip,
              tax: data.tax,
            },
          },
      {
        onSuccess: (data, variables) => {
          if (data.code === 20000) {
            setIsOpen(false);
            Alert.success(t('setting.paymentDetails.editInvoiceModal.success'));
          }
        },
      },
    );
  };

  const onSubmitForm = handleSubmit(onSubmit);

  // 把 API 資料預設進 form
  useEffect(() => {
    if (invoiceData) {
      if (invoiceData.invoiceDetail.type === 1) {
        const invoiceDetail = {
          name: invoiceData.invoiceDetail.name,
          country: invoiceData.invoiceDetail.country,
          address: invoiceData.invoiceDetail.address,
          zip: invoiceData.invoiceDetail.zip,
          carrierType: getCarrierType(
            invoiceData?.invoiceDetail?.carrierType,
            invoiceData?.invoiceDetail?.loveCode,
          ),
          code: getCarrierCode(
            invoiceData?.invoiceDetail?.carrierType,
            invoiceData?.invoiceDetail?.loveCode,
          ),
        };
        reset(invoiceDetail);
      } else if (invoiceData.invoiceDetail.type === 2) {
        const invoiceDetail = {
          companyName: invoiceData.invoiceDetail.name,
          companyCountry: invoiceData.invoiceDetail.country,
          companyAddress: invoiceData.invoiceDetail.address,
          companyZip: invoiceData.invoiceDetail.zip,
          tax: invoiceData.invoiceDetail.tax,
        };
        reset(invoiceDetail);
      }

      setEntityType(invoiceData.invoiceDetail.type);
    }
  }, [getCarrierCode, invoiceData, reset]);

  return (
    <Dialog open={isOpen} maxWidth="s" fullWidth>
      <ModalContentContainerStyled>
        <DialogTitle>
          {t('setting.paymentDetails.editInvoiceModal.title')}
        </DialogTitle>
        <DialogContent>
          <InvoiceContent
            entityType={entityType}
            setEntityType={setEntityType}
            register={register}
            errors={errors}
            onCancel={() => setIsOpen(false)}
            onSubmitForm={onSubmitForm}
            cancelButtonText={t(
              'setting.paymentDetails.editInvoiceModal.cancel',
            )}
            formData={data}
            control={control}
            setValue={setValue}
          />
        </DialogContent>
      </ModalContentContainerStyled>
    </Dialog>
  );
}

export default EditInvoiceModal;

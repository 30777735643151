import { OptionTypeV2, Radio, Typography } from '@frontend/components/ui';
import { useProjectPermission } from '@frontend/editor/data-access';
import { DefaultAnswerForm } from '@frontend/sorghum/interface';
import { Box, FormControl, FormControlLabel, RadioGroup } from '@mui/material';
import { styled } from '@mui/system';
import { FC } from 'react';
import { Trans } from 'react-i18next';
import { FlowSelect } from '../flow-select/flow-select';

const ContentWrapper = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
}));

interface DefaultAnswerFacebookProps {
  data: DefaultAnswerForm;
  onlineFlow?: string;
  setData: (val: DefaultAnswerForm) => void;
  onUpdateFlowID: (data: { flowId: string; name: string }) => void;
}

export const DefaultAnswerFacebook: FC<DefaultAnswerFacebookProps> = ({
  data,
  onlineFlow,
  setData,
  onUpdateFlowID,
}: DefaultAnswerFacebookProps) => {
  const { isViewer } = useProjectPermission();

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      frequency: (event.target as HTMLInputElement).value,
    } as DefaultAnswerForm);
  };

  const handleFlowChange = (flowData: OptionTypeV2 | null) => {
    setData({
      ...data,
      flowID: flowData?.value as string,
    } as DefaultAnswerForm);
  };

  return (
    <ContentWrapper>
      <Box>
        <Typography variant="body2" color="grey.600">
          <Trans i18nKey="basicSetting.defaultAnswer.content" />
        </Typography>
      </Box>
      <Box mt={2} mb={2} display="inline-flex">
        <Box display="flex" alignItems="center" mr={2}>
          <Typography variant="subtitle2">
            <Trans i18nKey="basicSetting.defaultAnswer.responseTitle" />
          </Typography>
        </Box>
        <FormControl>
          <RadioGroup row onChange={handleRadioChange}>
            <FormControlLabel
              value="1"
              disabled={!data.enable}
              control={<Radio size="small" />}
              checked={data.frequency === '1'}
              label={
                <Typography variant="body2">
                  <Trans i18nKey="basicSetting.defaultAnswer.always" />
                </Typography>
              }
            />
            <FormControlLabel
              value="2"
              disabled={!data.enable}
              control={<Radio size="small" />}
              checked={data.frequency === '2'}
              label={
                <Typography variant="body2">
                  <Trans i18nKey="basicSetting.defaultAnswer.once" />
                </Typography>
              }
            />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box>
        <FlowSelect
          onlineFlow={onlineFlow}
          disabled={!data.enable}
          value={data?.flowID ? data.flowID : null}
          onChange={handleFlowChange}
          socialType={1}
          onUpdateFlowID={onUpdateFlowID}
          isAllowAddNewFlow={!isViewer}
        />
      </Box>
    </ContentWrapper>
  );
};

export default DefaultAnswerFacebook;

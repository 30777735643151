// store checkbox status when close viewer-modal
export const setProjectStatus = ({
  projectID,
  checked,
}: {
  projectID: string;
  checked: boolean;
}): void => {
  localStorage.setItem(
    `${projectID}_viewer_modal_isChecked`,
    checked.toString(),
  );
};

// decide display viewer-modal or not when in viewer-mode
export const getProjectStatus = ({
  projectID,
}: {
  projectID: string;
}): boolean => {
  const storedValue = localStorage.getItem(
    `${projectID}_viewer_modal_isChecked`,
  );
  return storedValue ? JSON.parse(storedValue) : false;
};

import { globalTheme } from '@frontend/components/external-providers';
import { UploadImage } from '@frontend/components/ui';
import { Alert } from '@frontend/components/utils';
import {
  useCanvasAdd,
  useCanvasCollect,
  useCanvasGet,
  useCanvasRemove,
  useCanvasUpdate,
  useGetProjectID,
  useModal,
  usePostEditorFlowUpload,
} from '@frontend/editor/data-access';
import { EditorCtx, UICtx } from '@frontend/editor/external-providers';
import { ImageType, PublishErrorsEnum } from '@frontend/editor/interface';
import { IMAGE_WIDTH } from '@frontend/editor/utils';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { memo, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useViewport } from 'reactflow';
import EditorFloatPanel from '../editor-float-panel/editor-float-panel';
import SortableTextButtonGroup from '../editor-sortable-text-button-group/editor-sortable-text-button-group';

interface EditorImageProps {
  id: string;
  draggable: boolean;
  parentID: string;
  index: number;
  setIsHoverMenu: (isHover: boolean) => void;
}

const ContainerStyled = styled(Box)<{ $isHover: boolean; $zoom: number }>(
  ({ theme, $isHover, $zoom }) => ({
    background: theme.palette['grey'][100],
    borderRadius: '8px',
    border: `2px solid ${
      $isHover ? theme.palette['info']['light'] : 'transparent'
    }`,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1.5),
    position: 'relative',
    fontSize: `${28 / $zoom}px`,
  }),
);

export const EditorImage = ({
  id, // EditorImage 的 ID
  index,
  parentID, // Block 的 ID
  draggable,
  setIsHoverMenu,
}: EditorImageProps) => {
  const state = useContext(EditorCtx);
  const uiState = useContext(UICtx);
  const { zoom } = useViewport();

  const { getTargetElement } = useCanvasGet();

  // editor image 的資料
  const element = useMemo(
    () => getTargetElement(id) as ImageType,
    [getTargetElement, id],
  );

  const [t] = useTranslation();
  const [isHover, setIsHover] = useState<boolean>(false);
  const [isHoverImage, setIsHoverImage] = useState(false);
  const { openDeleteElementModal } = useModal();
  const { canvasRemoveElement } = useCanvasRemove();
  const { addCellButton } = useCanvasAdd();
  const { canvasSortElement } = useCanvasUpdate();
  const { getErrorStatus, getErrorStatusAfterPublished } = useCanvasCollect();
  const { data: projectID } = useGetProjectID();
  const { mutate: uploadImage } = usePostEditorFlowUpload(projectID as string);

  const onRemoveButtonClick = useCallback(() => {
    // 如果有設定任何資料則跳出提示
    const needConfirm = !!element.fileID || !!element.title;
    if (needConfirm) {
      openDeleteElementModal(id);
    } else {
      canvasRemoveElement(id);
    }
  }, [
    canvasRemoveElement,
    element.fileID,
    element.title,
    id,
    openDeleteElementModal,
  ]);

  const handleSortButton = useCallback(
    (oldIndex: number, newIndex: number) => {
      canvasSortElement(id, oldIndex, newIndex);
    },
    [canvasSortElement, id],
  );

  const handleMouseEnter = useCallback(() => {
    uiState.onHoverElement(id, 2);
    setIsHover(true);
  }, [id, uiState]);

  const handleMouseLeave = useCallback(() => {
    setIsHover(false);
  }, []);

  const handleMouseEnterMenu = useCallback(() => {
    setIsHoverMenu(true);
  }, [setIsHoverMenu]);

  const handleMouseLeaveMenu = useCallback(() => {
    setIsHoverMenu(false);
  }, [setIsHoverMenu]);

  const handleImageChange = useCallback(
    (val: Blob) => {
      uploadImage(
        { file: val, fileType: '6' },
        {
          onSuccess: (data) => {
            if (data.code === 20000) {
              state.updateElementData(id, 'fileID', data.data.fileId);
              state.updateElementData(id, 'fileUrl', data.data.fileUrl);
            } else {
              Alert.error(t('alert.imageSize'));
            }
          },
        },
      );
    },
    [id, state, t, uploadImage],
  );

  return (
    <ContainerStyled
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      $isHover={isHover}
      $zoom={zoom}
    >
      {/* Element 左側的刪除按鈕*/}
      {isHover && !isHoverImage && uiState.hoveredElement.layer < 3 && (
        <EditorFloatPanel
          onMouseEnter={handleMouseEnterMenu}
          onMouseLeave={handleMouseLeaveMenu}
          allowAdd
          parentID={parentID}
          index={index}
          draggable={draggable}
          direction="column"
          handleDelete={onRemoveButtonClick}
          backgroundColor={
            globalTheme.palette?.grey ? globalTheme.palette.grey[100] : ''
          }
        />
      )}
      <UploadImage
        defaultValue={element.fileUrl}
        title={t('canvas.editorImage.addImage')}
        info={t('canvas.editorImage.imageFormat')}
        width={IMAGE_WIDTH}
        onMouseEnter={() => setIsHoverImage(true)}
        onMouseLeave={() => setIsHoverImage(false)}
        onChange={handleImageChange}
        error={!!getErrorStatus(id, PublishErrorsEnum.IMAGE_FILE_EMPTY)}
        publishError={
          !!getErrorStatusAfterPublished(id, PublishErrorsEnum.IMAGE_FILE_EMPTY)
        }
      />
      <Box
        sx={{
          marginTop: '12px',
        }}
      >
        <SortableTextButtonGroup
          parentID={id}
          onSortEnd={handleSortButton}
          onAddClick={() => addCellButton(id)}
        />
      </Box>
    </ContainerStyled>
  );
};

export default memo(EditorImage);

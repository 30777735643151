import {
  Box,
  FormControl,
  FormControlLabel,
  RadioGroup,
  TextField,
} from '@mui/material';

import {
  OptionTypeV2,
  Select,
  SelectItem,
  Typography,
} from '@frontend/components/ui';
import { useProjectPermission } from '@frontend/editor/data-access';
import {
  EditorMenuItemType,
  FlowType,
  GetEditorMenuItemType,
  PersistentMenuPublishErrorsEnum,
} from '@frontend/sorghum/interface';
import Radio from '@mui/material/Radio';
import { styled } from '@mui/system';
import { isEmpty } from 'lodash';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlowSelect } from '../flow-select/flow-select';

interface PersistentMenuItemContentProps {
  isEditable: boolean;
  menuItemData: GetEditorMenuItemType;
  publishErrors: any[];
  setFieldValue: (
    id: string | undefined,
    fieldName: string,
    value: string | null | number,
  ) => void;
  setItemData: (itemID: string, itemData: EditorMenuItemType) => void;
  onUpdateFlowID: (flowID: string, itemID: string | undefined) => void;
}
interface OptionType {
  label: string;
  value: number;
}

const urlOptionItems: OptionType[] = [
  {
    label: 'native',
    value: 1,
  },
  {
    label: '100%',
    value: 2,
  },
  {
    label: '75%',
    value: 3,
  },
  {
    label: '50%',
    value: 4,
  },
];

const RadioGroupStyled = styled(Box)(({ theme }) => ({
  marginBottom: '18px',
  '& > .MuiFormControl-root > .MuiFormGroup-root': {
    display: 'flex',
    flexDirection: 'row',
  },
}));

const SelectUrlStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  '.MuiOutlinedInput-root > .MuiSelect-select': {
    boxSizing: 'border-box',
  },
}));

const TextFieldStyled = styled(TextField)(({ theme }) => ({
  marginBottom: '15px',
}));

const PersistentMenuItemContent: FC<PersistentMenuItemContentProps> = ({
  isEditable,
  menuItemData,
  publishErrors,
  setFieldValue,
  setItemData,
  onUpdateFlowID,
}: PersistentMenuItemContentProps) => {
  const [t] = useTranslation();
  const [urlError, setUrlError] = useState<string>('');
  const { isViewer } = useProjectPermission();

  const checkUrlFormat = (value: string | undefined) => {
    if (!value) {
      setUrlError('');
      return;
    }
    const urlRegex = /https?:\/\//g;
    if (value.match(urlRegex) === null) {
      setUrlError(t('basicSetting.persistentMenu.urlError'));
    } else {
      setUrlError('');
    }
  };

  const isShowUrlFieldError = () => {
    if (
      publishErrors.find(
        (item) =>
          item.id === menuItemData.id &&
          (item.type === PersistentMenuPublishErrorsEnum.NO_URL ||
            item.type === PersistentMenuPublishErrorsEnum.INCORRECT_URL_FORMAT),
      ) ||
      isEmpty(menuItemData.url)
    ) {
      return true;
    }

    if (publishErrors.length === 0) return false;

    return false;
  };

  const handleFlowChange = (flowData: OptionTypeV2 | null) => {
    setItemData(menuItemData.id as string, {
      ...menuItemData,
      flow: { ...menuItemData.flow, id: flowData?.value as string } as FlowType,
    });
  };

  const handleUpdateFlowID = (data: { flowId: string; name: string }) => {
    if (data) {
      onUpdateFlowID(data.flowId, menuItemData.id);
    }
  };

  return (
    <Box sx={{ width: '100%', pl: 2, pr: 2, pb: 2 }}>
      <RadioGroupStyled>
        <FormControl>
          <RadioGroup>
            <FormControlLabel
              name="type"
              control={<Radio size="small" />}
              label={
                <Typography variant="body2">
                  {t('basicSetting.persistentMenu.item.selectFlow')}
                </Typography>
              }
              value={1}
              checked={menuItemData.type.toString() === '1'}
              onChange={() => setFieldValue(menuItemData.id, 'type', 1)}
              disabled={!isEditable}
            />
            <FormControlLabel
              name="type"
              control={<Radio size="small" />}
              label={
                <Typography variant="body2">
                  {t('basicSetting.persistentMenu.item.addUrl')}
                </Typography>
              }
              value={2}
              checked={menuItemData.type.toString() === '2'}
              onChange={() => setFieldValue(menuItemData.id, 'type', 2)}
              disabled={!isEditable}
            />
          </RadioGroup>
        </FormControl>
      </RadioGroupStyled>

      {menuItemData.type.toString() === '1' && (
        <FlowSelect
          onlineFlow={menuItemData.onlineFlow?.id}
          disabled={!isEditable}
          value={menuItemData.flow ? menuItemData.flow.id : null}
          onChange={handleFlowChange}
          socialType={1}
          onUpdateFlowID={handleUpdateFlowID}
          linkIconDisplay={false}
          isAllowAddNewFlow={!isViewer}
        />
      )}

      {menuItemData.type.toString() === '2' && (
        <SelectUrlStyled>
          <TextFieldStyled
            variant="outlined"
            label={t('basicSetting.persistentMenu.item.url.label')}
            value={menuItemData.url ?? ''}
            onBlur={() => checkUrlFormat(menuItemData.url)}
            onChange={(e) =>
              setFieldValue(menuItemData.id, 'url', e.target.value)
            }
            error={!!urlError || isShowUrlFieldError()}
            helperText={urlError}
            disabled={!isEditable}
            size="small"
          />
          <Select
            size="small"
            name="size"
            value={
              menuItemData.size ? menuItemData.size : urlOptionItems[0].value
            }
            onChange={(e) =>
              setFieldValue(menuItemData.id, 'size', e.target.value)
            }
            disabled={!isEditable}
          >
            {urlOptionItems.map((item, index) => (
              <SelectItem key={index} value={item.value}>
                {item.label}
              </SelectItem>
            ))}
          </Select>
        </SelectUrlStyled>
      )}
    </Box>
  );
};

export default PersistentMenuItemContent;

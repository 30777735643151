import { queryClient } from '@frontend/sorghum/external-providers';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { BROADCAST } from '../apis';

import {
  PatchBroadcastDataType,
  Response,
  UseApi,
} from '@frontend/sorghum/interface';

interface PropType {
  projectID: string;
  data: PatchBroadcastDataType;
}

async function patchBroadcast(props: PropType): Promise<Response> {
  return axios
    .patch(`${BROADCAST}?projectId=${props.projectID}`, { ...props.data })
    .then((res) => res.data)
    .catch((err) => {
      return {};
    });
}

export function usePatchBroadcast(options?: UseApi<Response, PropType>) {
  return useMutation({
    mutationKey: ['patch-broadcast'],
    mutationFn: (props: PropType) => patchBroadcast(props),
    onSuccess: (data, variables: PropType) => {
      if (options?.onSuccess) {
        options.onSuccess(data, variables);
      }

      queryClient.refetchQueries([
        'get-broadcast-data',
        { projectID: variables.projectID, broadcastID: variables.data.id },
      ]);
      queryClient.invalidateQueries([
        'get-broadcast-list',
        { projectID: variables.projectID },
      ]);
    },
  });
}

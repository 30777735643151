import { EditorCtx } from '@frontend/editor/external-providers';
import {
  ActionAttributeType,
  ActionCellBaseType,
  ActionCellTypesEnum,
  ActionSequenceType,
  ActionSheetType,
  BaseElement,
  BlockTypesEnum,
  CategoryValueTypesEnum,
  CollectUserAnswerType,
  ConditionCellType,
  ConditionType,
  CouponCellType,
  CouponCellTypesEnum,
  CouponType,
  CouponTypesEnum,
  ElementTypesEnum,
  GalleryImageType,
  GalleryType,
  ImageType,
  JumpToFlowType,
  OptionCellType,
  PublishBlock,
  PublishCell,
  PublishElement,
  PublishElementTypesEnum,
  PublishGalleries,
  RecurringNotificationType,
  ShareLinkType,
  TextButtonCellTypesEnum,
} from '@frontend/editor/interface';
import { isValueInEnum } from '@frontend/editor/utils';
import { get, set } from 'lodash';
import { useCallback, useContext } from 'react';
import useGetCategoryValue from '../use-get-category-value/use-get-category-value';
import useGetProjectID from '../use-get-project-id/use-get-project-id';

export interface useCanvasPublish {
  getPublishData: () => {
    chart: { data: BaseElement[] };
    blocks: PublishBlock[];
  };
}

export function useCanvasPublish(): useCanvasPublish {
  const state = useContext(EditorCtx);
  const { data: projectID } = useGetProjectID();
  useGetCategoryValue({
    projectID: projectID as string,
    type: CategoryValueTypesEnum.RN_TOPIC,
  });

  const cellToPublish = useCallback(
    (id: string): PublishCell => {
      const target = state.getElement(id);
      const elementType = get(target, 'elementType');
      const targetID = get(target, 'id', '');
      const nextBlockID = get(target, 'targetID', '') as string;
      const buttonType = get(
        target,
        'buttonType',
        TextButtonCellTypesEnum.BLOCK,
      ) as TextButtonCellTypesEnum;

      switch (elementType) {
        case ElementTypesEnum.CONDITION_CELL: {
          const conditionCell = target as ConditionCellType;
          let symbolID = '';
          let _value = '';

          if (
            conditionCell.categoryType === CategoryValueTypesEnum.RN_TOPIC ||
            conditionCell.categoryType === CategoryValueTypesEnum.TAG
          ) {
            symbolID = conditionCell.categoryValue as string;
          } else if (
            conditionCell.categoryType === CategoryValueTypesEnum.ATTRIBUTE
          ) {
            symbolID = conditionCell.attributeID as string;
          }

          if (
            conditionCell.categoryType === CategoryValueTypesEnum.ATTRIBUTE ||
            conditionCell.categoryType === CategoryValueTypesEnum.RN_TITLE
          ) {
            _value = conditionCell.categoryValueName as string;
          }

          return {
            cellId: conditionCell.id,
            type: PublishElementTypesEnum.CONDITION,
            data: {
              type: conditionCell.categoryType,
              rule: conditionCell.rule,
              ...(symbolID && {
                symbolId: symbolID,
              }),
              ...(_value && {
                value: _value,
              }),
            },
            ...(conditionCell.targetID && {
              nextBlockId: conditionCell.targetID,
            }),
          };
        }
        case ElementTypesEnum.OPTION_CELL: {
          const cell = target as OptionCellType;
          return {
            cellId: cell.id,
            type: PublishElementTypesEnum.OPTION_CELL,
            nextBlockId: nextBlockID,
            data: {
              title: cell.data?.title,
            },
          };
        }
        case ElementTypesEnum.COUPON_CELL: {
          const cell = target as CouponCellType;
          let couponCellType = 1;
          switch (cell.cellType) {
            case CouponCellTypesEnum.SUCCESS: {
              couponCellType = 1;
              break;
            }
            case CouponCellTypesEnum.FAIL: {
              couponCellType = 2;
              break;
            }
            case CouponCellTypesEnum.EXHAUSTED: {
              couponCellType = 3;
              break;
            }
            case CouponCellTypesEnum.LIMIT: {
              couponCellType = 4;
              break;
            }
          }

          return {
            cellId: cell.id,
            type: PublishElementTypesEnum.COUPON_CELL,
            data: {
              type: couponCellType,
            },
            ...(cell.targetID && {
              nextBlockId: cell.targetID,
            }),
          };
        }
        case ElementTypesEnum.TEXT_BUTTON_CELL:
        default: {
          const result: PublishCell = {
            cellId: targetID,
            type: PublishElementTypesEnum.BUTTON,
            data: { title: get(target, 'label', '') },
          };

          switch (buttonType) {
            case TextButtonCellTypesEnum.PHONE: {
              set(result, 'type', PublishElementTypesEnum.CALL_BUTTON);
              set(result, 'data.tel', get(target, 'data.tel'));
              break;
            }
            case TextButtonCellTypesEnum.URL: {
              set(result, 'type', PublishElementTypesEnum.URL_BUTTON);
              set(result, 'data.url', get(target, 'data.url'));
              set(result, 'data.size', 1);
              break;
            }
            case TextButtonCellTypesEnum.FLOW: {
              set(result, 'type', PublishElementTypesEnum.REDIRECT_BUTTON);
              set(result, 'data.title', get(target, 'label'));
              set(result, 'data.flowId', get(target, 'data.flowID'));
            }
          }
          if (nextBlockID && buttonType !== TextButtonCellTypesEnum.FLOW) {
            set(result, 'nextBlockId', nextBlockID);
          }

          return result;
        }
      }
    },
    [state],
  );

  const elementToPublish = useCallback(
    (id: string): PublishElement | undefined => {
      const target = state.getElement(id);
      if (target) {
        switch (target.elementType) {
          case ElementTypesEnum.TEXT_BUTTON: {
            return {
              elementId: target.id,
              type: PublishElementTypesEnum.TEXT_BUTTON,
              data: {
                text: target.label as string,
              },
              cells: target.children.map((child) => cellToPublish(child)),
            } as PublishElement;
          }
          case ElementTypesEnum.RECURRING_NOTIFICATION: {
            const rn = target as RecurringNotificationType;
            return {
              elementId: rn.id,
              type: PublishElementTypesEnum.RECURRING_NOTIFICATION,
              data: {
                topicId: rn.topic,
                fileId: rn.fileID,
                frequency: 1,
                ratio: rn.imageType,
                title: rn.title,
                repotin: rn.reoptin,
              },
              cells: [
                {
                  cellId: rn.cellID,
                  type: PublishElementTypesEnum.BUTTON,
                  data: {
                    title: 'Get message',
                  },
                  nextBlockId: rn.targetID,
                },
              ],
            } as PublishElement;
          }
          case ElementTypesEnum.IMAGE: {
            return {
              elementId: target.id,
              type: PublishElementTypesEnum.IMAGE,
              data: {
                fileId: (target as ImageType).fileID,
              },
              cells: target.children.map((child) => cellToPublish(child)),
            };
          }
          case ElementTypesEnum.GALLERY: {
            const galleries: PublishGalleries[] = [];
            const cells: PublishCell[] = [];
            target.children.forEach((childID) => {
              const galleryImage = state.getElement(
                childID,
              ) as GalleryImageType;
              if (galleryImage) {
                galleries.push({
                  title: galleryImage.title,
                  fileId: galleryImage.fileID,
                  subTitle: galleryImage.subtitle,
                  cellIds: galleryImage.children,
                });
                const textButtonList = galleryImage.children.map((cellID) =>
                  cellToPublish(cellID),
                );
                cells.push(...textButtonList);
              }
            });

            return {
              elementId: target.id,
              type: PublishElementTypesEnum.GALLERY,
              data: {
                ratio: (target as GalleryType).ratio,
                galleries,
              },
              cells,
            };
          }
          case ElementTypesEnum.ACTION_ELEMENT: {
            const actionElement = target as ActionCellBaseType;
            switch (actionElement.actionType) {
              case ActionCellTypesEnum.SET:
              case ActionCellTypesEnum.REMOVE: {
                const cellData = actionElement as ActionAttributeType;
                return {
                  elementId: cellData.id,
                  type:
                    cellData.actionType === ActionCellTypesEnum.SET
                      ? PublishElementTypesEnum.ACTION_SET_ATTRIBUTE
                      : PublishElementTypesEnum.ACTION_REMOVE_ATTRIBUTE,
                  data: {
                    attributeId: cellData.attributeID,
                    value: cellData.valueName,
                  },
                };
              }
              case ActionCellTypesEnum.SUBSCRIBE_SEQUENCE:
              case ActionCellTypesEnum.UNSUBSCRIBE_SEQUENCE: {
                const cellData = actionElement as ActionSequenceType;
                return {
                  elementId: cellData.id,
                  type:
                    cellData.actionType ===
                    ActionCellTypesEnum.SUBSCRIBE_SEQUENCE
                      ? PublishElementTypesEnum.ACTION_SUBSCRIBE_SEQUENCE
                      : PublishElementTypesEnum.ACTION_UNSUBSCRIBE_SEQUENCE,
                  data: {
                    sequenceId: cellData.sequence.id,
                  },
                };
              }
              case ActionCellTypesEnum.EXPORT_GOOGLE_SHEET: {
                const cellData = actionElement as ActionSheetType;
                return {
                  elementId: cellData.id,
                  type: PublishElementTypesEnum.EXPORT_GOOGLE_SHEET,
                  data: {
                    googleId: cellData.data.accountID,
                    sheetId: cellData.data.sheetID,
                    property: cellData.data.properties,
                  },
                };
              }
            }
            break;
          }
          case ElementTypesEnum.COLLECT_USER_ANSWER: {
            const element = target as CollectUserAnswerType;

            const isAllowUserInput = element.data?.isAllowFreeInput;

            let cellList = [];

            if (isAllowUserInput) {
              cellList = [
                ...target.children.map((child) => cellToPublish(child)),
                {
                  cellId: element.data?.freeInputID,
                  type: PublishElementTypesEnum.CUSTOM_INPUT_CELL,
                  data: {},
                },
              ];
            } else {
              cellList = target.children.map((child) => cellToPublish(child));
            }

            return {
              elementId: element.id,
              type: PublishElementTypesEnum.COLLECT_USER_ANSWER,
              data: {
                text: element.data?.text,
                attributeId: element.data?.attributeId,
                periodType: element.data?.periodType,
                periodValue: element.data?.periodValue,
              },
              cells: cellList,
            };
          }
        }
      }
      return undefined;
    },
    [cellToPublish, state],
  );

  const getPublishData = useCallback(() => {
    const entryPoint = state.elements.find(
      (i) => i.elementType === ElementTypesEnum.ENTRY_POINT,
    );
    const result = state.elements
      .filter((i) => isValueInEnum(i.elementType, BlockTypesEnum))
      .map((element: BaseElement, index: number) => {
        let result: PublishBlock;
        switch (element.elementType) {
          case ElementTypesEnum.ACTION:
          case ElementTypesEnum.BLOCK: {
            result = {
              blockId: element.id,
              ...(element.targetID && { nextBlockId: element.targetID }),
              data: {
                title: element.label,
              },
              elements: element.children.map((child) =>
                elementToPublish(child),
              ),
            } as PublishBlock;
            break;
          }
          case ElementTypesEnum.COUPON: {
            const el = element as CouponType;
            let couponType;
            switch (el.type) {
              case CouponTypesEnum.SEND:
                couponType = PublishElementTypesEnum.COUPON_SEND;
                break;
              case CouponTypesEnum.SHOW:
                couponType = PublishElementTypesEnum.COUPON_SHOW;
                break;
              case CouponTypesEnum.REDEEM:
                couponType = PublishElementTypesEnum.COUPON_REDEEM;
                break;
            }

            result = {
              blockId: element.id,
              data: {
                title: element.label,
              },
              elements: [
                {
                  elementId: (element as CouponType).elementID,
                  type: couponType,
                  data: {
                    couponId: el.couponValue,
                  },
                  cells: element.children.map((child) => cellToPublish(child)),
                },
              ],
            } as PublishBlock;
            break;
          }
          case ElementTypesEnum.CONDITION: {
            const el = element as ConditionType;
            result = {
              blockId: el.id,
              data: {
                title: el.label,
              },
              elements: [
                {
                  data: {},
                  elementId: el.elementID,
                  type: PublishElementTypesEnum.CONDITION,
                  cells: el.children.map((child) => cellToPublish(child)),
                },
              ],
            } as PublishBlock;
            break;
          }
          case ElementTypesEnum.JUMP_TO_FLOW: {
            const el = element as JumpToFlowType;
            result = {
              blockId: el.id,
              data: {
                title: el.label,
              },
              elements: [
                {
                  data: { flowId: el.data.flowID },
                  elementId: el.data.elementID,
                  type: PublishElementTypesEnum.JUMP_TO_FLOW,
                },
              ],
            } as PublishBlock;
            break;
          }
          case ElementTypesEnum.SHARE_LINK:
          default: {
            return {
              blockId: element.id,
              data: {
                title: element.label,
              },
              elements: [
                {
                  elementId: (element as ShareLinkType).shareLinkID,
                  type: PublishElementTypesEnum.SHARE_LINK,
                  data: {
                    title: element.data.title,
                    fileId: element.data.fileID,
                  },
                  cells: [
                    {
                      cellId: (element as ShareLinkType).cellID,
                      type: PublishElementTypesEnum.BUTTON,
                      data: {
                        title: element.data.buttonText,
                      },
                    },
                  ],
                } as PublishElement,
              ],
            } as PublishBlock;
          }
        }

        // entry-point 連接的目標設定為 starter
        if (entryPoint && entryPoint.targetID === element.id) {
          set(result, 'starter', true);
        }

        return result;
      });
    return {
      chart: { data: state.elements },
      blocks: result,
    };
  }, [cellToPublish, elementToPublish, state.elements]);

  return {
    getPublishData,
  };
}

export default useCanvasPublish;

import {
  useGetAccountInfo,
  useGetProject,
} from '@frontend/sorghum/data-access';
import { AccountPermission, ProjectStatus } from '@frontend/sorghum/interface';
import { useGetProjectID } from '../use-get-project-id/use-get-project-id';

export function useProjectPermission() {
  const { data: projectID } = useGetProjectID();
  const { data: project } = useGetProject(projectID);
  const { data: account } = useGetAccountInfo();

  return {
    // 方案過期或是權限為 Viewer 都會進入檢視者模式
    isViewer:
      account?.permission === AccountPermission.VIEWER ||
      project?.status === ProjectStatus.VIEW_ONLY,
    // project 的身份是否為 viewer（admin/viewer）
    isAccountPermissionViewer: account?.permission === AccountPermission.VIEWER,
  };
}

export default useProjectPermission;

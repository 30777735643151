import { FC, PropsWithChildren } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { BrowserRouter } from 'react-router-dom';
import ResizeObserver from 'resize-observer-polyfill';
import { DndProvider } from './DndProvider';
import { ThemeProvider } from './ThemeProvider';

// 將 polyfill 設置為全域的 ResizeObserver
if (!window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver;
}

export const IntegrationProvider: FC<PropsWithChildren> = ({ children }) => {
  const methods = useForm();

  return (
    <ThemeProvider>
      <DndProvider>
        <BrowserRouter>
          <FormProvider {...methods}>{children}</FormProvider>
        </BrowserRouter>
      </DndProvider>
    </ThemeProvider>
  );
};

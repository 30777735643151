import { Button, Typography } from '@frontend/components/ui';
import {
  useCanvasAdd,
  useGetProjectID,
  useProjectPermission,
} from '@frontend/editor/data-access';
import {
  useGetAccountInfo,
  usePatchAccountInfo,
  usePostEditorFlow,
} from '@frontend/sorghum/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import {
  FlowTemplateEnum,
  PostEditorFlowResponse,
  ResponseWithData,
} from '@frontend/sorghum/interface';
import {
  ANIMATION_AUTO_REPLY_EN,
  ANIMATION_AUTO_REPLY_ZH,
  ANIMATION_DRAW_EN,
  ANIMATION_DRAW_ZH,
  ANIMATION_RN_EN,
  ANIMATION_RN_ZH,
  PAGE_FLOW,
  sendGAEvent,
  usePath,
} from '@frontend/sorghum/utils';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import i18next from 'i18next';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';

interface TutorialModalProps {
  googleStorageEndpoint: string;
}

const ContainerStyled = styled(Box)(({ theme }) => ({
  width: '100vw',
  height: '100vh',
  display: 'inline-flex',
  padding: '40px',
  gap: '40px',

  '& > div': {
    width: '100%',
    gap: '24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));

const RightSideWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '40px 0',
}));

const ContentWrapperStyled = styled(Box)(({ theme }) => ({
  height: '40%',
  overflow: 'auto',
}));

const ButtonWrapperStyled = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  gap: '24px',
  flexDirection: 'column',
  justifyContent: 'end',
}));

export const TutorialModal: FC<TutorialModalProps> = ({
  googleStorageEndpoint,
}) => {
  const [t] = useTranslation();
  const uiState = useContext(UICtx);
  const { navigateToProjectPage } = usePath();
  const { data: projectID } = useGetProjectID();
  const { data: accountInfo } = useGetAccountInfo();
  const { getInitialElements } = useCanvasAdd();
  const { mutate: patchAccount } = usePatchAccountInfo();
  const { mutate: createFlow } = usePostEditorFlow(projectID as string, 1);

  const { isViewer } = useProjectPermission();
  const newName = t('flows.leftSidebar.flowDefaultName');
  const chart = getInitialElements();

  const [animation_RN_EN, setAnimationRN_EN] = useState<JSON | null>();
  const [animation_RN_ZH, setAnimationRN_ZH] = useState<JSON | null>();
  const [animation_Draw_EN, setAnimationDraw_EN] = useState<JSON | null>();
  const [animation_Draw_ZH, setAnimationDraw_ZH] = useState<JSON | null>();
  const [animation_AutoReply_EN, setAnimationAutoReply_EN] =
    useState<JSON | null>();
  const [animation_AutoReply_ZH, setAnimationAutoReply_ZH] =
    useState<JSON | null>();

  const handleCreateFlowOnSuccess = useCallback(
    (data: ResponseWithData<PostEditorFlowResponse>) => {
      if (!accountInfo?.tutorial) {
        patchAccount(
          {
            tutorial: true,
          },
          {
            onSuccess: () => {
              uiState.closeModal();
              navigateToProjectPage(`${PAGE_FLOW}/${data.data.flowId}`);
            },
          },
        );
      } else {
        uiState.closeModal();
        navigateToProjectPage(`${PAGE_FLOW}/${data.data.flowId}`);
      }
    },
    [accountInfo?.tutorial, navigateToProjectPage, patchAccount, uiState],
  );

  const handleTemplateButtonClick = useCallback(() => {
    createFlow(
      {
        name: newName,
        chartTemplateID: uiState.tutorialModalType,
      },
      {
        onSuccess: handleCreateFlowOnSuccess,
      },
    );
    if (accountInfo?.tutorial) {
      sendGAEvent(
        'Template Page',
        'Use Template',
        'entrance_Automation-BN - Use Template BTN - click',
        '',
      );
    } else {
      // no skip
      sendGAEvent(
        'Template Page',
        'Use Template',
        'entrance_on-boarding - Use Template BTN - click',
        '',
      );

      sendGAEvent(
        'Template Page',
        'Use Template - user',
        'entrance_on-boarding users - Use Template BTN - click',
        '',
      );
    }
  }, [
    createFlow,
    newName,
    uiState.tutorialModalType,
    handleCreateFlowOnSuccess,
    accountInfo?.tutorial,
  ]);

  const handleCreateFlowButtonClick = useCallback(() => {
    createFlow(
      {
        name: newName,
        chart,
      },
      {
        onSuccess: handleCreateFlowOnSuccess,
      },
    );
    if (accountInfo?.tutorial) {
      sendGAEvent(
        'Template Page',
        'Start From Scratch',
        'entrance_Automation-BN - Scratch BTN - click',
        '',
      );
    } else {
      // no skip
      sendGAEvent(
        'Template Page',
        'Start From Scratch',
        'entrance_on-boarding - Scratch BTN - click',
        '',
      );
    }
  }, [
    accountInfo?.tutorial,
    chart,
    createFlow,
    handleCreateFlowOnSuccess,
    newName,
  ]);

  const handleClose = useCallback(() => {
    uiState.closeModal();
    sendGAEvent(
      'Template Page',
      'SKIP',
      'entrance_Automation-BN - Use Template BTN - click',
      '',
    );
  }, [uiState]);

  const defaultOptions = useMemo(() => {
    if (i18next.language.includes('en')) {
      switch (uiState.tutorialModalType) {
        case FlowTemplateEnum.RN: {
          return {
            loop: true,
            autoplay: true,
            animationData: animation_RN_EN,
          };
        }
        case FlowTemplateEnum.AUTO_REPLY: {
          return {
            loop: true,
            autoplay: true,
            animationData: animation_AutoReply_EN,
          };
        }
        default: {
          return {
            loop: true,
            autoplay: true,
            animationData: animation_Draw_EN,
          };
        }
      }
    } else {
      switch (uiState.tutorialModalType) {
        case FlowTemplateEnum.RN: {
          return {
            loop: true,
            autoplay: true,
            animationData: animation_RN_ZH,
          };
        }
        case FlowTemplateEnum.AUTO_REPLY: {
          return {
            loop: true,
            autoplay: true,
            animationData: animation_AutoReply_ZH,
          };
        }
        default: {
          return {
            loop: true,
            autoplay: true,
            animationData: animation_Draw_ZH,
          };
        }
      }
    }
  }, [
    uiState.tutorialModalType,
    animation_RN_EN,
    animation_RN_ZH,
    animation_Draw_EN,
    animation_Draw_ZH,
    animation_AutoReply_EN,
    animation_AutoReply_ZH,
  ]);

  const { title, content, info1, info2, info3, info4 } = useMemo(() => {
    switch (uiState.tutorialModalType) {
      case FlowTemplateEnum.RN: {
        return {
          title: t('tutorial.rn.title'),
          content: t('tutorial.rn.content'),
          info1: t('tutorial.rn.info1'),
          info2: t('tutorial.rn.info2'),
          info3: t('tutorial.rn.info3'),
        };
      }
      case FlowTemplateEnum.DRAW: {
        return {
          title: t('tutorial.draw.title'),
          content: t('tutorial.draw.content'),
          info1: t('tutorial.draw.info1'),
          info2: t('tutorial.draw.info2'),
          info3: t('tutorial.draw.info3'),
          info4: t('tutorial.draw.info4'),
        };
      }
      // AUTO REPLY
      default: {
        return {
          title: t('tutorial.autoReply.title'),
          content: t('tutorial.autoReply.content'),
          info1: t('tutorial.autoReply.info1'),
          info2: t('tutorial.autoReply.info2'),
          info3: t('tutorial.autoReply.info3'),
          info4: t('tutorial.autoReply.info4'),
        };
      }
    }
  }, [uiState.tutorialModalType, t]);

  // TODO: 現在每次開啟 modal 都會 fetch 一次，之後要想辦法只 fetch 一次
  useEffect(() => {
    if (i18next.language.includes('en')) {
      switch (uiState.tutorialModalType) {
        case FlowTemplateEnum.RN: {
          if (!animation_RN_EN) {
            fetch(`${googleStorageEndpoint}${ANIMATION_RN_EN}`).then((res) => {
              res.json().then((json) => {
                setAnimationRN_EN(json);
              });
            });
          }
          break;
        }
        case FlowTemplateEnum.DRAW: {
          if (!animation_Draw_EN) {
            fetch(`${googleStorageEndpoint}${ANIMATION_DRAW_EN}`).then(
              (res) => {
                res.json().then((json) => {
                  setAnimationDraw_EN(json);
                });
              },
            );
          }
          break;
        }
        // AUTO REPLY
        default: {
          if (!animation_AutoReply_EN) {
            fetch(`${googleStorageEndpoint}${ANIMATION_AUTO_REPLY_EN}`).then(
              (res) => {
                res.json().then((json) => {
                  setAnimationAutoReply_EN(json);
                });
              },
            );
          }
          break;
        }
      }
    } else {
      switch (uiState.tutorialModalType) {
        case FlowTemplateEnum.RN: {
          if (!animation_RN_ZH) {
            fetch(`${googleStorageEndpoint}${ANIMATION_RN_ZH}`).then((res) => {
              res.json().then((json) => {
                setAnimationRN_ZH(json);
              });
            });
          }
          break;
        }
        case FlowTemplateEnum.DRAW: {
          if (!animation_Draw_ZH) {
            fetch(`${googleStorageEndpoint}${ANIMATION_DRAW_ZH}`).then(
              (res) => {
                res.json().then((json) => {
                  setAnimationDraw_ZH(json);
                });
              },
            );
          }
          break;
        }
        // AUTO REPLY
        default: {
          if (!animation_AutoReply_ZH) {
            fetch(`${googleStorageEndpoint}${ANIMATION_AUTO_REPLY_ZH}`).then(
              (res) => {
                res.json().then((json) => {
                  setAnimationAutoReply_ZH(json);
                });
              },
            );
          }
          break;
        }
      }
    }
  }, [
    uiState.tutorialModalType,
    animation_RN_EN,
    animation_RN_ZH,
    animation_Draw_EN,
    animation_Draw_ZH,
    animation_AutoReply_EN,
    animation_AutoReply_ZH,
    googleStorageEndpoint,
  ]);

  // GA events
  useEffect(() => {
    if (accountInfo?.tutorial) {
      sendGAEvent(
        'Template Page',
        'Template View',
        'entrance_Automation-BN - entries',
        '',
      );
    } else {
      // template page: no skip
      sendGAEvent(
        'Template Page',
        'Template View',
        'entrance_on-boarding - entries',
        '',
      );
    }
  }, [accountInfo?.tutorial]);

  return (
    <ContainerStyled>
      <Box>
        <Lottie options={defaultOptions} />
      </Box>
      <RightSideWrapperStyled>
        {!!accountInfo?.tutorial && (
          <CloseIcon
            style={{
              right: 40,
              top: 40,
              position: 'absolute',
              color: 'grey.600',
              fontSize: '18',
            }}
            onClick={handleClose}
          />
        )}
        <Typography variant="h4" color="grey.800">
          {title}
        </Typography>
        <br />

        <ContentWrapperStyled>
          <Typography variant="body1" color="grey.600">
            {content}
          </Typography>
          <br />
          <br />
          <Typography variant="body1" color="primary">
            <Trans i18nKey="tutorial.subtitle" />
          </Typography>
          <Typography variant="body1" color="grey.600">
            {info1}
          </Typography>
          <Typography variant="body1" color="grey.600">
            {info2}
          </Typography>
          <Typography variant="body1" color="grey.600">
            {info3}
          </Typography>
          {info4 && (
            <Typography variant="body1" color="grey.600">
              {info4}
            </Typography>
          )}
        </ContentWrapperStyled>
        <ButtonWrapperStyled>
          <Box>
            <Button
              onClick={handleTemplateButtonClick}
              variant="contained"
              fullWidth
              color="primary"
              disabled={isViewer}
            >
              {t('tutorial.button1')}
            </Button>
          </Box>
          <Box>
            <Button
              onClick={handleCreateFlowButtonClick}
              fullWidth
              variant="text"
              disabled={isViewer}
            >
              {t('tutorial.button2')}
            </Button>
          </Box>
        </ButtonWrapperStyled>
      </RightSideWrapperStyled>
    </ContainerStyled>
  );
};

export default TutorialModal;

import { globalTheme } from '@frontend/components/external-providers';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box,
  Autocomplete as MuiAutocomplete,
  Paper,
  PaperProps,
  TextField,
  createFilterOptions,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/system';
import React, {
  FC,
  FocusEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Typography } from '../typography/typography';

export interface OptionTypeV2 {
  label: string;
  value: string | undefined | null;
  group?: string | null;
  divider?: boolean;
  onClick?: () => void;
  // if true 不會出現在搜尋結果
  exclude?: boolean;
}

export interface AutocompleteV2Props {
  value: string | null | undefined;
  options: OptionTypeV2[];
  disabled?: boolean;
  noOptionsText?: string;
  label?: string;
  loading?: boolean;
  placeholder?: string;
  customMessage?: JSX.Element;
  required?: boolean;
  errorMessage?: string;
  onChange?: (event: object, newValue: OptionTypeV2 | null) => void;
  onFocus?: () => void;
  autoFocus?: boolean;
  inputReadOnly?: boolean;
  disableClearable?: boolean;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  blurOnSelect?: boolean;
  renderOption?: (props: any, option: OptionTypeV2) => JSX.Element;
}

// 排除某些選項出現在搜尋結果
const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option: OptionTypeV2) => (option.exclude ? '' : option.label),
});

// 客製化 AutocompleteV2 的下拉選單樣式
const CustomPaper = (props: PaperProps) => {
  return <Paper elevation={8} {...props} />;
};

const TextFieldStyled = styled(TextField)(({ theme }) => ({
  input: {
    '&::placeholder': {
      color: theme.palette['grey']['black'],
      opacity: 0.6,
    },
  },
}));

export const AutocompleteV2: FC<AutocompleteV2Props> = ({
  value,
  label,
  disabled,
  noOptionsText,
  options,
  placeholder,
  customMessage,
  errorMessage,
  required,
  loading = false,
  onChange = () => undefined,
  onFocus = () => undefined,
  autoFocus = false,
  inputReadOnly = false,
  disableClearable = false,
  blurOnSelect = true,
  onBlur = (event) => undefined,
  renderOption,
}: AutocompleteV2Props) => {
  const defaultValue = options.find((x) => x.value === value);
  const [inputValue, setInputValue] = useState(defaultValue?.label || '');
  const [selectValue, setSelectValue] = useState<OptionTypeV2 | null>(
    defaultValue || null,
  );

  const handleKeyDownCapture = useCallback((e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
    }
  }, []);

  useEffect(() => {
    const currentValue = options.find((x) => x.value === value);
    setSelectValue(currentValue ? currentValue : null);
  }, [defaultValue, options, value]);

  const onOptionChange = useCallback(
    (event: object, newValue: OptionTypeV2 | null) => {
      if (newValue && newValue.value) {
        setSelectValue(newValue);
      } else {
        setSelectValue(null);
      }
      onChange && onChange(event, newValue);
    },
    [onChange],
  );

  return (
    <Box>
      <MuiAutocomplete
        onKeyDownCapture={handleKeyDownCapture}
        disableClearable={disableClearable}
        filterOptions={filterOptions}
        noOptionsText={noOptionsText}
        isOptionEqualToValue={(option: OptionTypeV2, value: OptionTypeV2) =>
          option.value === value.value
        }
        value={selectValue}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        disabled={disabled || loading}
        popupIcon={
          loading ? (
            <CircularProgress
              style={{
                animation: 'rotate 2s linear infinite',
                width: 14,
                height: 14,
              }}
            />
          ) : (
            <KeyboardArrowDownIcon />
          )
        }
        size="small"
        options={options}
        getOptionLabel={(option: OptionTypeV2) => option.label}
        groupBy={(option) => option.group || ''}
        PaperComponent={CustomPaper}
        blurOnSelect={blurOnSelect}
        renderGroup={(params) =>
          params.group ? (
            <div
              key={`autocomplete_${params.key}_${params.group}}`}
              style={{
                ...(params.key.toString() !== '0' && {
                  borderTop: `4px solid ${globalTheme.palette?.bluegrey?.[50]}`,
                }),
              }}
            >
              <div
                style={{
                  padding: '8px 0',
                }}
              >
                <Typography ml={2} variant="caption" color="bluegrey.400">
                  {params.group}
                </Typography>
              </div>
              {params.children}
            </div>
          ) : (
            params.children
          )
        }
        renderOption={
          renderOption
            ? renderOption
            : (props, option: OptionTypeV2) => (
                <li
                  key={`autocomplete_${option.value}_${props.tabIndex}}`}
                  {...props}
                  style={{
                    ...(option.divider && {
                      borderBottom: `4px solid ${globalTheme.palette?.bluegrey?.[50]}`,
                    }),
                  }}
                  {...(option.onClick && {
                    onClick: option.onClick,
                  })}
                >
                  <Typography variant="menuItem" color="grey.900">
                    {option.label}
                  </Typography>
                </li>
              )
        }
        onChange={onOptionChange}
        renderInput={(params) => (
          <TextFieldStyled
            {...params}
            onFocus={onFocus}
            required={required}
            variant="outlined"
            size="small"
            label={label}
            disabled={disabled}
            placeholder={placeholder}
            fullWidth
            error={!!errorMessage || !!customMessage || (required && !value)}
            autoFocus={autoFocus}
            onBlur={onBlur}
            inputProps={{
              ...params.inputProps,
              readOnly: inputReadOnly,
              ...(inputReadOnly && { style: { cursor: 'pointer' } }),
            }}
          />
        )}
      />
      {errorMessage && (
        <Box>
          <Typography variant="body2" color="error.main">
            {errorMessage}
          </Typography>
        </Box>
      )}
      {customMessage && customMessage}
    </Box>
  );
};

export default AutocompleteV2;

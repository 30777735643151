import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Avatar, Box } from '@mui/material';
import { styled } from '@mui/system';
import { Trans } from 'react-i18next';
import Gosky from '../../images/gosky.svg';
import { Typography } from '../typography/typography';

const PhonePreviewHeaderStyled = styled(Box)(({ theme }) => ({
  width: '100%',
}));

export function PhonePreviewHeader() {
  return (
    <PhonePreviewHeaderStyled>
      <Box height="35px" display="inline-flex" alignItems="center">
        <Box>
          <ChevronLeftIcon color="primary" fontSize="small" />
        </Box>
        <Box mr={1}>
          <Avatar
            src={Gosky}
            sx={{
              width: '30px',
              height: '30px',
            }}
          />
        </Box>
        <Box>
          <Typography fontSize="small" variant="subtitle2" color="grey.900">
            <Trans i18nKey="basicSetting.welcomeMessage.preview.userName" />
          </Typography>
          <Typography
            fontSize="small"
            variant="body2"
            color="grey.500"
            sx={{
              fontSize: '10px',
            }}
          >
            <Trans i18nKey="basicSetting.welcomeMessage.preview.info" />
          </Typography>
        </Box>
      </Box>
    </PhonePreviewHeaderStyled>
  );
}

export default PhonePreviewHeader;

import { useCanvasFlowView, useModal } from '@frontend/editor/data-access';
import {
  DrawerTypesEnum,
  EntryPointsEnum,
  ModalTypesEnum,
} from '@frontend/editor/interface';
import { usePath } from '@frontend/sorghum/utils';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactFlow from 'reactflow';
import 'reactflow/dist/style.css';
import ConnectionLineComponent from '../connection-line-component/connection-line-component';
import Control from '../control/control';
import Dialog from '../dialog/dialog';
import DrawerViewTrigger from '../drawer-view-trigger/drawer-view-trigger';
import CustomEdge, {
  CustomFocusMarker,
  CustomMarker,
} from '../editor-custom-edge/editor-custom-edge';
import NodeEntryPoint from '../node-entry-point/node-entry-point';
import NodeNote from '../node-note/node-note';
import ViewLoadingModal from '../view-loading-modal/view-loading-modal';
import ViewNodeAction from '../view-node-action/view-node-action';
import viewNodeBlock from '../view-node-block/view-node-block';
import ViewNodeCondition from '../view-node-condition/view-node-condition';
import viewNodeCoupon from '../view-node-coupon/view-node-coupon';
import viewNodeJumpToFlow from '../view-node-jump-to-flow/view-node-jump-to-flow';
import ViewNodeShareLink from '../view-node-share-link/view-node-share-link';

interface ViewProps {
  welcomeMessagePage: string;
  defaultAnswerPage: string;
  persistentMenuPage: string;
}

const nodeTypes = {
  BLOCK: viewNodeBlock,
  ENTRY_POINT: NodeEntryPoint,
  SHARE_LINK: ViewNodeShareLink,
  ACTION: ViewNodeAction,
  CONDITION: ViewNodeCondition,
  COUPON: viewNodeCoupon,
  NOTE: NodeNote,
  JUMP_TO_FLOW: viewNodeJumpToFlow,
};

const edgeTypes = {
  CUSTOM: CustomEdge,
};

const ViewWrapperStyled = styled(Box)(({ theme }) => ({
  flex: '1',
  width: '100%',
  height: 'calc(100% - 56px)',
  position: 'relative',
  '& .react-flow__node': {
    outline: 'none',
  },
  '& .react-flow__edge': {
    '&.selected': {
      '& .react-flow__edge-path': {
        stroke: theme.palette['blue']['600'],
      },
    },
  },
  background: theme.palette['grey']['200'],
}));

export const View: FC<ViewProps> = ({
  defaultAnswerPage,
  persistentMenuPage,
  welcomeMessagePage,
}: ViewProps) => {
  const { navigateToProjectPage } = usePath();
  const { ref, nodes, edges, setDrawerType } = useCanvasFlowView();
  const { openModal, modalState, closeModal } = useModal();

  const [t] = useTranslation();

  const gotoBasicSetting = useCallback(() => {
    closeModal();
    switch (modalState.entryType) {
      case EntryPointsEnum.DEFAULT_ANSWER: {
        navigateToProjectPage(defaultAnswerPage);
        break;
      }
      case EntryPointsEnum.PERSISTENT_MENU: {
        navigateToProjectPage(persistentMenuPage);
        break;
      }
      case EntryPointsEnum.WELCOME_MESSAGE: {
        navigateToProjectPage(welcomeMessagePage);
        break;
      }
    }
  }, [
    closeModal,
    defaultAnswerPage,
    modalState.entryType,
    navigateToProjectPage,
    persistentMenuPage,
    welcomeMessagePage,
  ]);

  useEffect(() => {
    setDrawerType(DrawerTypesEnum.VIEW_TRIGGER);
  }, [setDrawerType]);

  return (
    <ViewWrapperStyled ref={ref}>
      <ViewLoadingModal />
      <DrawerViewTrigger />
      <ReactFlow
        nodes={nodes}
        edges={edges}
        draggable={false}
        contentEditable={false}
        nodesConnectable={false}
        nodesDraggable={false}
        selectNodesOnDrag={false}
        selectionKeyCode={null}
        nodeTypes={nodeTypes}
        // 預設的 edge 樣式
        defaultEdgeOptions={{
          type: 'CUSTOM',
        }}
        // 設定 custom edge
        edgeTypes={edgeTypes}
        // 設定拉線時的樣式
        connectionLineComponent={ConnectionLineComponent}
        fitView
        fitViewOptions={{
          maxZoom: 0.8,
          minZoom: 0.8,
        }}
      >
        <Control showInteractive={false} />
      </ReactFlow>
      {/* modal 提示 basic setting toggle 設定 */}
      <Dialog
        size="xs"
        title={t('canvas.modal.basicSettingToggle.title')}
        content={t('canvas.modal.basicSettingToggle.info')}
        open={openModal === ModalTypesEnum.BASIC_SETTING_TOGGLE}
        handleClose={closeModal}
        cancelBtnText={t('common.cancel')}
        confirmBtnText={t('canvas.modal.basicSettingToggle.confirm')}
        handleConfirm={gotoBasicSetting}
      />
      <CustomMarker />
      <CustomFocusMarker />
    </ViewWrapperStyled>
  );
};

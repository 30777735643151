import { uuid } from '@frontend/components/utils';
import { EditorCtx, UICtx } from '@frontend/editor/external-providers';
import { ElementTypesEnum } from '@frontend/editor/interface';
import { getUniquePosition } from '@frontend/editor/utils';
import { useCallback, useContext } from 'react';
import { useViewport } from 'reactflow';
import useCanvasCollect from '../use-canvas-collect/use-canvas-collect';
import useCanvasFlow from '../use-canvas-flow/use-canvas-flow';

export function useCanvasCopy() {
  const state = useContext(EditorCtx);
  const uiState = useContext(UICtx);
  const { zoom, x: viewportCenterX, y: viewportCenterY } = useViewport();
  const { setNodesSelected } = useCanvasFlow();
  const { NEW_ELEMENT_POSITION_DISTANCE } = useCanvasCollect();
  const innerWidth = state.ref.current?.clientWidth || 0;
  const innerHeight = state.ref.current?.clientHeight || 0;
  const centerX = (innerWidth / 2 - viewportCenterX) / zoom;
  const centerY = (innerHeight / 2 - viewportCenterY) / zoom;

  const findCopyCenterCoordinates = useCallback(
    (blockPositionMap: Map<string, { x: number; y: number }>) => {
      const centerCoordinate = getUniquePosition(
        centerX,
        centerY,
        state.elements,
        NEW_ELEMENT_POSITION_DISTANCE,
      );

      const minX = Math.min(
        ...Array.from(blockPositionMap.values()).map((i) => i.x),
      );
      const leftestBlock = Array.from(blockPositionMap.entries()).find(
        ([key, position]) => position.x === minX,
      );

      if (leftestBlock) {
        const offsetX = centerCoordinate.x - leftestBlock[1].x;
        const offsetY = centerCoordinate.y - leftestBlock[1].y;

        return { x: offsetX, y: offsetY };
      }

      return { x: 0, y: 0 };
    },
    [NEW_ELEMENT_POSITION_DISTANCE, centerX, centerY, state.elements],
  );

  //FIXME: any
  const copyObjects = useCallback(
    (arr: any[]) => {
      const idMap = new Map();
      const blockPositionMap = new Map();
      const newObjArr: any[] = [];

      arr.forEach((obj: any) => {
        idMap.set(obj.id, uuid());
        // 辨別 type 來判斷要有哪些 key
      });

      arr.forEach((obj) => {
        if (obj.elementType === ElementTypesEnum.BLOCK) {
          blockPositionMap.set(obj.id, obj.position);
        }
      });

      arr.forEach((obj: any) => {
        const newObj: any = {};
        newObj.id = idMap.get(obj.id);

        Object.keys(obj).forEach((key) => {
          if (key === 'children') {
            newObj[key] = obj[key].map((childId: any) => idMap.get(childId));
          } else if (
            key === 'id' ||
            key === 'targetID' ||
            key === 'parentID' ||
            key === 'nodeID'
          ) {
            if (idMap.has(obj[key])) {
              newObj[key] = idMap.get(obj[key]);
            } else {
              newObj[key] = '';
            }
          } else if (key === 'inputID' || key === 'outputID') {
            // 生成新的 input 和 output ID
            newObj[key] = uuid();
          } else if (key === 'createDate') {
            newObj[key] = new Date().getTime();
          } else if (key === 'position') {
            if (obj.elementType === ElementTypesEnum.BLOCK) {
              newObj[key] = {
                x: obj[key].x + findCopyCenterCoordinates(blockPositionMap).x,
                y: obj[key].y + findCopyCenterCoordinates(blockPositionMap).y,
              };
            }
          } else {
            newObj[key] = obj[key];
          }
        });

        newObjArr.push(newObj);
      });

      return newObjArr;
    },
    [findCopyCenterCoordinates],
  );

  const copyBlocks = useCallback(
    (blockList: string[]) => {
      const blockListData: any[] = [];

      if (blockList.length > 0) {
        blockList.forEach((id) => {
          if (state.elements.filter((el) => el.id === id).length > 0) {
            const newElement = state.elements.filter((el) => el.id === id)[0];
            if (
              newElement.elementType !== ElementTypesEnum.ENTRY_POINT &&
              newElement.elementType !== ElementTypesEnum.NOTE
            ) {
              blockListData.push(newElement);
            }
          }
        });
      }

      if (blockListData.length > 0) {
        blockListData.forEach((item) => {
          item.children.forEach((child: string) => {
            if (state.elements.filter((el) => el.id === child).length > 0) {
              const childElement = state.elements.filter(
                (el) => el.id === child,
              )[0];
              blockListData.push({ ...childElement, id: child });

              childElement.children.forEach((grandChild) => {
                if (
                  state.elements.filter((el) => el.id === grandChild).length > 0
                ) {
                  const grandChildElement = state.elements.filter(
                    (el) => el.id === grandChild,
                  )[0];
                  blockListData.push(grandChildElement);
                }
              });
            }
          });
        });
      }

      const copiedBlockList = copyObjects(blockListData);

      const idList = copiedBlockList
        .filter((item) => item.elementType === ElementTypesEnum.BLOCK)
        .map((item) => item.id);

      //FIXME: 沒辦法被選取
      setNodesSelected(idList);
      state.addElements(copiedBlockList);
    },
    [copyObjects, setNodesSelected, state],
  );

  return {
    copyBlocks,
  };
}

import {
  PostBillingOrderProps,
  PostBillingOrderResponse,
  ResponseWithData,
} from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { BILLING_ORDER } from '../apis';

async function postBillingOrder(
  props: PostBillingOrderProps,
  projectID?: string,
): Promise<ResponseWithData<PostBillingOrderResponse>> {
  const token = localStorage.getItem('token') as string;
  const newAxios = axios.create({
    headers: {
      'gosky-api-token': token,
      ...(projectID && { 'project-id': projectID }),
    },
  });
  return newAxios.post(BILLING_ORDER, props).then((res) => res.data);
}

export function usePostBillingOrder(projectID?: string) {
  return useMutation({
    mutationKey: ['post-billing-order', { projectID }],
    mutationFn: (props: PostBillingOrderProps) => {
      return postBillingOrder(props, projectID);
    },
  });
}

export default usePostBillingOrder;

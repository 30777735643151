import { globalTheme } from '@frontend/components/external-providers';
import {
  useCanvasGet,
  useGetCouponOption,
  useGetDataFlowChart,
  useGetProjectID,
} from '@frontend/editor/data-access';
import { UICtx } from '@frontend/editor/external-providers';
import {
  CouponCellType,
  CouponCellTypesEnum,
  CouponType,
  CouponTypesEnum,
  DataFlowChartTrigger,
  NodeContainerStyledProps,
} from '@frontend/editor/interface';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { get } from 'lodash';
import { memo, useContext, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { NodeProps, Position } from 'reactflow';
import EditorViewDataBlock from '../editor-view-data-block/editor-view-data-block';
import EditorViewTextButtonCell from '../editor-view-text-button-cell/editor-view-text-button-cell';
import { HandlePoint } from '../handle-point/handle-point';
import { NodeHeader } from '../node-header/node-header';

const BlockDataWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  justifyContent: 'space-between',
}));

const ContentItemStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette?.['grey'][100],
  borderRadius: '8px',
  padding: '12px',
  gap: '10px',
}));

export const ValueWrapperStyled = styled(Box)(({ theme }) => ({
  padding: '2px 8px',
  borderRadius: '8px',
  width: '100%',
  background: theme.palette['bluegrey']['50'],
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'flex',
  alignItems: 'center',
}));

const BodyStyled = styled(Box)(({ theme }) => ({
  width: '316px',
  borderRadius: '0 0 12px 12px',
  display: 'flex',
  justifyContent: 'end',
  flexDirection: 'column',
  padding: theme.spacing(1.5),
  gap: '10px',
}));

const ContainerStyled = styled(Box)<NodeContainerStyledProps>(
  ({ theme, $focus, $zoom }) => ({
    background: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '11px',
    cursor: 'move',
    fontSize: `${$zoom ? 28 / $zoom : 28}px`,
    boxShadow:
      '0px 3px 6px rgba(69, 90, 100, 0.15), 0px 2px 3px rgba(69, 90, 100, 0.12)',
    ...($focus && { border: `2px solid ${theme.palette['grass']['600']}` }),
  }),
);

export const ViewNodeCoupon = ({ id }: NodeProps) => {
  const { getTargetElement } = useCanvasGet();
  const { id: flowID } = useParams();
  const [t] = useTranslation();
  const { data: projectID } = useGetProjectID();

  const { data: couponOptions } = useGetCouponOption({
    projectID: projectID as string,
  });
  const uiState = useContext(UICtx);
  const { viewFlowEntryID, viewFlowEntryType } = uiState;
  const { data: viewData } = useGetDataFlowChart({
    projectID: projectID as string,
    flowID: flowID as string,
    entryID: viewFlowEntryID,
    entryType: viewFlowEntryType,
  });
  const ViewNodeCouponData =
    (getTargetElement(id) as CouponType) || ({} as CouponType);

  const {
    label: header,
    inputID,
    children: buttons,
  } = (ViewNodeCouponData as CouponType) || ({} as CouponType);

  const { couponTitle, couponName } = useMemo(() => {
    const couponName = couponOptions?.find(
      (i) => i.id === ViewNodeCouponData.couponValue,
    )?.name;
    switch (ViewNodeCouponData.type) {
      case CouponTypesEnum.SEND: {
        return {
          couponTitle: t('canvas.coupon.send.title'),
          couponName,
        };
      }
      case CouponTypesEnum.REDEEM: {
        return {
          couponTitle: t('canvas.coupon.redeem.title'),
          couponName,
        };
      }
      case CouponTypesEnum.SHOW:
      default: {
        return {
          couponTitle: t('canvas.coupon.show.title'),
          couponName,
        };
      }
    }
  }, [
    ViewNodeCouponData?.couponValue,
    ViewNodeCouponData?.type,
    couponOptions,
    t,
  ]);

  const { uniqTrigger, trigger } = useMemo(() => {
    const blockData: DataFlowChartTrigger = get(viewData, `blocks.${id}`, {});

    return {
      uniqTrigger: blockData?.uniqTrigger,
      trigger: blockData?.trigger,
    };
  }, [viewData, id]);

  return (
    <Box>
      {inputID && (
        <HandlePoint
          id={inputID}
          type="target"
          position={Position.Left}
          styles={{
            top: '5%',
          }}
        />
      )}
      <ContainerStyled>
        <NodeHeader
          color={globalTheme.palette?.['grass'][600] as string}
          background={globalTheme.palette?.['grass'][50]}
          icon={<CallSplitIcon fontSize="small" />}
          title={header}
          readonly
        />

        <BodyStyled>
          <BlockDataWrapperStyled>
            <EditorViewDataBlock
              title={t('view.block.trigger')}
              tooltipTitle={t('view.block.triggerTooltip')}
              count={trigger}
              uniqCount={uniqTrigger}
            />
          </BlockDataWrapperStyled>
          <ContentItemStyled>
            <Box
              sx={{
                paddingLeft: '8px',
              }}
            >
              <Typography variant="subtitle2">{couponTitle}</Typography>
            </Box>
            <ValueWrapperStyled>
              {couponName ? (
                <Typography variant="caption" color="primary">
                  {couponName}
                </Typography>
              ) : (
                <Typography variant="caption" color="bluegrey.400">
                  <Trans i18nKey="canvas.coupon.alert" />
                </Typography>
              )}
            </ValueWrapperStyled>

            {buttons?.map((i: string) => {
              const cellData = getTargetElement(i) as CouponCellType;
              const percentage = get(viewData, `cells.${i}.uniqTriggerP`);
              let cellLabel = '';
              switch (ViewNodeCouponData.type) {
                case CouponTypesEnum.SEND: {
                  switch (cellData.cellType) {
                    case CouponCellTypesEnum.SUCCESS: {
                      cellLabel = t('canvas.coupon.send.success');
                      break;
                    }
                    case CouponCellTypesEnum.EXHAUSTED: {
                      cellLabel = t('canvas.coupon.send.exhausted');
                      break;
                    }
                    case CouponCellTypesEnum.LIMIT: {
                      cellLabel = t('canvas.coupon.send.limit');
                      break;
                    }
                  }
                  break;
                }
                case CouponTypesEnum.SHOW: {
                  switch (cellData.cellType) {
                    case CouponCellTypesEnum.SUCCESS: {
                      cellLabel = t('canvas.coupon.show.success');
                      break;
                    }
                    case CouponCellTypesEnum.FAIL: {
                      cellLabel = t('canvas.coupon.show.fail');
                      break;
                    }
                  }
                  break;
                }
                case CouponTypesEnum.REDEEM: {
                  switch (cellData.cellType) {
                    case CouponCellTypesEnum.SUCCESS: {
                      cellLabel = t('canvas.coupon.redeem.success');
                      break;
                    }
                    case CouponCellTypesEnum.FAIL: {
                      cellLabel = t('canvas.coupon.redeem.fail');
                      break;
                    }
                  }
                  break;
                }
              }

              if (cellData) {
                return (
                  <EditorViewTextButtonCell
                    key={i}
                    buttonNumber={percentage}
                    variant="coupon"
                    textButtonDisabled={true}
                    text={cellLabel}
                    targetID={cellData?.targetID}
                    outputID={cellData?.outputID}
                  />
                );
              } else {
                return null;
              }
            })}
          </ContentItemStyled>
        </BodyStyled>
      </ContainerStyled>
    </Box>
  );
};

export default memo(ViewNodeCoupon);

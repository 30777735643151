import { Button, ButtonProps, Typography } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

/* eslint-disable-next-line */
export interface GradientButtonProps extends ButtonProps {
  isShining: boolean;
}

const StyledGradientButton = styled(Button)(({ theme }) => ({
  '@keyframes gradient': {
    '0%': {
      backgroundPosition: '0% 50%',
    },
    '50%': {
      backgroundPosition: '100% 50%',
    },
    '100%': {
      backgroundPosition: '0% 50%',
    },
  },
  '&.gradient': {
    background: `linear-gradient(-45deg, ${theme.palette['primary']['main']} 0%,${theme.palette['primary']['light']} 25%,${theme.palette['primary']['main']} 75%,${theme.palette['primary']['light']} 100%)`,
    animation: `gradient 5s ease infinite`,
    backgroundSize: '400% 400%',
    '&:hover': {
      animation: 'none',
      background: `${theme.palette['primary']['main']}`,
    },
  },
  '&.Mui-disabled': {
    color: theme.palette['grey']['white'],
    background: theme.palette['grey']['300'],
    animation: 'none',
  },
}));

export const GradientButton: FC<PropsWithChildren<GradientButtonProps>> = ({
  children,
  isShining,
  size = 'medium',
  ...props
}: PropsWithChildren<GradientButtonProps>) => {
  let variant: 'buttonLarge' | 'button' | 'buttonSmall' = 'button';

  // 不同的 button size 會使用不同的 text variant
  switch (size) {
    case 'large': {
      variant = 'buttonLarge';
      break;
    }
    case 'medium': {
      variant = 'button';
      break;
    }
    case 'small': {
      variant = 'buttonSmall';
      break;
    }
  }

  return (
    <StyledGradientButton {...props} className={isShining ? 'gradient' : ''}>
      <Typography
        variant={variant}
        sx={{ textTransform: 'none', textWrap: 'nowrap' }}
      >
        {children}
      </Typography>
    </StyledGradientButton>
  );
};

export default GradientButton;

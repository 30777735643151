import { Typography } from '@frontend/components/ui';
import { DefaultAnswerForm } from '@frontend/sorghum/interface';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Avatar, Box } from '@mui/material';
import { styled } from '@mui/system';
import { FC } from 'react';
import Gosky from '../../images/gosky.svg';

interface DefaultAnswerPreviewProps {
  data: DefaultAnswerForm;
}

const PreviewContainerStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette['grey'][50],
}));

const MessageWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column',
}));

const MessageStyled = styled(Box)(({ theme }) => ({
  borderRadius: '20px',
  padding: '8px 12px',
  margin: '4px',
  maxWidth: '145px',
  width: 'fit-content',
}));

const RightSideMessageStyled = styled(MessageStyled)(({ theme }) => ({
  backgroundColor: theme.palette['primary'].main,
  color: theme.palette['grey']['white'],
  alignSelf: 'end',
}));

const LeftSideMessageStyled = styled(MessageStyled)(({ theme }) => ({
  backgroundColor: theme.palette['grey'][200],
  color: theme.palette['grey']['black'],
  alignItems: 'cetner',
}));

export const DefaultAnswerPreview: FC<DefaultAnswerPreviewProps> = () => {
  return (
    <PreviewContainerStyled>
      <Box height="35px" display="inline-flex" alignItems="center">
        <Box>
          <ChevronLeftIcon color="primary" fontSize="small" />
        </Box>
        <Box mr={1}>
          <Avatar
            src={Gosky}
            sx={{
              width: '30px',
              height: '30px',
            }}
          />
        </Box>
        <Box>
          <Typography fontSize="small" variant="subtitle2" color="grey.900">
            {'Gosky test'}
          </Typography>
          <Typography fontSize="small" variant="body2" color="grey.500">
            {'Typicallly replies instantly'}
          </Typography>
        </Box>
      </Box>
      <MessageWrapperStyled>
        <RightSideMessageStyled>
          <Typography fontSize="small" variant="body2">
            {'Unrecognized content'}
          </Typography>
        </RightSideMessageStyled>

        <Box display="inline-flex">
          <Box justifyContent="end" display="flex" ml={2} mb={1}>
            <Avatar
              src={Gosky}
              sx={{
                width: '17px',
                height: '17px',
              }}
            />
          </Box>
          <LeftSideMessageStyled>
            <Box display="inline-flex">
              <Typography
                lineHeight="14px"
                variant="body2"
                ml={1}
                sx={{
                  letterSpacing: '-0.41px',
                  fontSize: '12px',
                }}
              >
                {'歡迎來到Gosky，請點選下方按鈕開始註冊登入會員系統'}
              </Typography>
            </Box>

            <Box
              mt={1}
              borderRadius="10px"
              padding="8px 12px"
              whiteSpace="nowrap"
              width="fit-content"
              sx={{
                backgroundColor: 'grey.white',
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  letterSpacing: '-0.41px',
                }}
                color="grey.black"
                fontSize="small"
                variant="body2"
              >
                {'前往開始註冊/登入'}
              </Typography>
            </Box>
          </LeftSideMessageStyled>
        </Box>
      </MessageWrapperStyled>
    </PreviewContainerStyled>
  );
};

export default DefaultAnswerPreview;

import { queryClient } from '@frontend/sorghum/external-providers';
import { Response, TrialSurveyProps } from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { PROJECT_SURVEY } from '../apis';

async function postTrialSurvey(props: TrialSurveyProps): Promise<Response> {
  return axios.post(PROJECT_SURVEY, props).then((res) => res.data);
}

export function usePostProjectTrialSurvey() {
  return useMutation({
    mutationKey: ['post-project-trial-survey'],
    mutationFn: (props: TrialSurveyProps) => {
      return postTrialSurvey(props);
    },
    onSuccess: (res: Response) => {
      if (res.code === 20000) {
        const projectID = sessionStorage.getItem('project-id');
        const data = queryClient.getQueriesData(['get-project', { projectID }]);

        if (data) {
          queryClient.setQueryData(['get-project', { projectID }], {
            ...data,
            step: 3,
          });
        }
      }
    },
  });
}

export default usePostProjectTrialSurvey;

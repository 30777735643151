import { GetBillingPaymentResponseType } from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { BILLING_PAYMENT } from '../apis';

const defaultData = {
  planDetail: {
    plan: 1,
    period: 1,
  },
  paymentDetail: {
    method: 1,
    lastFour: '',
  },
  invoiceDetail: {
    type: 1,
  },
};

async function getGetBillingPayment(
  type: number,
): Promise<GetBillingPaymentResponseType> {
  return axios
    .get(`${BILLING_PAYMENT}?type=${type}`)
    .then((res) => res.data.data)
    .catch((err) => {
      return defaultData;
    });
}

export function useGetBillingPayment(type: number, projectID?: string) {
  return useQuery<GetBillingPaymentResponseType>({
    queryKey: ['get-billing-payment', { type, projectID }],
    queryFn: () => {
      if (projectID) {
        return getGetBillingPayment(type);
      } else {
        return defaultData;
      }
    },
  });
}

export default useGetBillingPayment;

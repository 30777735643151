import TagManager from 'react-gtm-module';

export const sendGAEvent = (
  category: string,
  action: string,
  label: string,
  value: string,
) => {
  const args = {
    dataLayer: {
      event: 'customEvent',
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      eventValue: value,
    },
  };

  TagManager.dataLayer(args);
};

export const sendTemplateGAEvent = (id: number, type: 'self' | 'ai') => {
  const eventAction =
    type === 'self' ? 'Template applied self guided' : 'Template applied ai';
  switch (id) {
    case 4:
      return sendGAEvent(
        'Template',
        eventAction,
        'lucky draw: fan page interaction - template applied',
        '',
      );
    case 5:
      return sendGAEvent(
        'Template',
        eventAction,
        'fun interactive quiz - template applied',
        '',
      );
    case 6:
      return sendGAEvent(
        'Template',
        eventAction,
        'event participation reminder - template applied',
        '',
      );
    case 7:
      return sendGAEvent(
        'Template',
        eventAction,
        'data (whitepaper) request - template applied',
        '',
      );
    case 8:
      return sendGAEvent(
        'Template',
        eventAction,
        'interactive event registration - template applied',
        '',
      );
    case 9:
      return sendGAEvent(
        'Template',
        eventAction,
        'guide customers to provide information - template applied',
        '',
      );
    case 10:
      return sendGAEvent(
        'Template',
        eventAction,
        'join membership for discounts - template applied',
        '',
      );
    case 11:
      return sendGAEvent(
        'Template',
        eventAction,
        'newsletter subscription promotion - template applied',
        '',
      );
    case 12:
      return sendGAEvent(
        'Template',
        eventAction,
        'restaurant menu and returning customer - template applied',
        '',
      );
    case 13:
      return sendGAEvent(
        'Template',
        eventAction,
        'returning customer discount promotion - template applied',
        '',
      );
    case 2:
      return sendGAEvent(
        'Template',
        eventAction,
        'automated faq response - template applied',
        '',
      );
    case 3:
      return sendGAEvent(
        'Template',
        eventAction,
        'lucky draw - template applied',
        '',
      );
    case 14:
      return sendGAEvent(
        'Template',
        eventAction,
        'psychological test - template applied',
        '',
      );
    case 15:
      return sendGAEvent(
        'Template',
        eventAction,
        'handoff to customer service - template applied',
        '',
      );
    case 16:
      return sendGAEvent(
        'Template',
        eventAction,
        'get subscribers by displaying your content - template applied',
        '',
      );
    case 17:
      return sendGAEvent(
        'Template',
        eventAction,
        'get subscribers and collect subscriber info - template applied',
        '',
      );
    default:
      return null;
  }
};

import { globalTheme } from '@frontend/components/external-providers';
import { Textarea } from '@frontend/components/ui';
import {
  useCanvasAdd,
  useCanvasCollect,
  useCanvasGet,
  useCanvasRemove,
  useCanvasUpdate,
  useCanvasView,
  useGetProjectID,
  useModal,
} from '@frontend/editor/data-access';
import { EditorCtx, UICtx } from '@frontend/editor/external-providers';
import {
  CollectUserAnswerType,
  DrawerTypesEnum,
  OptionCellType,
  PublishErrorsEnum,
} from '@frontend/editor/interface';
import { useGetEditorLabelItems } from '@frontend/sorghum/data-access';
import { GetEditorLabelItemLabelType } from '@frontend/sorghum/interface';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { get, isEmpty } from 'lodash';
import React, { memo, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableElementProps,
} from 'react-sortable-hoc';
import { useViewport } from 'reactflow';
import EditorFloatPanel from '../editor-float-panel/editor-float-panel';
import { OptionButton } from '../option-button/option-button';
import {
  AddOptionButtonStyled,
  AllowUserInputButtonContainerStyled,
  AllowUserInputButtonStyled,
  AttributeContainerStyled,
  ButtonContainerStyled,
  ContainerStyled,
  HintTextContainerStyled,
  OptionButtonContainerStyled,
  OptionContainerStyled,
  TitleContainerStyled,
  TitleWrapperStyled,
} from './styles';

interface EditorCollectUserAnswerProps {
  id: string;
  draggable: boolean;
  parentID: string;
  index: number;
  setIsHoverMenu: (isHover: boolean) => void;
}

interface SortableItemProps {
  item: string;
  id: string;
  draggable: boolean;
}

interface SortableListProps {
  list: string[];
}

const SortableItem: React.ComponentClass<
  SortableElementProps & SortableItemProps
> = SortableElement(({ item, id, draggable }: SortableItemProps) => {
  const state = useContext(EditorCtx);
  const uiState = useContext(UICtx);

  const { canvasUpdateData } = useCanvasUpdate();
  const { canvasRemoveCell } = useCanvasRemove();
  const { getErrorStatus } = useCanvasCollect();

  const itemData = state.getElement(id) as OptionCellType;

  if (!itemData) {
    return null;
  }

  const handleLabelChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      canvasUpdateData(itemData.id, 'data.title', e.target.value);
    },
    [canvasUpdateData, itemData?.id],
  );

  const handleDelete = useCallback(() => {
    if (itemData) {
      canvasRemoveCell(itemData.id);
    }
  }, [canvasRemoveCell, itemData]);

  const handleMouseEnterInputField = useCallback(
    (objectId: string) => {
      uiState.onHoverElement(objectId, 3);
    },
    [uiState],
  );

  const handleMouseLeaveInputField = useCallback(() => {
    uiState.onHoverElement('', 0);
  }, [uiState]);

  return (
    <Box
      key={id}
      onMouseEnter={() => handleMouseEnterInputField(id)}
      onMouseLeave={() => handleMouseLeaveInputField()}
      style={{
        position: 'relative',
      }}
    >
      {uiState.hoveredElement.id === id && (
        <EditorFloatPanel
          draggable={draggable}
          handleDelete={handleDelete}
          direction="column"
        />
      )}
      <OptionButton
        key={item}
        className="button"
        onChange={handleLabelChange}
        id={itemData.outputID}
        value={itemData?.data?.title || ''}
        limit={20}
        error={
          !!getErrorStatus(
            item,
            PublishErrorsEnum.COLLECT_USER_ANSWER_OPTION_TITLE_EMPTY,
          )
        }
      />
    </Box>
  );
});

const SortableList: React.ComponentClass<
  SortableContainerProps & SortableListProps
> = SortableContainer(({ list }: SortableListProps) => {
  return (
    <Box>
      {list.map((item, index) => (
        <SortableItem
          key={item}
          index={index}
          item={item}
          id={item}
          draggable={list.length > 1}
        />
      ))}
    </Box>
  );
});

export const EditorCollectUserAnswer = ({
  id,
  index,
  parentID, // Block 的 ID
  draggable,
  setIsHoverMenu, // 檢查是否 hover 在側邊的選單來決定是否開放 element 的 sort event
}: EditorCollectUserAnswerProps) => {
  const [t] = useTranslation();
  const state = useContext(EditorCtx);
  const uiState = useContext(UICtx);
  const { zoom } = useViewport();
  const { data: projectID } = useGetProjectID();
  const { getTargetElement } = useCanvasGet();
  const { openDeleteElementModal } = useModal();
  const { onFocusID, focusOnCell, handleDrawer } = useCanvasView();
  const { canvasUpdateData, canvasSortElement } = useCanvasUpdate();
  const { addOptionCellButton } = useCanvasAdd();
  const { getErrorStatus } = useCanvasCollect();
  const { canvasRemoveElement } = useCanvasRemove();
  const { data: labelItems } = useGetEditorLabelItems(projectID as string);

  const [isHoverBlock, setIsHoverBlock] = useState<boolean>(false);
  const [isShowAttributeText, setIsShowAttributeText] =
    useState<boolean>(false);

  const elementData = useMemo(
    () => getTargetElement(id) as CollectUserAnswerType,
    [getTargetElement, id],
  );
  const children = get(elementData, 'children', []);

  const onRemoveButtonClick = useCallback(() => {
    let isChildrenHasValue = false;
    children.forEach((childID) => {
      const childElement = getTargetElement(childID) as OptionCellType;
      if (childElement?.data?.title) {
        isChildrenHasValue = true;
      }
    });

    if (isChildrenHasValue || elementData?.data?.text) {
      openDeleteElementModal(id);
    } else {
      canvasRemoveElement(id);
    }
  }, [
    canvasRemoveElement,
    children,
    elementData?.data?.text,
    getTargetElement,
    id,
    openDeleteElementModal,
  ]);

  const handleTitleClick = useCallback(() => {
    focusOnCell(id);
    handleDrawer(DrawerTypesEnum.COLLECT_USER_ANSWER);
    setIsShowAttributeText(true);
  }, [focusOnCell, handleDrawer, id]);

  const handleChange = useCallback(
    (text: string) => {
      canvasUpdateData(id, 'data.text', text);
    },
    [canvasUpdateData, id],
  );

  const handleToggleFreeInput = useCallback(() => {
    const isAllow = get(elementData, 'data.isAllowFreeInput', false);
    setIsShowAttributeText(true);

    canvasUpdateData(id, 'data.isAllowFreeInput', !isAllow);
  }, [canvasUpdateData, elementData, id]);

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
      canvasSortElement(id, oldIndex, newIndex);
    },
    [canvasSortElement, id],
  );

  const handleMouseEnterMenu = useCallback(() => {
    setIsHoverMenu(true);
  }, [setIsHoverMenu]);

  const handleMouseLeaveMenu = useCallback(() => {
    setIsHoverMenu(false);
  }, [setIsHoverMenu]);

  const labelList = get(labelItems, 'data', []);

  const isAllowUserInput = get(elementData, 'data.isAllowFreeInput', false);

  return (
    <Box
      onClick={() => {
        state.setOnFocusCellID(id);
      }}
      onMouseEnter={() => setIsHoverBlock(true)}
      onMouseLeave={() => setIsHoverBlock(false)}
    >
      <ContainerStyled $focus={onFocusID === id} $zoom={zoom}>
        {/* Block 左方的按鈕 */}
        {(isHoverBlock || onFocusID === id) &&
          uiState.hoveredElement.layer < 3 && (
            <EditorFloatPanel
              onMouseEnter={handleMouseEnterMenu}
              onMouseLeave={handleMouseLeaveMenu}
              type="element"
              backgroundColor={
                globalTheme.palette?.grey ? globalTheme.palette.grey[100] : ''
              }
              handleDelete={onRemoveButtonClick}
              allowAdd
              parentID={parentID}
              index={index}
              draggable={draggable}
              direction="column"
            />
          )}
        <TitleWrapperStyled onClick={handleTitleClick}>
          <Typography variant="subtitle2" color="grey.900">
            {t('canvas.collectUserAnswer.title')}
          </Typography>
        </TitleWrapperStyled>
        <Textarea
          hasLabelItem={false}
          defaultValue={elementData?.data?.text || ''}
          limit={640}
          onChange={handleChange}
          labelItem={labelList.filter(
            (item: GetEditorLabelItemLabelType) =>
              item.type === 1 || item.type === 2,
          )}
          placeholder={t('canvas.collectUserAnswer.questionPlaceholder')}
          error={
            !!getErrorStatus(
              id,
              PublishErrorsEnum.COLLECT_USER_ANSWER_TITLE_EMPTY,
            )
          }
          publishError={
            !!getErrorStatus(
              id,
              PublishErrorsEnum.COLLECT_USER_ANSWER_TITLE_EMPTY,
            )
          }
        />
        <OptionContainerStyled>
          <Box onClick={() => handleTitleClick()}>
            <TitleContainerStyled>
              <Typography variant="body2" color="grey.700">
                {t('canvas.collectUserAnswer.answerSetting')}
              </Typography>

              {/* FIXME: 有任何錯誤也會顯示 */}
              {((!isAllowUserInput && children.length === 0) ||
                isEmpty(elementData?.data?.attributeId)) && (
                <Tooltip
                  placement="right"
                  title={t('canvas.collectUserAnswer.answerSettingTooltip')}
                >
                  <IconButton size="small" color="error">
                    <WarningAmberOutlinedIcon />
                  </IconButton>
                </Tooltip>
              )}
            </TitleContainerStyled>
            {(isShowAttributeText ||
              !isEmpty(elementData.data.attributeId)) && (
              <AttributeContainerStyled>
                {isEmpty(elementData.data.attributeId) ? (
                  <Typography variant="caption" color="error">
                    {t('canvas.collectUserAnswer.errorAttribute')}
                  </Typography>
                ) : (
                  <Typography color="bluegrey.400" variant="caption">
                    {t('canvas.collectUserAnswer.saveAttribute')}{' '}
                    <Typography variant="caption" color="primary.main">
                      {elementData.data.attributeName}
                    </Typography>
                  </Typography>
                )}
              </AttributeContainerStyled>
            )}
          </Box>
          <OptionButtonContainerStyled>
            <SortableList
              list={children}
              useDragHandle
              axis="y"
              lockAxis="y"
              onSortEnd={onSortEnd}
              helperClass="cell_dragged_style"
            />
          </OptionButtonContainerStyled>
          <ButtonContainerStyled>
            {children.length < 10 && (
              <AddOptionButtonStyled
                id="add_quick_reply"
                onClick={() => {
                  addOptionCellButton(id, 'quickReply');
                  setIsShowAttributeText(true);
                }}
              >
                <AddIcon sx={{ color: 'primary.main' }} />
                <Typography variant="body2" color="primary.main">
                  {t('canvas.collectUserAnswer.addOption')}
                </Typography>
              </AddOptionButtonStyled>
            )}
            <AllowUserInputButtonContainerStyled
              className={isAllowUserInput ? '' : 'inactive'}
            >
              {isAllowUserInput && (
                <Tooltip
                  placement="top"
                  title={t('canvas.collectUserAnswer.freeTextInputTooltip')}
                >
                  <InfoIcon
                    sx={{
                      color: 'bluegrey.200',
                      width: '20px',
                      height: '20px',
                    }}
                  />
                </Tooltip>
              )}
              <Tooltip
                placement="right"
                title={t('canvas.collectUserAnswer.userInputTooltip')}
              >
                <AllowUserInputButtonStyled
                  id="add_free_text_input"
                  className={isAllowUserInput ? '' : 'inactive'}
                  onClick={() => handleToggleFreeInput()}
                >
                  {isAllowUserInput ? (
                    <CheckIcon
                      sx={{ color: 'secondary.dark', marginRight: '8px' }}
                    />
                  ) : (
                    <AddIcon sx={{ color: 'secondary.dark' }} />
                  )}

                  <Typography variant="body2" color="secondary.dark">
                    {t('canvas.collectUserAnswer.allowUserInput')}
                  </Typography>
                </AllowUserInputButtonStyled>
              </Tooltip>
            </AllowUserInputButtonContainerStyled>
          </ButtonContainerStyled>
        </OptionContainerStyled>
      </ContainerStyled>
      <HintTextContainerStyled>
        <Typography variant="body3" color="warning.dark">
          {t('canvas.collectUserAnswer.hintText')}
        </Typography>
      </HintTextContainerStyled>
    </Box>
  );
};

export default memo(EditorCollectUserAnswer);

import { Box, Fade, Menu, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { FC, PropsWithChildren, useState } from 'react';

import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from '@mui/material';

export interface ButtonMenuProps extends MuiButtonProps {
  title: string;
  disabled?: boolean;
  isUppercase?: boolean;
}

const ContainerStyled = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
}));

const MenuStyled = styled(Menu)(({ theme }) => ({}));

export const ButtonMenu: FC<PropsWithChildren<ButtonMenuProps>> = ({
  children,
  title,
  disabled = false,
  size = 'medium',
  variant = 'contained',
  isUppercase = false,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let textVariant: 'buttonLarge' | 'button' | 'buttonSmall' = 'button';

  // 不同的 button size 會使用不同的 text variant
  switch (size) {
    case 'large': {
      textVariant = 'buttonLarge';
      break;
    }
    case 'medium': {
      textVariant = 'button';
      break;
    }
    case 'small': {
      textVariant = 'buttonSmall';
      break;
    }
  }

  return (
    <ContainerStyled>
      <MuiButton
        {...props}
        size={size}
        onClick={handleClick}
        disabled={disabled}
        variant={variant}
      >
        <Typography
          variant={textVariant}
          sx={
            isUppercase
              ? { textTransform: 'uppercase' }
              : { textTransform: 'none' }
          }
        >
          {title}
        </Typography>
      </MuiButton>
      <MenuStyled
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            minWidth: '200px',
          },
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        TransitionComponent={Fade}
      >
        {children}
      </MenuStyled>
    </ContainerStyled>
  );
};

export default ButtonMenu;

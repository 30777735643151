import { queryClient } from '@frontend/sorghum/external-providers';
import {
  DeleteBillingRequestType,
  Response,
} from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { BILLING } from '../apis';

async function deleteBilling(
  props: DeleteBillingRequestType,
): Promise<Response> {
  return axios
    .delete(BILLING, { data: props })
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function useDeleteBilling() {
  return useMutation({
    mutationKey: ['delete-billing'],
    mutationFn: (props: DeleteBillingRequestType) => deleteBilling(props),
    onSuccess: (res: Response) => {
      if (res.code === 20000) {
        const projectID = sessionStorage.getItem('project-id');
        if (projectID) {
          queryClient.refetchQueries(['get-project', { projectID }]);
          queryClient.refetchQueries(['get-billing', { projectID }]);
        }
      }
    },
  });
}

export default useDeleteBilling;

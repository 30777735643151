import { Typography } from '@frontend/components/ui';
import { PatchEditorConversation } from '@frontend/sorghum/interface';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Avatar, Box } from '@mui/material';
import { styled } from '@mui/system';
import { FC } from 'react';
import Gosky from '../../images/gosky.svg';

interface ConversationStarterPreviewProps {
  data: PatchEditorConversation;
}

const PreviewContainerStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette['grey'][50],
  borderRadius: '0 0 20px 20px',
}));

const MessageWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column-reverse',
  alignItems: 'end',
  height: '470px',
}));

const MessageStyled = styled(Box)(({ theme }) => ({
  borderRadius: '20px',
  padding: '8px 12px',
  margin: '4px',
  fontSize: '12px',
  width: 'fit-content',
}));

const PimaryMessageStyled = styled(MessageStyled)(({ theme }) => ({
  background: theme.palette['primary']['main'],
  color: theme.palette['grey']['white'],
}));

const DefaultMessageStyled = styled(MessageStyled)(({ theme }) => ({
  background: '#F0F0F0',
  color: theme.palette['grey']['400'],
}));

export const ConversationStarterPreview: FC<
  ConversationStarterPreviewProps
> = ({ data }) => {
  return (
    <PreviewContainerStyled>
      <Box height="35px" display="inline-flex" alignItems="center">
        <Box>
          <ChevronLeftIcon color="primary" fontSize="small" />
        </Box>
        <Box mr={1}>
          <Avatar
            src={Gosky}
            sx={{
              width: '30px',
              height: '30px',
            }}
          />
        </Box>
        <Box>
          <Typography fontSize="small" variant="subtitle2" color="grey.900">
            {'Gosky test'}
          </Typography>
          <Typography fontSize="small" variant="body2" color="grey.500">
            {'Typicallly replies instantly'}
          </Typography>
        </Box>
      </Box>
      <MessageWrapperStyled>
        {data.items.map((i, index) => {
          if (i.title) {
            return (
              <PimaryMessageStyled
                key={`conversation_question_preview_${index}_${new Date().getTime()}`}
              >
                {i.title}
              </PimaryMessageStyled>
            );
          } else {
            return (
              <DefaultMessageStyled
                key={`conversation_question_preview_${index}_${new Date().getTime()}`}
              >
                {'Question'} {index + 1}
              </DefaultMessageStyled>
            );
          }
        })}
      </MessageWrapperStyled>
    </PreviewContainerStyled>
  );
};

export default ConversationStarterPreview;

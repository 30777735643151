import { Button, Radio } from '@frontend/components/ui';
import {
  useGetProjectID,
  useProjectPermission,
} from '@frontend/editor/data-access';
import {
  usePatchCommentReplyPosts,
  useProjectLinked,
} from '@frontend/sorghum/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import {
  GetCommentAutoReplyPost,
  ModalTypesEnum,
  PostType,
  SelectPostsType,
} from '@frontend/sorghum/interface';
import {
  PAGE_SETTING,
  dayjs,
  usePath,
  useTimer,
} from '@frontend/sorghum/utils';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PostImage from '../../images/post-image.svg';
import { CommentAutoReplyPostModal } from '../comment-auto-reply-post-modal/comment-auto-reply-post-modal';

interface CommentAutoReplyFirstStepProps {
  commentAutoReplyID: string;
  disabled?: boolean;
  postData?: SelectPostsType;
  handleSaving: (status: boolean) => void;
  updateData: (newData: {
    replyType: number;
    posts: GetCommentAutoReplyPost[];
  }) => void;
}

type SelectedPostType = PostType | GetCommentAutoReplyPost;

const ContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px',
  gap: '12px',
}));

const RadioWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  width: '100%',
  alignItems: 'center',
  marginBottom: '12px',
}));

const SubtitleWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  width: '100%',
}));

const ButtonWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  width: '100%',
  alignItems: 'center',
  gap: '12px',
}));

const PostContainerStyled = styled(Box)(({ theme }) => ({
  display: 'inline',
  flexDirection: 'column',
  width: '100%',
  maxHeight: '180px',
  overflow: 'auto',
}));

const PostStyled = styled(Box)<{
  $disabled?: boolean;
}>(({ theme }) => ({
  display: 'inline-flex',
  width: '320px',
  height: '64px',
  alignItems: 'center',
  padding: '12px 16px',
  gap: '24px',

  '& > img': {
    width: '44px',
    height: '44px',
    minWidth: '44px',
    minHeight: '44px',
    borderRadius: '4px',
  },
}));

const PostDescriptionStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '12px 16px',
  justifyContent: 'center',
  width: '100%',
  overflow: 'hidden',

  '& > div': {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export const CommentAutoReplyFirstStep: FC<CommentAutoReplyFirstStepProps> = ({
  commentAutoReplyID,
  disabled,
  postData,
  handleSaving,
  updateData,
}) => {
  const [t] = useTranslation();
  const uiState = useContext(UICtx);
  const { navigateToProjectPage } = usePath();
  const [selectedPost, setSelectedPost] = useState<SelectedPostType[]>([]);
  const [radioValue, setRadioValue] = useState<number>(1);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const { data: projectID } = useGetProjectID();
  const { data: linked } = useProjectLinked(projectID as string);
  const { isViewer } = useProjectPermission();
  const { mutate: updateCommentAutoReplyPost, isLoading } =
    usePatchCommentReplyPosts();

  const handleDataChange = useCallback(() => {
    updateData({
      replyType: radioValue,
      posts: selectedPost,
    });
  }, [radioValue, selectedPost, updateData]);

  const handleButtonClick = useCallback(() => {
    if (linked?.fb?.id) {
      uiState.openModal(ModalTypesEnum.EDIT_COMMENT_AUTO_REPLY_POST);
    } else {
      uiState.openCommonModal(
        t('commentAutoReply.modal.fanPageModal.title'),
        t('commentAutoReply.modal.fanPageModal.description'),
        () => {
          navigateToProjectPage(PAGE_SETTING);
        },
        t('alert.connect'),
      );
    }
  }, [linked?.fb?.id, navigateToProjectPage, t, uiState]);

  const handleRadioChange = useCallback(
    (newValue: number) => {
      if (disabled) return;
      setRadioValue(newValue);
      setIsUpdated(true);
    },
    [disabled],
  );

  const onSave = useCallback(() => {
    if (disabled || isViewer) return;
    updateCommentAutoReplyPost({
      id: commentAutoReplyID,
      isAutoSave: true,
      replyType: radioValue,
      posts: selectedPost,
    });

    setIsUpdated(false);
  }, [
    commentAutoReplyID,
    disabled,
    isViewer,
    radioValue,
    selectedPost,
    updateCommentAutoReplyPost,
  ]);

  const handlePostSelect = useCallback(
    (newValue: GetCommentAutoReplyPost[]) => {
      setSelectedPost(newValue);
      setIsUpdated(true);
    },
    [],
  );

  useEffect(() => {
    if (!isLoaded && postData) {
      setIsLoaded(true);
      setIsUpdated(false);
      setRadioValue(postData.replyType || 1);
      setSelectedPost(postData.posts || []);
    }
  }, [postData, isLoaded]);

  useEffect(() => {
    handleDataChange();
  }, [handleDataChange]);

  useEffect(() => {
    handleSaving(isLoading);
  }, [handleSaving, isLoading]);

  useTimer(2, isUpdated, onSave);

  return (
    <ContainerStyled>
      <SubtitleWrapperStyled>
        <Typography variant="body2" color="grey.600">
          {t('commentAutoReply.content.firstStep.subtitle1')}
        </Typography>
        <Tooltip
          placement="bottom"
          title={t('commentAutoReply.content.firstStep.tooltip')}
        >
          <InfoOutlinedIcon
            fontSize="small"
            sx={{ color: 'bluegrey.600', ml: '10px' }}
          />
        </Tooltip>
      </SubtitleWrapperStyled>

      <RadioWrapperStyled>
        <Radio
          size="small"
          checked={radioValue === 1}
          onClick={() => {
            handleRadioChange(1);
          }}
          disabled={disabled}
        />
        {t('commentAutoReply.content.firstStep.radio1')}

        <Radio
          size="small"
          checked={radioValue === 2}
          onClick={() => handleRadioChange(2)}
          disabled={disabled}
          sx={{ marginLeft: '24px' }}
        />
        {t('commentAutoReply.content.firstStep.radio2')}
      </RadioWrapperStyled>
      {radioValue === 1 && (
        <>
          <Typography variant="body2" color="grey.600">
            {t('commentAutoReply.content.firstStep.subtitle2')}
          </Typography>
          <ButtonWrapperStyled>
            <Button
              sx={{
                whiteSpace: 'nowrap',
              }}
              startIcon={<ImageOutlinedIcon />}
              variant="outlined"
              onClick={handleButtonClick}
              disabled={disabled}
            >
              {t('commentAutoReply.content.firstStep.button')}
            </Button>
            {!linked?.fb?.id && (
              <Typography variant="body1" color="error.main">
                {t('commentAutoReply.content.firstStep.fanPageDisconnected')}
              </Typography>
            )}
            {linked?.fb?.id && selectedPost.length <= 0 && (
              <Typography variant="body1" color="error.main">
                {t('commentAutoReply.content.firstStep.postEmpty')}
              </Typography>
            )}
            {linked?.fb?.id && selectedPost.length > 0 && (
              <Typography variant="body1" color="grey.500">
                {t('commentAutoReply.content.firstStep.buttonInfo', {
                  count: selectedPost.length,
                })}
              </Typography>
            )}
          </ButtonWrapperStyled>
          {selectedPost.length > 0 && linked?.fb?.id && (
            <PostContainerStyled>
              {selectedPost.map((post) => (
                <PostStyled key={post.id} $disabled={disabled}>
                  <img src={post.fullPicture || PostImage} alt="" />
                  <PostDescriptionStyled>
                    <Typography
                      style={{ cursor: 'pointer' }}
                      component="div"
                      color={
                        (post as PostType).isDeleted ? 'grey.400' : 'grey.900'
                      }
                      variant="body3"
                      onClick={() => window.open(post.permalinkUrl, '_blank')}
                    >
                      {post.message
                        ? post.message
                        : t('commentAutoReply.modal.post.postNoText')}
                    </Typography>
                    <Typography
                      color={
                        (post as PostType).isDeleted ? 'grey.400' : 'grey.500'
                      }
                      variant="notoSans"
                    >
                      {(post as PostType).isDeleted
                        ? t('commentAutoReply.content.firstStep.postDeleted')
                        : dayjs(post.createdTime)
                            .tz()
                            .format('MMMM D, YYYY [at] h:mm A')}
                    </Typography>
                  </PostDescriptionStyled>
                </PostStyled>
              ))}
            </PostContainerStyled>
          )}
        </>
      )}
      <CommentAutoReplyPostModal
        commentAutoReplyID={commentAutoReplyID}
        selectedValue={selectedPost}
        handleSubmit={handlePostSelect}
        facebookID={linked?.fb?.id || ''}
      />
    </ContainerStyled>
  );
};

export default CommentAutoReplyFirstStep;

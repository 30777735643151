import { BreadcrumbsItem } from '@frontend/components/interface';
import { EllipsisItemProps, Typography } from '@frontend/components/ui';
import { useProjectPermission } from '@frontend/editor/data-access';
import { NotificationCtx, UICtx } from '@frontend/sorghum/external-providers';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Box, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import { isEmpty } from 'lodash';
import { useContext } from 'react';
import FullScreenLoading from '../full-screen-loading/full-screen-loading';
import TopBar from '../top-bar/top-bar';

export interface PageContainerProps {
  history: BreadcrumbsItem[];
  now: BreadcrumbsItem;
  lastModified?: string;
  menuItems?: EllipsisItemProps[];
  onPublish?: () => void;
  topBarChildren?: React.ReactNode;
  children: React.ReactNode;
  savingText?: React.ReactNode;
  customButton?: React.ReactNode;
  isShowMoreButton?: boolean;
  hasTitle?: boolean;
  title?: string;
  hasTitleTooltip?: boolean;
  titleTooltipText?: string;
  topBarChildrenCondition?: boolean;
  titleChildren?: React.ReactNode;
  titleChildrenCondition?: boolean;
  isActiveLoading?: boolean;
  isShowNoticeBox?: boolean;
  noticeBoxChildren?: React.ReactNode;
}

const PageContainerStyled = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
}));

const PageContentStyled = styled(Box)(({ theme }) => ({
  padding: '0 40px 40px 40px',
  height: 'fit-content',
  minHeight: '100%',
  width: '100%',
  backgroundColor: theme.palette['grey'][100],

  '&.no-title': {
    paddingTop: '60px',
  },
}));

const TitleContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  padding: '87px 40px 20px 40px',
  backgroundColor: theme.palette['grey'][100],

  '.header': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const NoticeBoxContainerStyled = styled(Box)<{
  $hasNotibar: boolean;
  $isOpenLeftSidebar: boolean;
  $isOpenProjectList: boolean;
}>(({ theme, $hasNotibar, $isOpenLeftSidebar, $isOpenProjectList }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'fixed',
  width: `${
    $isOpenLeftSidebar && $isOpenProjectList
      ? 'calc(100% - 560px)'
      : !$isOpenLeftSidebar && $isOpenProjectList
      ? 'calc(100% - 340px)'
      : $isOpenLeftSidebar
      ? 'calc(100% - 280px)'
      : 'calc(100% - 60px)'
  }`,
  top: `${$hasNotibar ? '106px' : '68px'}`,
}));

const TopBarContainerStyled = styled(Box)<{
  $isOpenLeftSidebar: boolean;
  $isOpenProjectList: boolean;
}>(({ theme, $isOpenLeftSidebar, $isOpenProjectList }) => ({
  display: 'flex',
  width: `${
    $isOpenLeftSidebar && $isOpenProjectList
      ? 'calc(100% - 560px)'
      : !$isOpenLeftSidebar && $isOpenProjectList
      ? 'calc(100% - 340px)'
      : $isOpenLeftSidebar
      ? 'calc(100% - 280px)'
      : 'calc(100% - 60px)'
  }`,
  position: 'fixed',
  zIndex: 200,
}));

export function PageContainer({
  history,
  now,
  customButton,
  children,
  hasTitle = false,
  title,
  hasTitleTooltip = false,
  titleTooltipText = '',
  isShowMoreButton = false,
  menuItems = [],
  savingText,
  topBarChildren,
  topBarChildrenCondition = false,
  titleChildren,
  titleChildrenCondition,
  lastModified,
  isActiveLoading = false,
  isShowNoticeBox = false,
  noticeBoxChildren,
}: PageContainerProps) {
  const uiState = useContext(UICtx);
  const notificationState = useContext(NotificationCtx);
  const { isViewer } = useProjectPermission();

  return (
    <PageContainerStyled>
      {isActiveLoading && <FullScreenLoading />}
      <TopBarContainerStyled
        $isOpenLeftSidebar={uiState.isOpenLeftSidebar}
        $isOpenProjectList={uiState.isOpenProjectList}
      >
        <TopBar
          history={history}
          now={now}
          customButton={customButton}
          isShowMoreButton={isShowMoreButton}
          menuItems={menuItems}
          savingText={savingText}
          lastModified={lastModified}
        >
          {topBarChildren && topBarChildrenCondition && topBarChildren}
        </TopBar>
      </TopBarContainerStyled>

      {isShowNoticeBox && !isViewer && (
        <NoticeBoxContainerStyled
          $isOpenLeftSidebar={uiState.isOpenLeftSidebar}
          $isOpenProjectList={uiState.isOpenProjectList}
          $hasNotibar={!!notificationState.noticeBarType}
        >
          {noticeBoxChildren}
        </NoticeBoxContainerStyled>
      )}
      {hasTitle && (
        <TitleContainerStyled>
          <Box className="header">
            <Typography variant="h5" color="grey.800">
              {title}
            </Typography>
            {!isEmpty(titleTooltipText) && (
              <Tooltip placement="top" title={titleTooltipText}>
                <InfoIcon sx={{ color: 'bluegrey.200', ml: '8px' }} />
              </Tooltip>
            )}
          </Box>
          {titleChildren && titleChildrenCondition && titleChildren}
        </TitleContainerStyled>
      )}
      <PageContentStyled className={`${!hasTitle && 'no-title'}`}>
        {children}
      </PageContentStyled>
    </PageContainerStyled>
  );
}

export default PageContainer;

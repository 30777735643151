import {
  AI_GUIDED,
  BROADCAST,
  COMMENT_AUTO_REPLY,
  CONTENT,
  CONVERSATION_STARTER,
  COUPON,
  DASHBOARD,
  DEFAULT_ANSWER,
  DRAFT,
  FLOW,
  FLOW_ENTRY,
  FLOW_VIEW,
  INCENTIVES,
  LIST,
  MAIN,
  ONBOARDING,
  PAYMENT_DETAILS,
  PEOPLE,
  PERSISTENT_MENU,
  SELF_GUIDED,
  SENT,
  SEQUENCE,
  SETTING,
  TEMPLATE_SELECTION,
  TOUR,
  WELCOME_MESSAGE,
  withPageBoundary,
} from '@frontend/sorghum/utils';
import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

import { useAuth } from '@frontend/sorghum/features/dashboard';
import { usePage as useDefaultAnswerPage } from '@frontend/sorghum/features/default-answer';
import { usePage as usePersistentMenuPage } from '@frontend/sorghum/features/persistent-menu';

import { useRoutes } from './useRoutes';

const Dashboard = lazy(() =>
  import('@frontend/sorghum/features/dashboard').then(
    ({ SorghumFeaturesDashboard: Page }) => ({
      default: Page,
    }),
  ),
);

const Setting = lazy(() =>
  import('@frontend/sorghum/features/setting').then(
    ({ SorghumFeaturesSetting: Page }) => ({
      default: Page,
    }),
  ),
);

const Flow = lazy(() =>
  import('@frontend/sorghum/features/flow').then(
    ({ SorghumFeaturesFlow: Page }) => ({
      default: Page,
    }),
  ),
);

const FlowEntry = lazy(() =>
  import('@frontend/sorghum/features/flow-entry').then(
    ({ SorghumFeaturesFlowEntry: Page }) => ({
      default: Page,
    }),
  ),
);

const FlowView = lazy(() =>
  import('@frontend/sorghum/features/flow-view').then(
    ({ SorghumFeaturesFlowView: Page }) => ({
      default: Page,
    }),
  ),
);

const People = lazy(() =>
  import('@frontend/sorghum/features/people').then(
    ({ SorghumFeaturesPeople: Page }) => ({
      default: Page,
    }),
  ),
);

const Broadcast = lazy(() =>
  import('@frontend/sorghum/features/broadcast').then(
    ({ SorghumFeaturesBroadcast: Page }) => ({
      default: Page,
    }),
  ),
);

const BroadcastDraft = lazy(() =>
  import('@frontend/sorghum/features/broadcast-draft').then(
    ({ SorghumFeaturesBroadcastDraft: Page }) => ({
      default: Page,
    }),
  ),
);

const BroadcastSent = lazy(() =>
  import('@frontend/sorghum/features/broadcast-sent').then(
    ({ SorghumFeaturesBroadcastSent: Page }) => ({
      default: Page,
    }),
  ),
);

const PersistentMenu = lazy(() =>
  import('@frontend/sorghum/features/persistent-menu').then(
    ({ SorghumFeaturesPersistentMenu: Page }) => ({
      default: Page,
    }),
  ),
);

const DefaultAnswer = lazy(() =>
  import('@frontend/sorghum/features/default-answer').then(
    ({ SorghumFeaturesDefaultAnswer: Page }) => ({
      default: Page,
    }),
  ),
);

const WelcomeMessage = lazy(() =>
  import('@frontend/sorghum/features/welcome-message').then(
    ({ SorghumFeaturesWelcomeMessage: Page }) => ({
      default: Page,
    }),
  ),
);

const ConversationStarter = lazy(() =>
  import('@frontend/sorghum/features/conversation-starter').then(
    ({ SorghumFeaturesConversationStarter: Page }) => ({
      default: Page,
    }),
  ),
);

const DataDashboard = lazy(() =>
  import('@frontend/sorghum/features/data-dashboard').then(
    ({ SorghumFeaturesDataDashboard: Page }) => ({
      default: Page,
    }),
  ),
);

const DataDashboardFlow = lazy(() =>
  import('@frontend/sorghum/features/data-dashboard-flow').then(
    ({ SorghumFeaturesDataDashboardFlow: Page }) => ({
      default: Page,
    }),
  ),
);

const SequenceList = lazy(() =>
  import('@frontend/sorghum/features/sequence-list').then(
    ({ SorghumFeaturesSequenceList: Page }) => ({
      default: Page,
    }),
  ),
);

const SequenceContent = lazy(() =>
  import('@frontend/sorghum/features/sequence-content').then(
    ({ SorghumFeaturesSequenceContent: Page }) => ({
      default: Page,
    }),
  ),
);

const CouponList = lazy(() =>
  import('@frontend/sorghum/features/coupon-list').then(
    ({ SorghumFeaturesCouponList: Page }) => ({
      default: Page,
    }),
  ),
);

const CouponContent = lazy(() =>
  import('@frontend/sorghum/features/coupon-content').then(
    ({ SorghumFeaturesCouponContent: Page }) => ({
      default: Page,
    }),
  ),
);

const CouponIncentiveList = lazy(() =>
  import('@frontend/sorghum/features/coupon-incentive-list').then(
    ({ SorghumFeaturesCouponIncentiveList: Page }) => ({
      default: Page,
    }),
  ),
);

const CommentAutoReplyList = lazy(() =>
  import('@frontend/sorghum/features/comment-auto-reply-list').then(
    ({ SorghumFeaturesCommentAutoReplyList: Page }) => ({
      default: Page,
    }),
  ),
);

const CommentAutoReplyContent = lazy(() =>
  import('@frontend/sorghum/features/comment-auto-reply-content').then(
    ({ SorghumFeaturesCommentAutoReplyContent: Page }) => ({
      default: Page,
    }),
  ),
);

const PaymentDetail = lazy(() =>
  import('@frontend/sorghum/features/payment-details').then(
    ({ SorghumFeaturesPaymentDetails: Page }) => ({ default: Page }),
  ),
);

const TemplateSelection = lazy(() =>
  import('@frontend/sorghum/features/template-selection').then(
    ({ SorghumFeaturesTemplateSelection: Page }) => ({
      default: Page,
    }),
  ),
);

const TemplateSelectionSelfGuided = lazy(() =>
  import('@frontend/sorghum/features/template-selection-self-guided').then(
    ({ SorghumFeaturesTemplateSelectionSelfGuided: Page }) => ({
      default: Page,
    }),
  ),
);

const TemplateSelectionAiGuided = lazy(() =>
  import('@frontend/sorghum/features/template-selection-ai-guided').then(
    ({ SorghumFeaturesTemplateSelectionAiGuided: Page }) => ({
      default: Page,
    }),
  ),
);
const TourOnboarding = lazy(() =>
  import('@frontend/sorghum/features/tour-onboarding').then(
    ({ SorghumFeaturesTourOnboarding: Page }) => ({
      default: Page,
    }),
  ),
);

export const DashboardRoute = () => {
  const {
    facebookAppID,
    mode,
    permission,
    googleStorageEndpoint,
    tapPayAppID,
  } = useRoutes();

  return (
    <Route
      path={MAIN}
      element={withPageBoundary(
        <Dashboard
          facebookAppID={facebookAppID}
          mode={mode}
          googleStorageEndpoint={googleStorageEndpoint}
          tapPayAppID={tapPayAppID}
          useAuth={useAuth}
        />,
      )}
    >
      <Route path={`:projectID`}>
        <Route
          path={FLOW_ENTRY}
          element={<FlowEntry googleStorageEndpoint={googleStorageEndpoint} />}
        />
        <Route path={`${FLOW}`}>
          <Route
            path=":id"
            element={
              <Flow
                mode={mode}
                facebookAppID={facebookAppID}
                permission={permission}
                googleStorageEndpoint={googleStorageEndpoint}
              />
            }
          />
          <Route
            index
            element={
              <Flow
                mode={mode}
                facebookAppID={facebookAppID}
                permission={permission}
                googleStorageEndpoint={googleStorageEndpoint}
              />
            }
          />
        </Route>
        <Route path={`${FLOW_VIEW}`}>
          <Route path=":id" element={<FlowView />} />
          <Route index element={<FlowView />} />
        </Route>
        <Route
          path={`${PERSISTENT_MENU}`}
          element={<PersistentMenu usePage={usePersistentMenuPage} />}
        />
        <Route
          path={`${DEFAULT_ANSWER}`}
          element={<DefaultAnswer usePage={useDefaultAnswerPage} />}
        />
        <Route path={`${WELCOME_MESSAGE}`} element={<WelcomeMessage />} />
        <Route
          path={`${CONVERSATION_STARTER}`}
          element={<ConversationStarter />}
        />
        {/* Sequence */}
        <Route path={`${SEQUENCE}`} element={<SequenceList />} />
        <Route path={`${SEQUENCE}/:id`}>
          <Route path={`${CONTENT}`} element={<SequenceContent />} />
        </Route>
        {/* Coupon */}
        <Route path={`${COUPON}`} element={<CouponList />} />
        <Route path={`${COUPON}/:id`}>
          <Route path={`${INCENTIVES}`} element={<CouponIncentiveList />} />
          <Route
            path={`${CONTENT}`}
            element={
              <CouponContent googleStorageEndpoint={googleStorageEndpoint} />
            }
          />
        </Route>
        {/* Comment auto reply */}
        <Route
          path={`${COMMENT_AUTO_REPLY}/${LIST}`}
          element={<CommentAutoReplyList />}
        />
        <Route
          path={`${COMMENT_AUTO_REPLY}/:id/${CONTENT}`}
          element={<CommentAutoReplyContent />}
        />
        {/* Setting */}
        <Route
          path={`${SETTING}`}
          element={
            <Setting
              mode={mode}
              facebookAppID={facebookAppID}
              permission={permission}
            />
          }
        />
        {/* setting - payment */}
        <Route
          path={`${SETTING}/${PAYMENT_DETAILS}`}
          element={<PaymentDetail />}
        />

        {/* People */}
        <Route path={`${PEOPLE}`} element={<People />} />

        {/* Broadcast */}
        <Route path={`${BROADCAST}`} element={<Broadcast />} />
        <Route path={`${BROADCAST}/:id`}>
          <Route index element={<Navigate to={`/${BROADCAST}`} />} />
          <Route path={`${DRAFT}`} element={<BroadcastDraft />} />
          <Route path={`${SENT}`} element={<BroadcastSent />} />
        </Route>

        {/* Data dashboard */}
        <Route path={`${DASHBOARD}`} element={<DataDashboard />} />
        <Route path={`${DASHBOARD}/${FLOW}`} element={<DataDashboardFlow />} />
        <Route index element={<Navigate to={`${FLOW_ENTRY}`} />} />

        {/* Onboarding - Template Selection */}
        <Route path={`${TEMPLATE_SELECTION}`} element={<TemplateSelection />} />
        <Route
          path={`${TEMPLATE_SELECTION}/${SELF_GUIDED}`}
          element={
            <TemplateSelectionSelfGuided
              googleStorageEndpoint={googleStorageEndpoint}
            />
          }
        />
        <Route
          path={`${TEMPLATE_SELECTION}/${AI_GUIDED}`}
          element={
            <TemplateSelectionAiGuided
              googleStorageEndpoint={googleStorageEndpoint}
            />
          }
        />
        <Route path={TOUR}>
          <Route
            path={ONBOARDING}
            element={
              <TourOnboarding googleStorageEndpoint={googleStorageEndpoint} />
            }
          />
        </Route>
      </Route>
    </Route>
  );
};

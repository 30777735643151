import { globalTheme } from '@frontend/components/external-providers';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button from '@mui/material/Button';
import ButtonGroup, { ButtonGroupProps } from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/system';
import { get } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';

interface SplitButtonOption {
  label: string;
  value: string;
  disabled?: boolean;
  onClick?: () => void;
}

export interface SplitButtonProps
  extends Pick<ButtonGroupProps, 'color' | 'size' | 'disabled'> {
  defaultValue?: string;
  options: SplitButtonOption[];
  onClick?: (val: SplitButtonOption) => void;
}

const ButtonGroupStyled = styled(ButtonGroup)(({ theme }) => ({
  boxShadow: 'none',
}));

const MainButtonStyled = styled(Button)(({ theme }) => ({
  '&.MuiButtonGroup-grouped:not(:last-of-type)': {
    borderRight: 0,
  },
  '&.MuiButtonGroup-grouped:not(:last-of-type).Mui-disabled': {
    borderRight: 0,
    color: theme.palette['grey']['white'],
  },
}));

const PaperStyled = styled(Paper)(({ theme }) => ({
  minWidth: '200px',
}));

export function SplitButton({
  defaultValue,
  options,
  color = 'primary',
  size = 'small',
  disabled = false,
  onClick,
}: SplitButtonProps) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleClick = useCallback(() => {
    onClick && onClick(options[selectedIndex]);
  }, [onClick, options, selectedIndex]);

  const handleMenuItemClick = (index: number) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (defaultValue) {
      const findIndex = options.findIndex((i) => i.value === defaultValue);
      if (findIndex >= 0) {
        setSelectedIndex(findIndex);
      }
    }
  }, [defaultValue, options]);

  return (
    <>
      <ButtonGroupStyled
        size={size}
        color={color}
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
        disabled={disabled}
      >
        <MainButtonStyled
          onClick={handleClick}
          sx={{ textTransform: 'none', textWrap: 'nowrap' }}
        >
          {options[selectedIndex].label}
        </MainButtonStyled>
        <Button
          size={size}
          color={color}
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          // aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon
            sx={{
              color:
                color === 'secondary'
                  ? get(globalTheme, 'palette.grey.800')
                  : get(globalTheme, 'palette.grey.white'),
            }}
          />
        </Button>
      </ButtonGroupStyled>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <PaperStyled>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.value}
                      disabled={option.disabled}
                      selected={index === selectedIndex}
                      onClick={(_) => {
                        handleMenuItemClick(index);
                        if (option.onClick) option.onClick();
                      }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </PaperStyled>
          </Grow>
        )}
      </Popper>
    </>
  );
}

export default SplitButton;

import {
  Button,
  Select,
  SelectItem,
  Tooltip,
  Typography,
} from '@frontend/components/ui';
import {
  useCanvasUpdate,
  useGetProjectID,
  useIsDrawerDataUpdate,
  usePostProjectAttributeKey,
} from '@frontend/editor/data-access';
import { EditorCtx, UICtx } from '@frontend/editor/external-providers';
import {
  AutocompleteOptionType,
  CollectUserAnswerType,
  DrawerTypesEnum,
  ElementTypesEnum,
} from '@frontend/editor/interface';
import { useGetAttributeKey } from '@frontend/sorghum/data-access';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Box, InputAdornment, TextField } from '@mui/material';
import { styled } from '@mui/system';
import { isEmpty, isNaN } from 'lodash';
import {
  ChangeEvent,
  KeyboardEvent,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Autocomplete from '../autocomplete/autocomplete';
import { EditorDrawer } from '../editor-drawer/editor-drawer';

const ContainerStyled = styled(Box)(({ theme }) => ({
  whiteSpace: 'break-spaces',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  padding: '24px',
}));

const ApplyButtonStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  bottom: '0',
  padding: '24px',
}));

const TitleContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '12px',
  width: '100%',
}));

const TimePeriodContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginTop: '24px',
}));

export const DrawerCollectUserAnswer = () => {
  const { data: projectID } = useGetProjectID();
  const [t] = useTranslation();
  const { onFocusCellID, setIsDrawerUpdate, isDrawerUpdate, getElement } =
    useContext(EditorCtx);
  const uiState = useContext(UICtx);

  const { element } = useMemo(() => {
    const element = getElement(onFocusCellID) as CollectUserAnswerType;
    return {
      element,
    };
  }, [getElement, onFocusCellID]);

  const { isDrawerDataUpdate } = useIsDrawerDataUpdate();
  const { canvasUpdateData } = useCanvasUpdate();
  const { mutate: addAttribute } = usePostProjectAttributeKey(
    projectID as string,
  );
  const { data: attributeData } = useGetAttributeKey(projectID as string);

  const [selectedAttribute, setSelectedAttribute] = useState({
    value: element?.data?.attributeId,
    label: element?.data?.attributeName,
  });
  const [attributeOptions, setAttributeOptions] = useState<
    AutocompleteOptionType[]
  >([]);
  // default value is 30 days
  const [periodType, setPeriodType] = useState<number>(0);
  const [periodValue, setPeriodValue] = useState<number>(0);

  const [isAttributeError, setIsAttributeError] = useState<boolean>(false);
  const [isPeriodValueError, setIsPeriodValueError] = useState<boolean>(false);

  const handleApply = useCallback(() => {
    if (element) {
      if (
        isEmpty(selectedAttribute.value) ||
        (periodType !== 1 && isNaN(periodValue))
      ) {
        if (isEmpty(selectedAttribute.value)) {
          setIsAttributeError(true);
        }
        if (periodType !== 1 && isNaN(periodValue)) {
          setIsPeriodValueError(true);
        }
        return;
      } else {
        canvasUpdateData(
          element.id,
          'data.attributeId',
          selectedAttribute.value,
        );
        canvasUpdateData(element.id, 'data.periodType', periodType);
        canvasUpdateData(element.id, 'data.periodValue', periodValue);
        canvasUpdateData(
          element.id,
          'data.attributeName',
          selectedAttribute.label,
        );
        uiState.setDrawerType(DrawerTypesEnum.CLOSE);
      }
    }
  }, [
    canvasUpdateData,
    element,
    periodType,
    periodValue,
    selectedAttribute.label,
    selectedAttribute.value,
    uiState,
  ]);

  const handleAddOption = useCallback(
    (option: AutocompleteOptionType) => {
      addAttribute(
        { key: option.label },
        {
          onSuccess: (res) => {
            if (res.code === 20000 && res.data.id) {
              setAttributeOptions([
                ...attributeOptions,
                { label: option.label, value: res.data.id },
              ]);
              setSelectedAttribute({
                label: option.label,
                value: res.data.id,
              });
            }
          },
        },
      );
    },
    [addAttribute, attributeOptions],
  );

  const handleUnitKeydown = useCallback((e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'e' || e.key === 'E' || e.key === '-' || e.key === '+') {
      e.preventDefault();
    }
  }, []);

  const handleUnitChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const inputValue = e.target.value;

      // 過濾開頭為零的字串
      const isZeroAtStart = inputValue.match(new RegExp(/^0/gm));

      if (isZeroAtStart) {
        return;
      }

      if (
        (periodType === 2 &&
          (parseInt(inputValue, 10) < 60 || inputValue === '')) ||
        (periodType === 3 &&
          (parseInt(inputValue, 10) < 24 || inputValue === '')) ||
        (periodType === 4 &&
          (parseInt(inputValue, 10) <= 30 || inputValue === ''))
      ) {
        setPeriodValue(parseInt(inputValue, 10));
      }
    },
    [periodType],
  );

  useEffect(() => {
    const newOptions: AutocompleteOptionType[] = [];
    if (!isEmpty(attributeData) && attributeData && attributeData?.length > 0) {
      attributeData.forEach((item) => {
        newOptions.push({
          value: item.id,
          label: item.key,
        });
      });
    }
    setAttributeOptions(newOptions);
  }, [attributeData]);

  useEffect(() => {
    if (element) {
      setSelectedAttribute({
        label: element.data?.attributeName,
        value: element.data?.attributeId,
      });

      setPeriodType(element.data?.periodType || 4);
      if (element.data?.periodValue) {
        setPeriodValue(element.data.periodValue);
      }
    }
  }, [element]);

  useEffect(() => {
    if (element && uiState.drawerType === DrawerTypesEnum.COLLECT_USER_ANSWER) {
      if (
        isDrawerDataUpdate(
          {
            attributeID: element.data?.attributeId,
            periodType: element.data?.periodType,
            periodValue: element.data?.periodValue,
          },
          {
            attributeID: selectedAttribute.value,
            periodType: periodType,
            periodValue: periodValue,
          },
        )
      ) {
        setIsDrawerUpdate(true);
      } else {
        setIsDrawerUpdate(false);
      }
    }
  }, [
    element,
    uiState.drawerType,
    isDrawerDataUpdate,
    periodType,
    periodValue,
    selectedAttribute.value,
    setIsDrawerUpdate,
  ]);

  useEffect(() => {
    if (isEmpty(selectedAttribute.value)) {
      setIsAttributeError(true);
    } else {
      setIsAttributeError(false);
    }
  }, [selectedAttribute.value]);

  useEffect(() => {
    if (periodType !== 1 && isNaN(periodValue)) {
      setIsPeriodValueError(true);
    } else {
      setIsPeriodValueError(false);
    }
  }, [periodType, periodValue]);

  const timePeriodList = [
    { label: t('canvas.collectUserAnswer.drawer.never'), value: 1 },
    { label: t('canvas.collectUserAnswer.drawer.minute'), value: 2 },
    { label: t('canvas.collectUserAnswer.drawer.hour'), value: 3 },
    { label: t('canvas.collectUserAnswer.drawer.day'), value: 4 },
  ];

  if (element && element.elementType === ElementTypesEnum.COLLECT_USER_ANSWER) {
    return (
      <EditorDrawer drawerType={DrawerTypesEnum.COLLECT_USER_ANSWER}>
        <ContainerStyled>
          <Box>
            <Typography
              variant="h6"
              color="grey.900"
              sx={{ marginBottom: '20px' }}
            >
              {t('canvas.collectUserAnswer.drawer.title')}
            </Typography>
            <Typography variant="body2" color="grey.900">
              {t('canvas.collectUserAnswer.drawer.description')}
            </Typography>
            <Autocomplete
              required
              placeholder={t('canvas.action.drawer.addAttribute.placeholder')}
              noOptionsText={t('common.noOptions')}
              label={t('canvas.collectUserAnswer.drawer.attributeName')}
              value={selectedAttribute.value}
              options={attributeOptions}
              addOption={handleAddOption}
              onChange={(option) => {
                setSelectedAttribute({
                  value: option?.value as string,
                  label: option?.label as string,
                });
              }}
              maxLength={100}
              pattern={/[{},@\s]/}
              error={
                isAttributeError
                  ? t('canvas.collectUserAnswer.drawer.errorEmptyAttribute')
                  : ''
              }
            />
            <TimePeriodContainerStyled>
              <TitleContainerStyled>
                <Typography variant="subtitle1" color="grey.800">
                  {t('canvas.collectUserAnswer.drawer.timePeriod')}
                </Typography>
                <Tooltip
                  placement="right"
                  title={t('canvas.collectUserAnswer.drawer.timePeriodTooltip')}
                >
                  <InfoIcon
                    sx={{
                      width: '20px',
                      height: '20px',
                      color: 'bluegrey.200',
                    }}
                  />
                </Tooltip>
              </TitleContainerStyled>
              <Select
                required
                label={t('canvas.collectUserAnswer.drawer.timePeriod')}
                size="small"
                sx={{ width: '100%', margin: '20px 0' }}
                value={periodType}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  setPeriodType(parseInt(e.target.value, 10));
                  setPeriodValue(1);
                }}
              >
                {timePeriodList.map((item) => (
                  <SelectItem key={item.value} value={item.value}>
                    {item.label}
                  </SelectItem>
                ))}
              </Select>
              {periodType !== 1 && (
                <>
                  <TextField
                    required
                    size="small"
                    type="number"
                    sx={{ width: '100%' }}
                    value={isNaN(periodValue) ? '' : periodValue}
                    onChange={(e) => handleUnitChange(e)}
                    // 擋掉使用者輸入 e, +, - 的字元
                    onKeyDown={(e) => handleUnitKeydown(e)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {periodType === 2
                            ? t('canvas.collectUserAnswer.drawer.minuteUnit')
                            : periodType === 3
                            ? t('canvas.collectUserAnswer.drawer.hourUnit')
                            : t('canvas.collectUserAnswer.drawer.dayUnit')}
                        </InputAdornment>
                      ),
                      inputProps: {
                        min: 1,
                        max: periodType === 2 ? 60 : periodType === 3 ? 24 : 30,
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                      },
                    }}
                    error={isPeriodValueError}
                  />
                  <Typography
                    variant="caption"
                    color="grey.500"
                    sx={{ margin: '8px 0 0 14px' }}
                  >
                    {t('canvas.collectUserAnswer.drawer.timePeriodHelpText', {
                      min: 1,
                      max: periodType === 2 ? 59 : periodType === 3 ? 23 : 30,
                    })}
                  </Typography>
                </>
              )}
            </TimePeriodContainerStyled>
          </Box>
          <Button
            id="apply"
            onClick={handleApply}
            size="large"
            variant="contained"
            fullWidth
            disabled={!isDrawerUpdate}
            sx={{ marginTop: '24px' }}
          >
            {t('common.apply')}
          </Button>
        </ContainerStyled>
      </EditorDrawer>
    );
  } else {
    return null;
  }
};

export default memo(DrawerCollectUserAnswer);

import { PlacementEnum } from '@frontend/components/interface';
import {
  Button,
  Chip,
  Divider,
  Pagination,
  ProgressBar,
  Typography,
} from '@frontend/components/ui';
import {
  useGetProjectID,
  useProjectPermission,
} from '@frontend/editor/data-access';
import {
  useGetAccountInfo,
  useGetAccountTutorial,
  usePostAccountTutorial,
} from '@frontend/sorghum/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import { MissionModalTypeEnum } from '@frontend/sorghum/interface';
import CheckIcon from '@mui/icons-material/Check';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import {
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
} from '@mui/material';
import { styled } from '@mui/system';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const MissionBoardContainerStyled = styled(Box)(({ theme }) => ({}));

const MissionButtonStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  bottom: '12px',
  left: '75px',

  zIndex: 5,
  lineHeight: '100%',
  height: '40px',
  paddingTop: '8px',
  boxSizing: 'content-box',

  '.inner-container': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },

  '.MuiTypography-button': {
    lineHeight: '100%',
  },

  '&.opened': {
    left: '291px',
    transition: '0.5s',
  },
}));

const MissionButtonClosedStyled = styled(Button)(({ theme }) => ({
  display: 'flex',
  padding: '8px 16px',
  backgroundColor: theme.palette['bluegrey']['800'],
  color: theme.palette['grey']['white'],
  zIndex: 5,
  lineHeight: '100%',
  borderRadius: '100px',

  '.inner-container': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },

  '.MuiTypography-button': {
    lineHeight: '100%',
  },

  '&:hover': {
    backgroundColor: theme.palette['bluegrey']['800'],
  },
}));

const MissionButtonOpenedStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '20px',
  height: '40px',
  width: '40px',
  backgroundColor: theme.palette['bluegrey']['800'],
  cursor: 'pointer',
}));

const PopoverContainerStyled = styled(Box)(({ theme }) => ({
  width: '320px',
  marginBottom: '8px',
  padding: '8px 0',

  '.title': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 16px',
  },

  '.progress-bar': {
    padding: '4px 16px',
  },

  '.divider': {
    padding: '16px',
  },
}));

const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  '&:hover': {
    backgroundColor: `${theme.palette['primary']['main']}0D`,
  },
}));

//q3s5 TODO: add tutorial API status
export function MissionBoard() {
  const [t] = useTranslation();
  const uiState = useContext(UICtx);
  const [isLoaded, setIsLoaded] = useState(false);
  const { data: projectID } = useGetProjectID();
  const { data: account } = useGetAccountInfo();
  const { data: tutorialData } = useGetAccountTutorial(projectID ?? '');
  const { mutate: updateAccountTutorial } = usePostAccountTutorial();
  const { isViewer } = useProjectPermission();

  const anchorRef = useRef(null);
  const [currentLevel, setCurrentLevel] = useState(1);

  const handleClick = () => {
    uiState.toggleMissionListModal();
  };

  const finishTutorial = useCallback(() => {
    if (account?.isOnboarding) {
      updateAccountTutorial({
        isOnboarding: false,
      });
    }
  }, [account?.isOnboarding, updateAccountTutorial]);

  const level1LessonList = [
    {
      id: 0,
      title: t('flows.missionBoard.missionList.first.lesson1'),
      icon: SchoolOutlinedIcon,
      onClick: () =>
        uiState.setOpenMissionModal(MissionModalTypeEnum.LEVEL1_LESSON1),
      gaID: 'level1_mission1_show_more',
    },
    {
      id: 1,
      title: t('flows.missionBoard.missionList.first.lesson2'),
      icon: LibraryBooksOutlinedIcon,
      onClick: () =>
        uiState.setOpenMissionModal(MissionModalTypeEnum.LEVEL1_LESSON2),
      gaID: 'level1_mission2_show_more',
    },
    {
      id: 2,
      title: t('flows.missionBoard.missionList.first.lesson3'),
      icon: UploadOutlinedIcon,
      onClick: () =>
        uiState.setOpenMissionModal(MissionModalTypeEnum.LEVEL1_LESSON3),
      gaID: 'level1_mission3_show_more',
    },
  ];

  const mappedLevel1LessonList = level1LessonList.map((lesson) => {
    switch (lesson.id) {
      case 0:
        return {
          ...lesson,
          completed:
            tutorialData?.marketingExpert?.canvasTutorial?.isComplete ?? false,
        };
      case 1:
        return {
          ...lesson,
          completed:
            tutorialData?.marketingExpert?.getMarketingTemplate?.isComplete ??
            false,
        };
      case 2:
        return {
          ...lesson,
          completed:
            tutorialData?.marketingExpert?.publishConversationFlow
              ?.isComplete ?? false,
        };
      default:
        return {
          ...lesson,
          completed: false,
        };
    }
  });

  const missionList = [
    {
      id: 0,
      level: 1,
      totalStep: tutorialData?.marketingExpert?.totalStep ?? 0,
      completedStep: tutorialData?.marketingExpert?.completedStep ?? 0,
      title: t('flows.missionBoard.missionList.first.title'),
      lessonList: mappedLevel1LessonList,
    },
    // {
    //   id: 1,
    //   level: 2,
    //   title: t('flows.missionBoard.missionList.second.title'),
    //   lessonList: [
    //     {
    //       id: 0,
    //       title: t('flows.missionBoard.missionList.second.lesson1'),
    //       icon: PeopleOutlinedIcon,
    //       onClick: () => console.log('click'),
    //     },
    //     {
    //       id: 1,
    //       title: t('flows.missionBoard.missionList.second.lesson2'),
    //       icon: RadioOutlinedIcon,
    //       onClick: () => console.log('click'),
    //     },
    //     {
    //       id: 2,
    //       title: t('flows.missionBoard.missionList.second.lesson3'),
    //       icon: DashboardOutlinedIcon,
    //       onClick: () => console.log('click'),
    //     },
    //   ],
    // },
    // {
    //   id: 2,
    //   level: 3,
    //   title: t('flows.missionBoard.missionList.third.title'),
    //   lessonList: [
    //     {
    //       id: 0,
    //       title: t('flows.missionBoard.missionList.third.lesson1'),
    //       icon: StarsOutlinedIcon,
    //       onClick: () => console.log('click'),
    //     },
    //   ],
    // },
  ];

  useEffect(() => {
    if (!isLoaded && anchorRef.current && account?.userName && projectID) {
      setIsLoaded(true);
      if (account.isOnboarding) {
        uiState.setIsOpenMissionListModal(true);
        setTimeout(() => {
          uiState.setTour([
            {
              targets: ['mission-board'],
              tooltipProps: {
                description: t('dashboard.onboarding'),
                placement: PlacementEnum.RIGHT,
                onClose: () => {
                  uiState.setIsTouring(false);
                  finishTutorial();
                },
              },
            },
          ]);
          uiState.setIsTouring(true);
        }, 1000);
      }
    }
  }, [t, account, isLoaded, uiState, finishTutorial, projectID]);

  return (
    <MissionBoardContainerStyled onClick={finishTutorial}>
      {anchorRef.current && (
        <Popover
          open={uiState.isOpenMissionListModal}
          anchorEl={anchorRef?.current}
          className={uiState.isOpenLeftSidebar ? 'opened' : ''}
          onClose={() => uiState.setIsOpenMissionListModal(false)}
          transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        >
          <PopoverContainerStyled id="mission-board">
            <Box className="title">
              <Typography variant="subtitle2" color="grey.700">
                {missionList[currentLevel - 1].title}
              </Typography>
              <Chip
                sx={{ height: '24px', '& > span': { padding: '0 8px' } }}
                label={`Level ${missionList[currentLevel - 1].level}`}
              />
            </Box>
            <Box className="progress-bar">
              <ProgressBar
                title={t('flows.missionBoard.progressBar', {
                  finished: missionList[currentLevel - 1].completedStep,
                  total: missionList[currentLevel - 1].totalStep,
                })}
                percent={
                  (missionList[currentLevel - 1].completedStep /
                    missionList[currentLevel - 1].totalStep) *
                  100
                }
              />
            </Box>
            <Box className="divider">
              <Divider />
            </Box>
            <Box>
              {missionList[currentLevel - 1].lessonList.map((item) => (
                <MenuItemStyled
                  key={item.id}
                  id={item.gaID}
                  sx={{ padding: '10px 16px' }}
                  onClick={() => {
                    uiState.setIsTouring(false);
                    item.onClick();
                  }}
                >
                  <ListItemIcon>
                    {item.completed ? (
                      <CheckIcon sx={{ color: 'success.main' }} />
                    ) : (
                      <item.icon />
                    )}
                  </ListItemIcon>
                  <ListItemText sx={{ whiteSpace: 'pre-wrap' }}>
                    {item.title}
                  </ListItemText>
                  <Typography
                    variant="body2"
                    color="grey.700"
                    sx={{ textDecoration: 'underline' }}
                  >
                    {t('flows.missionBoard.more')}
                  </Typography>
                </MenuItemStyled>
              ))}
            </Box>
            <Box className="divider">
              <Divider />
            </Box>
            <Box sx={{ width: '100%' }}>
              <Pagination
                count={missionList.length}
                onChange={(event, page) => {
                  setCurrentLevel(page);
                }}
              />
            </Box>
          </PopoverContainerStyled>
        </Popover>
      )}
      {!isViewer && (
        <MissionButtonStyled
          ref={anchorRef}
          onClick={handleClick}
          className={uiState.isOpenLeftSidebar ? 'opened' : ''}
        >
          {uiState.isOpenMissionListModal ? (
            <MissionButtonOpenedStyled>
              <KeyboardArrowDownOutlinedIcon
                sx={{ width: '24px', height: '24px', color: 'grey.white' }}
              />
            </MissionButtonOpenedStyled>
          ) : (
            <MissionButtonClosedStyled>
              <Box className="inner-container">
                <RocketLaunchOutlinedIcon sx={{ marginRight: '8px' }} />
                {t('flows.missionBoard.toggleButton')}
              </Box>
            </MissionButtonClosedStyled>
          )}
        </MissionButtonStyled>
      )}
    </MissionBoardContainerStyled>
  );
}

export default MissionBoard;

import { queryClient } from '@frontend/sorghum/external-providers';
import {
  PatchBillingPaymentRequestType,
  PatchBillingPaymentResponseType,
  ResponseWithData,
} from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { BILLING_PAYMENT } from '../apis';

async function getPatchBillingPayment(
  props: PatchBillingPaymentRequestType,
): Promise<ResponseWithData<PatchBillingPaymentResponseType>> {
  return axios
    .patch(BILLING_PAYMENT, props)
    .then((res) => res.data)
    .catch((err) => ({
      code: 40000,
      msg: err,
    }));
}

export function usePatchBillingPayment() {
  return useMutation({
    mutationKey: ['patch-billing-payment'],
    mutationFn: (props: PatchBillingPaymentRequestType) =>
      getPatchBillingPayment(props),
    onSuccess: () => {
      queryClient.invalidateQueries(['get-billing-payment']);
    },
  });
}

export default usePatchBillingPayment;

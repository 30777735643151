import { globalTheme } from '@frontend/components/external-providers';
import { TapPayPrimeResult, TPDirectType } from '@frontend/sorghum/interface';
import { get } from 'lodash';

declare global {
  interface Window {
    TPDirect: TPDirectType;
  }
}

const TPDirect = window.TPDirect;

const setNumberFormGroupToError = (id: string) => {
  const target = window.document.getElementById(id);
  if (target) {
    target?.classList.add('has-error');
    target?.classList.remove('has-success');
  }
};

const setNumberFormGroupToSuccess = (id: string) => {
  const target = window.document.getElementById(id);
  if (target) {
    target?.classList.remove('has-error');
    target?.classList.add('has-success');
  }
};

const setNumberFormGroupToNormal = (id: string) => {
  const target = window.document.getElementById(id);
  if (target) {
    target?.classList.remove('has-error');
    target?.classList.remove('has-success');
  }
};

export const initTapPay = (
  appID: number,
  appKey: string,
  setCanGetPrime: (val: boolean) => void,
  setError: (key: 'number' | 'expiry' | 'CVC', val: boolean) => void,
) => {
  if (TPDirect) {
    // Initialize TapPay with your Merchant ID and App Key
    TPDirect.setupSDK(
      appID,
      appKey,
      process.env['NX_ENV'] === 'PROD' ? 'production' : 'sandbox',
    );

    // Setup card fields
    const fields = {
      number: {
        element: '#card-number',
        placeholder: '0000 0000 0000 0000',
      },
      expirationDate: {
        element: '#card-expiration-date',
        placeholder: 'MM / YY',
      },
      ccv: {
        element: '#card-ccv',
        placeholder: '123',
      },
    };

    TPDirect.card.setup({
      fields,
      styles: {
        input: {
          color: get(globalTheme, 'palette.grey.500', ''),
        },
        '.invalid': {
          color: get(globalTheme, 'palette.error.main', ''),
        },
      },
      isMaskCreditCardNumber: true,
      maskCreditCardNumberRange: {
        beginIndex: 6,
        endIndex: 11,
      },
    });

    TPDirect.card.onUpdate((update) => {
      // update.canGetPrime === true
      // --> you can call TPDirect.card.getPrime()

      if (update.canGetPrime) {
        setCanGetPrime(true);
      } else {
        setCanGetPrime(false);
      }

      // cardTypes = ['mastercard', 'visa', 'jcb', 'amex', 'unknown']
      if (update.cardType === 'visa') {
        // Handle card type visa.
      }
      // number 欄位是錯誤的
      if (update.status.number === 2) {
        setNumberFormGroupToError('card-number');
        setError('number', true);
      } else if (update.status.number === 0) {
        setNumberFormGroupToSuccess('card-number');
        setError('number', false);
      } else {
        setNumberFormGroupToNormal('card-number');
        setError('number', false);
      }

      // expiry 欄位是錯誤的
      if (update.status.expiry === 2) {
        setNumberFormGroupToError('card-expiration-date');
        setError('expiry', true);
      } else if (update.status.expiry === 0) {
        setNumberFormGroupToSuccess('card-expiration-date');
        setError('expiry', false);
      } else {
        setNumberFormGroupToNormal('card-expiration-date');
        setError('expiry', false);
      }

      // ccv 欄位是錯誤的
      if (update.status.ccv === 2) {
        setNumberFormGroupToError('card-ccv');
        setError('CVC', true);
      } else if (update.status.ccv === 0) {
        setNumberFormGroupToSuccess('card-ccv');
        setError('CVC', false);
      } else {
        setNumberFormGroupToNormal('card-ccv');
        setError('CVC', false);
      }
    });
  }
};

export const getPrime = (callback: (res: TapPayPrimeResult) => void) => {
  if (TPDirect) {
    // 取得 TapPay Fields 的 status
    const tappayStatus = TPDirect.card.getTappayFieldsStatus();

    // 確認是否可以 getPrime
    if (tappayStatus.canGetPrime === false) {
      alert('can not get prime');
      return;
    }

    // Get prime
    TPDirect.card.getPrime(callback);
  }
};

import {
  Box,
  Link,
  Breadcrumbs as MiuBreadcrumbs,
  BreadcrumbsProps as MuiBreadcrumbsProps,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { FC } from 'react';

import { BreadcrumbsItem } from '@frontend/components/interface';
import { MaterialStyledProps } from '@frontend/editor/interface';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export interface BreadcrumbsProps extends MuiBreadcrumbsProps {
  history: BreadcrumbsItem[];
  now: BreadcrumbsItem;
}

export interface BreadcrumbItemStyledProps extends MaterialStyledProps {
  $disabled: boolean;
}

const ContainerWrapper = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  '.MuiBreadcrumbs-separator': {
    color: theme.palette?.['grey'][400],
  },
}));

const BreadcrumbItemStyled = styled(Box)<BreadcrumbItemStyledProps>(
  ({ theme, $disabled }) => ({
    ...($disabled
      ? {
          cursor: 'not-allowed',
          '.breadcrumb-text': {
            'pointer-events': 'none',
          },
          '&:hover': {
            textDecoration: 'none',
          },
        }
      : {
          p: {
            '&:active': {
              color: theme.palette?.['grey'][900],
              textDecorationColor: theme.palette?.['grey'][900],
            },
          },
          '&:hover': {
            textDecoration: 'underline',
            textDecorationColor: theme.palette?.['grey'][500],
          },
        }),
  }),
);

const LastBreadcrumbItemStyled = styled(Box)(({ theme }) => ({
  cursor: 'not-allowed',
  color: theme.palette?.['grey'][900],
}));

export const Breadcrumbs: FC<BreadcrumbsProps> = ({
  history,
  now,
}: BreadcrumbsProps) => {
  return (
    <ContainerWrapper>
      <MiuBreadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        {history.map((h) => {
          return (
            <BreadcrumbItemStyled
              id={h.id}
              key={`Breadcrumbs_${h.id}`}
              $disabled={!h.onClick}
            >
              <Link
                key={h.id}
                underline="none"
                color="inherit"
                href={h.href}
                onClick={h.onClick && h.onClick}
              >
                <Typography
                  variant="body2"
                  color="grey.500"
                  sx={{ textTransform: 'none' }}
                >
                  {h.label}
                </Typography>
              </Link>
            </BreadcrumbItemStyled>
          );
        })}
        <LastBreadcrumbItemStyled>
          <Typography
            className="breadcrumb-text"
            variant="body2"
            color="grey.900"
            sx={{ textTransform: 'none' }}
          >
            {now.label}
          </Typography>
        </LastBreadcrumbItemStyled>
      </MiuBreadcrumbs>
    </ContainerWrapper>
  );
};

export default Breadcrumbs;

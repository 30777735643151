import { GetBillingHistoryType } from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { BILLING_HISTORY } from '../apis';

const defaultData = [] as GetBillingHistoryType[];

async function getGetBillingHistory(): Promise<GetBillingHistoryType[]> {
  return axios
    .get(BILLING_HISTORY)
    .then((res) => res.data.data)
    .catch((err) => {
      return defaultData;
    });
}

export function useGetBillingHistory() {
  return useQuery<GetBillingHistoryType[]>({
    queryKey: ['get-billing-history'],
    queryFn: getGetBillingHistory,
  });
}

export default useGetBillingHistory;

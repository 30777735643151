import { Dialog, Typography } from '@frontend/components/ui';
import { Alert } from '@frontend/components/utils';
import { useGetProjectID } from '@frontend/editor/data-access';
import { usePatchBillingPayment } from '@frontend/sorghum/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import {
  ModalTypesEnum,
  PatchBillingPaymentResponseType,
  ResponseWithData,
  TapPayPrimeResult,
} from '@frontend/sorghum/interface';
import { getPrime, usePath } from '@frontend/sorghum/utils';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TapPayForm, { TapPayFormValue } from '../tap-pay-form/tap-pay-form';

export interface EditTapPayModalProps {
  tapPayAppID: number;
  tapPayAppKey: string;
}

export const EditTapPayModal: FC<EditTapPayModalProps> = ({
  tapPayAppID,
  tapPayAppKey,
}) => {
  const uiState = useContext(UICtx);
  const [t] = useTranslation();
  const { navigate } = usePath();
  const { data: projectID } = useGetProjectID();
  const [paymentData, setPaymentData] = useState<TapPayFormValue>({
    name: '',
    email: '',
    phoneNumber: '',
  });
  const [paymentDataCompleted, setPaymentDataCompleted] =
    useState<boolean>(false);

  const { mutate: updatePayment } = usePatchBillingPayment();

  const handlePaymentDialogConfirm = useCallback(() => {
    getPrime((result: TapPayPrimeResult) => {
      if (result.status !== 0 || !result.card?.prime) {
        alert('get prime error ' + result.msg);
        return;
      }

      uiState.openModal(ModalTypesEnum.TAP_PAY_LOADING);
      updatePayment(
        {
          paymentDetail: {
            method: 1,
            prime: result.card.prime,
            name: paymentData.name,
            email: paymentData.email,
            phone: paymentData.phoneNumber,
          },
        },
        {
          onSuccess: (
            res: ResponseWithData<PatchBillingPaymentResponseType>,
          ) => {
            if (res.code === 20000) {
              window.open(res.data.paymentUrl, '_self');
            } else {
              uiState.closeModal();
              Alert.error(res.msg);
            }
          },
        },
      );
    });
  }, [
    paymentData.email,
    paymentData.name,
    paymentData.phoneNumber,
    uiState,
    updatePayment,
  ]);

  useEffect(() => {
    if (uiState.modalType === ModalTypesEnum.CHANGE_PAYMENT_METHOD) {
      setPaymentDataCompleted(false);
      setPaymentData({ name: '', email: '', phoneNumber: '' });
    }
  }, [uiState.modalType]);

  return (
    <Dialog
      size="s"
      title={t('flows.modal.changePayment.title')}
      open={uiState.modalType === ModalTypesEnum.CHANGE_PAYMENT_METHOD}
      cancelBtnText={t('common.cancel')}
      confirmBtnText={t('common.continue')}
      handleClose={() => uiState.closeModal()}
      handleConfirm={handlePaymentDialogConfirm}
      disableConfirmButton={!paymentDataCompleted}
    >
      <Typography variant="body1" color="grey.800">
        {t('flows.modal.changePayment.description')}
      </Typography>
      <br />
      <br />
      <TapPayForm
        appID={tapPayAppID}
        appKey={tapPayAppKey}
        value={paymentData}
        onChange={setPaymentData}
        onValidate={setPaymentDataCompleted}
      />
    </Dialog>
  );
};

export default EditTapPayModal;

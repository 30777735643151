export enum GetBillingPlanEnum {
  TRIAL = 1,
  ADVANCE = 2,
  EXPIRY = 3,
}
export interface GetBillingResponseType {
  plan: GetBillingPlanEnum;
  startedAt?: string;
  endedAt?: string;
  period?: number;
  amount?: number;
  subscribed?: boolean;
  cancelledAt?: string;
}

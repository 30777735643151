import { Divider, Typography } from '@frontend/components/ui';
import { useGetProjectID } from '@frontend/editor/data-access';
import { useGetBilling } from '@frontend/sorghum/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import {
  GetBillingPlanEnum,
  PaymentOrderType,
} from '@frontend/sorghum/interface';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { Box, Link } from '@mui/material';
import { styled } from '@mui/system';
import { isEmpty } from 'lodash';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

const OrderContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px 20px',
  backgroundColor: `${theme.palette['bluegrey']['500']}0D`,
  borderRadius: '8px',
  gap: '12px',
}));

const TitleContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  paddingBottom: '12px',
}));

const CardNumberContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '12px',
  borderRadius: '8px',
  backgroundColor: theme.palette['grey']['white'],
}));

const AttributeContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: '8px',

  '.title': {
    fontWeight: '700',
    marginRight: '8px',
    textWrap: 'nowrap',
  },
}));

const CarrierInfo = ({
  loveCode,
  carrierType,
  carrierNumber,
}: {
  loveCode?: string;
  carrierType: number;
  carrierNumber?: string;
}) => {
  const [t] = useTranslation();

  const convertCarrierType = (type: number) => {
    switch (type) {
      case 1:
        return t('setting.paymentDetails.invoiceDetails.mobileBarcode');
      case 2:
        return t('setting.paymentDetails.invoiceDetails.citizenCode');
      case 3:
        return t('setting.paymentDetails.invoiceDetails.eInvoice');
      default:
        return '';
    }
  };

  if (loveCode) {
    return (
      <>
        <AttributeContainerStyled>
          <Typography variant="body2" color="grey.800" className="title">
            {`${t('setting.paymentDetails.invoiceDetails.carrierType')}:`}
          </Typography>
          <Typography variant="body2" color="grey.800">
            {t('setting.paymentDetails.invoiceDetails.donationInvoice')}
          </Typography>
        </AttributeContainerStyled>
        <AttributeContainerStyled>
          <Typography variant="body2" color="grey.800" className="title">
            {`${t('setting.paymentDetails.invoiceDetails.charityNumber')}:`}
          </Typography>
          <Typography variant="body2" color="grey.800">
            {loveCode}
          </Typography>
        </AttributeContainerStyled>
      </>
    );
  }
  return (
    <>
      {!isEmpty(carrierType) && (
        <AttributeContainerStyled>
          <Typography variant="body2" color="grey.800" className="title">
            {`${t('setting.paymentDetails.invoiceDetails.carrierType')}:`}
          </Typography>
          <Typography variant="body2" color="grey.800">
            {convertCarrierType(carrierType)}
          </Typography>
        </AttributeContainerStyled>
      )}
      {!isEmpty(carrierType) && carrierType !== 3 && carrierNumber && (
        <AttributeContainerStyled>
          <Typography variant="body2" color="grey.800" className="title">
            {`${convertCarrierType(carrierType)}:`}
          </Typography>
          <Typography variant="body2" color="grey.800">
            {carrierNumber}
          </Typography>
        </AttributeContainerStyled>
      )}
    </>
  );
};

export function PaymentOrder({
  isEditable,
  setIsOpenEditInvoiceModal,
  paymentData,
}: PaymentOrderType) {
  const uiState = useContext(UICtx);
  const [t] = useTranslation();
  const { data: projectID } = useGetProjectID();
  const { data: billing } = useGetBilling();

  return (
    <OrderContainerStyled>
      <TitleContainerStyled>
        <Typography variant="subtitle2" color="grey.800">
          {t('setting.paymentDetails.invoiceDetails.cardInformation')}
        </Typography>
        {isEditable && (
          <Link
            sx={{ color: 'grey.800' }}
            onClick={() => uiState.openChangePaymentMethodModal()}
          >
            <Typography variant="overline" sx={{ textTransform: 'capitalize' }}>
              {t('setting.paymentDetails.invoiceDetails.edit')}
            </Typography>
          </Link>
        )}
      </TitleContainerStyled>
      <CardNumberContainerStyled>
        <Box
          sx={{
            marginRight: '16px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <CreditCardIcon sx={{ marginRight: '8px' }} />
          <Typography variant="body2" color="grey.800" sx={{ fontWeight: 700 }}>
            {t('setting.paymentDetails.invoiceDetails.card')}
          </Typography>
        </Box>
        <Typography variant="body2" color="grey.800">
          {paymentData.last4CardNum
            ? `**** **** **** ${paymentData.last4CardNum}`
            : '-'}
        </Typography>
      </CardNumberContainerStyled>
      {/* 不在 payment detail 編輯模式 and 升級專案 query 才有會 projectID and 仍在訂閱方案期間 and 取消訂閱 */}
      {!isEditable &&
        projectID &&
        billing?.plan === GetBillingPlanEnum.ADVANCE &&
        !billing?.subscribed && (
          <Typography variant="caption" color="grey.600">
            {t('setting.paymentDetails.invoiceDetails.newCardInfo')}
          </Typography>
        )}
      <Divider sx={{ margin: '12px 0' }} />
      <Box>
        <TitleContainerStyled>
          <Typography variant="subtitle2" color="grey.800">
            {t('setting.paymentDetails.invoiceDetails.invoice')}
          </Typography>
          {isEditable && setIsOpenEditInvoiceModal && (
            <Link
              sx={{ color: 'grey.800' }}
              onClick={() => setIsOpenEditInvoiceModal(true)}
            >
              <Typography
                variant="overline"
                sx={{ textTransform: 'capitalize' }}
              >
                {t('setting.paymentDetails.invoiceDetails.edit')}
              </Typography>
            </Link>
          )}
        </TitleContainerStyled>
        <AttributeContainerStyled>
          <Typography variant="body2" color="grey.800" className="title">
            {`${
              paymentData.type === 1
                ? t('setting.paymentDetails.invoiceDetails.name')
                : t('setting.paymentDetails.invoiceDetails.companyName')
            }:`}
          </Typography>
          <Typography variant="body2" color="grey.800">
            {paymentData.name ?? '-'}
          </Typography>
        </AttributeContainerStyled>
        <AttributeContainerStyled>
          <Typography variant="body2" color="grey.800" className="title">
            {`${t('setting.paymentDetails.invoiceDetails.country')}:`}
          </Typography>
          <Typography variant="body2" color="grey.800">
            {paymentData.country ?? '-'}
          </Typography>
        </AttributeContainerStyled>
        <AttributeContainerStyled>
          <Typography variant="body2" color="grey.800" className="title">
            {`${
              paymentData.type === 1
                ? t('setting.paymentDetails.invoiceDetails.address')
                : t('setting.paymentDetails.invoiceDetails.companyAddress')
            }:`}
          </Typography>
          <Typography variant="body2" color="grey.800">
            {paymentData.address ?? '-'}
          </Typography>
        </AttributeContainerStyled>
        <AttributeContainerStyled>
          <Typography variant="body2" color="grey.800" className="title">
            {`${
              paymentData.type === 1
                ? t('setting.paymentDetails.invoiceDetails.zip')
                : t('setting.paymentDetails.invoiceDetails.companyZip')
            }:`}
          </Typography>
          <Typography variant="body2" color="grey.800">
            {paymentData.zip ?? '-'}
          </Typography>
        </AttributeContainerStyled>
        {paymentData.type === 1 && (
          <CarrierInfo
            loveCode={paymentData?.loveCode}
            carrierType={paymentData?.carrierType ?? 0}
            carrierNumber={paymentData?.carrierNumber}
          />
        )}
        {paymentData.type === 2 && paymentData.tax && (
          <AttributeContainerStyled>
            <Typography variant="body2" color="grey.800" className="title">
              {`${t('setting.paymentDetails.invoiceDetails.tax')}:`}
            </Typography>
            <Typography variant="body2" color="grey.800">
              {paymentData.tax ?? '-'}
            </Typography>
          </AttributeContainerStyled>
        )}
      </Box>
    </OrderContainerStyled>
  );
}

export default PaymentOrder;

import { dayjs } from '../dayjs/index';

interface DayProps {
  endDate: dayjs.Dayjs;
}

export const calculateRemainDays = ({ endDate }: DayProps): number => {
  const DateNow = dayjs();

  const remainDays = endDate.diff(DateNow, 'days');
  return remainDays;
};

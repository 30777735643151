import { useCanvasView } from '@frontend/editor/data-access';
import { EditorCtx } from '@frontend/editor/external-providers';
import { Box, BoxProps, styled } from '@mui/material';
import { useContext, useMemo } from 'react';

interface EditorBlockBodyProps extends BoxProps {
  nodeID: string;
  color: string;
  selected?: boolean;
  zoom?: number;
}

const EditorBlockBorderStyled = styled(Box)<{
  $focus: boolean;
  $color: string;
  $disabled?: boolean;
  $zoom?: number;
}>(({ $focus, $color, $zoom, $disabled }) => ({
  background: '#ffffff',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '11px',
  cursor: 'move',
  fontSize: `${$zoom ? 28 / $zoom : 28}px`,
  boxShadow:
    '0px 3px 6px rgba(69, 90, 100, 0.15), 0px 2px 3px rgba(69, 90, 100, 0.12)',
  ...($focus && { border: `2px solid ${$color}` }),
  '&:hover': {
    border: `2px solid  ${$color}`,
  },
  ...($disabled && {
    pointerEvents: 'none',
  }),
}));

export const EditorBlockBorder = ({
  children,
  nodeID,
  selected,
  color,
  zoom,
  ...props
}: EditorBlockBodyProps) => {
  const { tourMode } = useContext(EditorCtx);
  const { onFocusID } = useCanvasView();

  const isFocusOrSelected = useMemo(
    () => (selected || onFocusID === nodeID) && !tourMode,
    [nodeID, onFocusID, selected, tourMode],
  );

  return (
    <EditorBlockBorderStyled
      $focus={isFocusOrSelected}
      $color={color}
      $zoom={zoom}
      $disabled={tourMode}
      {...props}
    >
      {children}
    </EditorBlockBorderStyled>
  );
};

export default EditorBlockBorder;

import { Alert } from '@frontend/components/utils';
import {
  useGetProjectID,
  useProjectPermission,
} from '@frontend/editor/data-access';
import {
  useGetEditorDefaultAnswer,
  useGetFlow,
  usePatchEditorDefaultAnswer,
  usePostEditorDefaultAnswer,
} from '@frontend/sorghum/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import {
  DefaultAnswerForm,
  ModalTypesEnum,
  ResponseWithData,
  UsePageDefaultAnswerReturn,
} from '@frontend/sorghum/interface';
import {
  DEFAULT_ANSWER,
  MAIN,
  PAGE_DEFAULT_ANSWER,
  PAGE_FLOW,
  useDebouncedCallback,
  useDocumentTitle,
  usePath,
} from '@frontend/sorghum/utils';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const usePage = () => {
  const [tabValue, setTabValue] = useState(0);
  const [socialType, setSocialType] = useState<1 | 2>(1);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [formData, setFormData] = useState<DefaultAnswerForm>({
    flowID: '',
    frequency: '',
    enable: false,
  });
  const uiState = useContext(UICtx);
  const [t] = useTranslation();

  const { navigateToProjectPage } = usePath();
  const { data: projectID } = useGetProjectID();
  const { data: defaultAnswer } = useGetEditorDefaultAnswer({
    projectID: projectID as string,
    socialType,
  });

  const { data: selectedFlow } = useGetFlow(
    formData?.flowID as string,
    projectID as string,
    false,
  );

  const { mutate: saveDefaultAnswer, isLoading: isSaving } =
    usePatchEditorDefaultAnswer(projectID as string, socialType);

  const { isViewer } = useProjectPermission();

  const onPublishDataSuccess = useCallback(
    (data: ResponseWithData<{ link: string }>) => {
      switch (data.code) {
        case 20000: {
          Alert.success(
            t('basicSetting.welcomeMessage.alert.success', {
              publishStatus:
                defaultAnswer?.status === 1
                  ? t('common.publish')
                  : t('common.update'),
            }),
          );
          break;
        }
        // 沒有發布的 flow 會顯示提示 modal
        case 40000: {
          uiState.handleSetID(formData.flowID as string);
          uiState.handleSetName(selectedFlow?.name as string);
          uiState.openModal(ModalTypesEnum.DEFAULT_ANSWER_FLOW_UNPUBLISH);
          break;
        }
        case 40002: {
          // TODO disconnect fan page, open pop-up
          break;
        }
        default: {
          Alert.error(
            t('basicSetting.welcomeMessage.alert.fail', {
              publishStatus:
                defaultAnswer?.status === 1
                  ? t('common.publish')
                  : t('common.update'),
            }),
          );
          break;
        }
      }
    },
    [defaultAnswer?.status, formData.flowID, selectedFlow?.name, t, uiState],
  );

  const { mutate: publishDefaultAnswer } = usePostEditorDefaultAnswer(
    projectID as string,
    socialType,
    { onSuccess: onPublishDataSuccess },
  );

  const onSave = useCallback(
    (val: DefaultAnswerForm) => {
      if (isViewer) return;

      saveDefaultAnswer({
        startFlowId: val.flowID || '',
        frequency: parseInt(val.frequency),
        enable: val.enable,
      });
      setIsUpdated(false);
    },
    [saveDefaultAnswer, isViewer],
  );

  const debounceSave = useDebouncedCallback(
    (val: DefaultAnswerForm) => onSave(val),
    1000,
  );

  const handleSetFormData = useCallback(
    (val: DefaultAnswerForm) => {
      if (defaultAnswer && isLoaded) {
        setFormData(val);
        setIsUpdated(true);
        debounceSave(val);
      }
    },
    [defaultAnswer, isLoaded, debounceSave],
  );

  const setDefaultAnswer = useCallback(
    (data: DefaultAnswerForm) => {
      handleSetFormData(data);
    },
    [handleSetFormData],
  );

  const checkUpdated = useCallback(
    (callback: () => void) => {
      if (isViewer) {
        callback();
        return;
      }
      if (uiState.isUpdated) {
        uiState.openNavigateModal(callback);
        uiState.setIsUpdated(false);
      } else {
        callback();
      }
    },
    [isViewer, uiState],
  );

  const breadcrumbItems = [
    {
      id: MAIN,
      label: t('common.breadcrumbs.project'),
      // href: PAGE_MAIN,
      onClick: () => checkUpdated(() => navigateToProjectPage()),
    },
  ];

  const breadcrumbNow = {
    id: DEFAULT_ANSWER,
    label: t('common.breadcrumbs.defaultAnswer'),
    // href: PAGE_DEFAULT_ANSWER,
    onClick: () => navigateToProjectPage(PAGE_DEFAULT_ANSWER),
  };

  const onUpdateFlowID = useCallback(
    (data: { flowId: string; name: string }) => {
      if (formData) {
        saveDefaultAnswer({
          ...formData,
          startFlowId: data.flowId,
          frequency: parseInt(formData.frequency),
        });
      }
    },
    [formData, saveDefaultAnswer],
  );

  const onPublish = useCallback(() => {
    if (formData) {
      if (!formData.flowID && formData.enable) {
        Alert.error(t('basicSetting.defaultAnswer.alert.noFlow'));
      } else if (formData.enable && selectedFlow?.publishStatus === 1) {
        if (defaultAnswer?.status === 1) {
          uiState.openModal(ModalTypesEnum.DEFAULT_ANSWER_FLOW_UNPUBLISH);
        } else {
          Alert.error(t('basicSetting.welcomeMessage.alert.flowUnpublished'));
        }
      } else {
        publishDefaultAnswer({
          startFlowId: formData.flowID as string,
          frequency: parseInt(formData.frequency),
          enable: formData.enable,
        });
      }
    } else {
      Alert.error(t('basicSetting.defaultAnswer.alert.noFlow'));
    }
  }, [
    defaultAnswer?.status,
    formData,
    publishDefaultAnswer,
    selectedFlow?.publishStatus,
    t,
    uiState,
  ]);

  const setDefaultAnswerStatus = useCallback(
    (newStatus: boolean) => {
      handleSetFormData({
        ...formData,
        enable: newStatus,
      });
    },
    [formData, handleSetFormData],
  );

  const goToFlow = useCallback(() => {
    navigateToProjectPage(`${PAGE_FLOW}/${uiState.flowID}`);
    uiState.closeModal();
  }, [navigateToProjectPage, uiState]);

  useEffect(() => {
    if (projectID && defaultAnswer && !isLoaded) {
      setIsLoaded(true);
      setIsUpdated(false);
      setFormData({
        flowID: defaultAnswer.startFlow?.id
          ? defaultAnswer.startFlow.id
          : undefined,
        frequency: defaultAnswer.frequency.toString(),
        enable: defaultAnswer.enable,
      });
    }
  }, [defaultAnswer, isLoaded, projectID]);

  useEffect(() => {
    if (tabValue === 0) {
      setSocialType(1);
    } else {
      setSocialType(2);
    }
  }, [tabValue]);

  useDocumentTitle(t('title.defaultAnswer'));

  return {
    isSaving: isSaving || isUpdated,
    publishStatus: defaultAnswer?.status,
    defaultAnswer: formData,
    breadcrumbItems,
    breadcrumbNow,
    tabValue,
    onlineFlow: defaultAnswer?.onlineFlow?.id,
    flowName: defaultAnswer?.startFlow?.name,
    isAlertModalOpen:
      uiState.modalType === ModalTypesEnum.DEFAULT_ANSWER_FLOW_UNPUBLISH,
    isLoaded,
    setTabValue,
    setDefaultAnswerStatus,
    onPublish,
    setDefaultAnswer,
    onUpdateFlowID,
    closeModal: uiState.closeModal,
    goToFlow,
  } as UsePageDefaultAnswerReturn;
};

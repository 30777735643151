import { Loading } from '@frontend/components/ui';
import { UICtx } from '@frontend/sorghum/external-providers';
import { LoadingModalTypeEnum } from '@frontend/sorghum/interface';
import { styled } from '@mui/system';
import { get } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import { Box } from '../box/box';

// mask 透明遮罩 / block 非透明遮罩
const ContainerStyled = styled(Box)<{ $type: LoadingModalTypeEnum }>(
  ({ theme, $type }) => ({
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    backgroundColor:
      $type === LoadingModalTypeEnum.MASK
        ? 'rgba(0, 0, 0, 0.54)'
        : get(theme, 'palette.grey.400'),
    zIndex: '1000',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
);

export const LoadingModal: FC = () => {
  const UIState = useContext(UICtx);

  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if (UIState.loadingList.length > 0) {
      setIsOpen(true);
    }
  }, [UIState.loadingList.length]);

  // 如果關閉的時間過短（ex. API fetching 速度很快），讓 loading 頁顯示多延長一秒再關閉來避免畫面的閃挫
  useEffect(() => {
    if (isOpen && UIState.loadingList.length === 0) {
      const time = setTimeout(() => {
        setIsOpen(false);
      }, 500);

      return () => clearTimeout(time);
    }
    return () => {
      /* do nothing */
    };
  }, [UIState.loadingList.length, isOpen]);

  if (!isOpen) return null;

  return (
    <ContainerStyled $type={UIState.loadingModalType}>
      <Loading />
    </ContainerStyled>
  );
};

export default LoadingModal;

import { Button } from '@frontend/components/ui';
import { resortList } from '@frontend/components/utils';
import { EditorFloatPanel } from '@frontend/editor/ui';
import {
  TextButtonCellErrorEnum,
  TextButtonCellType,
} from '@frontend/sorghum/interface';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableElementProps,
} from 'react-sortable-hoc';
import CellButton from '../cell-button/cell-button';

interface CellButtonListProps {
  list: TextButtonCellType[];
  disabled?: boolean;
  updateCellData: (
    elementID: string,
    id: string,
    data: TextButtonCellType,
  ) => void;
  errorList: {
    id: string;
    type: TextButtonCellErrorEnum;
    errorText?: string;
  }[];
  deleteCell: (elementID: string, cellID: string) => void;
  addCell: (elementID: string) => void;
  updateCellListData: (elementID: string, data: TextButtonCellType[]) => void;
  elementID: string;
  setErrorList: (
    list: {
      id: string;
      type: TextButtonCellErrorEnum;
      errorText?: string;
    }[],
  ) => void;
  isDisableAddButton?: boolean;
}

interface SortableItemType {
  id: string;
  index: number;
  cellData: TextButtonCellType;
  isHoverCell: string;
  setIsHoverCell: (id: string) => void;
  isDraggable: boolean;
  updateCellData: (
    elementID: string,
    id: string,
    data: TextButtonCellType,
  ) => void;
  errorList: {
    id: string;
    type: TextButtonCellErrorEnum;
    errorText?: string;
  }[];
  deleteCell: (elementID: string, cellID: string) => void;
  elementID: string;
  setErrorList: (
    list: {
      id: string;
      type: TextButtonCellErrorEnum;
      errorText?: string;
    }[],
  ) => void;
}

interface SortableListType {
  list: TextButtonCellType[];
  updateCellData: (
    elementID: string,
    id: string,
    data: TextButtonCellType,
  ) => void;
  errorList: {
    id: string;
    type: TextButtonCellErrorEnum;
    errorText?: string;
  }[];
  deleteCell: (elementID: string, cellID: string) => void;
  elementID: string;
  setErrorList: (
    list: {
      id: string;
      type: TextButtonCellErrorEnum;
      errorText?: string;
    }[],
  ) => void;
}

const SortableItemStyled = styled(Box)(({ theme }) => ({
  position: 'relative',
  paddingBottom: '8px',
  width: '100%',
  fontSize: '28px',
}));

const SortableItem: React.ComponentClass<
  SortableElementProps & SortableItemType
> = SortableElement(
  ({
    id,
    index,
    cellData,
    isHoverCell,
    setIsHoverCell,
    isDraggable,
    updateCellData,
    errorList,
    deleteCell,
    elementID,
    setErrorList,
  }: SortableItemType) => {
    return (
      <SortableItemStyled
        onMouseEnter={() => setIsHoverCell(id)}
        onMouseLeave={() => setIsHoverCell('')}
      >
        {isHoverCell === id && (
          <EditorFloatPanel
            draggable={isDraggable}
            direction="column"
            handleDelete={() => deleteCell(elementID, id)}
          />
        )}
        <CellButton
          id={id}
          cellData={cellData}
          isHoverCell={isHoverCell}
          updateCellData={updateCellData}
          errorList={errorList}
          elementID={elementID}
          setErrorList={setErrorList}
        />
      </SortableItemStyled>
    );
  },
);

const SortableList: React.ComponentClass<
  SortableContainerProps & SortableListType
> = SortableContainer(
  ({
    list,
    updateCellData,
    errorList,
    deleteCell,
    elementID,
    setErrorList,
  }: SortableListType) => {
    const [isHoverCell, setIsHoverCell] = useState<string>('');
    return (
      <Box>
        {list.map((item, index) => (
          <SortableItem
            key={item.cellId}
            id={item.cellId}
            index={index}
            cellData={item}
            setIsHoverCell={setIsHoverCell}
            isHoverCell={isHoverCell}
            isDraggable={list.length > 1}
            updateCellData={updateCellData}
            errorList={errorList}
            deleteCell={deleteCell}
            elementID={elementID}
            setErrorList={setErrorList}
          />
        ))}
      </Box>
    );
  },
);

export function CellButtonList({
  list,
  disabled = false,
  updateCellData,
  errorList,
  deleteCell,
  addCell,
  updateCellListData,
  elementID,
  setErrorList,
  isDisableAddButton,
}: CellButtonListProps) {
  const [t] = useTranslation();

  const onCellSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    const newArray = resortList(list, oldIndex, newIndex);
    updateCellListData(elementID, newArray);
  };

  return (
    <>
      {disabled ? (
        <>
          {list.map((item) => (
            <CellButton
              key={item.cellId}
              id={item.cellId}
              cellData={item}
              isHoverCell=""
              updateCellData={updateCellData}
              errorList={errorList}
              elementID={elementID}
              setErrorList={setErrorList}
            />
          ))}
        </>
      ) : (
        <SortableList
          useDragHandle
          lockAxis="y"
          list={list}
          onSortEnd={onCellSortEnd}
          updateCellData={updateCellData}
          errorList={errorList}
          deleteCell={deleteCell}
          elementID={elementID}
          setErrorList={setErrorList}
        />
      )}
      {list.length < 3 && !disabled && (
        <Button
          variant="text"
          color="inherit"
          sx={{
            background: 'rgba(255, 255, 255, 0.75)',
            marginTop: '12px',
          }}
          onClick={() => addCell(elementID)}
          disabled={disabled || isDisableAddButton}
        >
          {t('broadcast.draft.addButton')}
        </Button>
      )}
    </>
  );
}

export default CellButtonList;

import { EditorCtx } from '@frontend/editor/external-providers';
import {
  BaseElement,
  ModalTypesEnum,
  NodeTypesEnum,
} from '@frontend/editor/interface';
import { uuid } from '@frontend/editor/utils';
import { sendGAEvent } from '@frontend/sorghum/utils';
import { isNull } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  Connection,
  Edge,
  EdgeChange,
  EdgeSelectionChange,
  MarkerType,
  Node,
  NodeChange,
  NodeSelectionChange,
  OnConnectStartParams,
  OnSelectionChangeParams,
  applyEdgeChanges,
  applyNodeChanges,
  getNodesBounds,
  updateEdge,
  useReactFlow,
  useStoreApi,
  useViewport,
} from 'reactflow';
import useModal from '../use-modal/use-modal';

export interface UseCanvasFlow {
  nodes: Node[];
  edges: Edge[];
  zoom: number;
  targetElement?: BaseElement;
  isConnecting: boolean;
  onConnectEnd: (event: MouseEvent | TouchEvent, allowAdd?: boolean) => void;
  onEdgesChange: (edgeChanges: EdgeChange[]) => void;
  onNodesChange: (nodeChanges: NodeChange[]) => void;
  handleNodesDragEnd: (nodes: Node[]) => void;
  handleNodesDelete: (deleteNodes: Node[]) => void;
  handleEdgeDelete: (deleteEdges: Edge[]) => void;
  handleEdgeUpdate: (oldEdge: Edge, newConnection: Connection) => void;
  handleOnSelectionContextMenu: (
    e: React.MouseEvent<Element, MouseEvent>,
    nodes: Node[],
  ) => void;
  handleOnNodeContextMenu: (
    e: React.MouseEvent<Element, MouseEvent>,
    nodes: Node,
  ) => void;
  handleSelectionChange: (
    onSelectionChangeParams: OnSelectionChangeParams,
  ) => void;
  onConnectStart: (
    event: React.MouseEvent<Element, MouseEvent> | React.TouchEvent<Element>,
    { nodeId }: OnConnectStartParams,
  ) => void;
  getSelectedNodeBound: () => {
    x: number;
    y: number;
  };
  setNodesSelected: (idList: string[]) => void;
}

const MENU_WIDTH = 314;
const MENU_HEIGHT = 400;
const CANVAS_PADDING = 24;

export function useCanvasFlow(): UseCanvasFlow {
  const state = useContext(EditorCtx);
  const store = useStoreApi();
  const { addSelectedNodes } = store.getState();
  const { openContextMenu } = useModal();
  const [activeNode, setActiveNode] = useState<OnConnectStartParams>();
  const { project, setViewport, flowToScreenPosition } = useReactFlow();
  const [isConnecting, setIsConnecting] = useState(false);
  const [flowContainerCoordinate, setFlowContainerCoordinate] = useState({
    x: 0,
    y: 0,
  });

  const {
    nodes,
    edges,
    targetElementID,
    setNodes,
    setEdges,
    onEdgesChange,
    onNodesChange,
    getElement,
  } = state;
  const { zoom } = useViewport();

  const handleSelectionChange = useCallback(
    ({ nodes, edges }: OnSelectionChangeParams) => {
      const nodeChanges: NodeSelectionChange[] = nodes.map((node: Node) => ({
        id: node.id,
        type: 'select',
        selected: node.selected === true,
      }));
      const edgeChanges: EdgeSelectionChange[] = edges.map((edge: Edge) => ({
        id: edge.id,
        type: 'select',
        selected: edge.selected === true,
      }));
      if (nodeChanges.length > 0) {
        setNodes((oldNodes) => applyNodeChanges(nodeChanges, oldNodes));
      }
      if (edgeChanges.length > 0) {
        setEdges((oldEdges) => applyEdgeChanges(edgeChanges, oldEdges));
      }
    },
    [setEdges, setNodes],
  );

  const handleOnSelectionContextMenu = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>, nodes: Node[]) => {
      e.preventDefault();
      if (nodes.length <= 0) return;
      if (
        nodes.length === 1 &&
        nodes[0].type === NodeTypesEnum.ENTRY_POINT_NODE
      )
        return;
      openContextMenu(e, ModalTypesEnum.SELECTION_CONTEXT_MENU);
    },
    [openContextMenu],
  );

  const handleOnNodeContextMenu = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>, node: Node) => {
      e.preventDefault();
      if (
        node.type === NodeTypesEnum.ENTRY_POINT_NODE ||
        node.type === NodeTypesEnum.CREATE_MENU_NODE
      )
        return;
      openContextMenu(e, ModalTypesEnum.NODE_CONTEXT_MENU, node);
    },
    [openContextMenu],
  );

  const onConnectStart = useCallback(
    (
      e: React.MouseEvent<Element, MouseEvent> | React.TouchEvent<Element>,
      props: OnConnectStartParams,
    ) => {
      setNodes((nodes) =>
        nodes.filter((node) => node.type !== NodeTypesEnum.CREATE_MENU_NODE),
      );
      setIsConnecting(true);
      setActiveNode(props);
    },
    [setNodes],
  );

  const onConnectEnd = useCallback(
    (event: MouseEvent | TouchEvent, allowAdd = true) => {
      const mouseEvent = event as MouseEvent;
      const target = event.target as HTMLElement;
      setIsConnecting(false);
      if (target.classList.contains('react-flow__pane') && allowAdd) {
        if (state.ref && state.ref.current) {
          const { width, height, top, left } =
            state.ref.current.getBoundingClientRect();
          const id = uuid();

          // 314 和 400 分別為 menu 選單的寬度和高度，24 為畫布預留的 padding
          let positionX = mouseEvent.clientX;
          let positionY = mouseEvent.clientY;

          if (mouseEvent.clientX < CANVAS_PADDING) {
            positionX += CANVAS_PADDING;
          } else if (mouseEvent.clientX > width - MENU_WIDTH) {
            positionX = width - MENU_WIDTH - CANVAS_PADDING;
          }

          if (mouseEvent.clientY < CANVAS_PADDING) {
            positionY += CANVAS_PADDING;
          } else if (mouseEvent.clientY > height - MENU_HEIGHT) {
            positionY = height - MENU_HEIGHT - CANVAS_PADDING;
          }

          // menu of create block
          const newNode = {
            id,
            position: project({
              x: positionX - left,
              y: positionY - top,
            }),
            data: { sourceOutputID: activeNode?.handleId as string },
            type: NodeTypesEnum.CREATE_MENU_NODE,
            // 比 focus node 的層級高一點
            zIndex: 21,
          } as Node;

          setNodes((nds) => nds.concat(newNode));
          setEdges((eds) =>
            eds.concat({
              id,
              source: activeNode?.nodeId as string,
              sourceHandle: activeNode?.handleId,
              target: id,
              updatable: 'target',
              markerEnd: {
                type: MarkerType.ArrowClosed,
              },
            }),
          );

          // 判斷是從 block 還是 cell 呼叫出選單
          if (activeNode?.handleId) {
            if (
              state.getElementByOutputID(activeNode.handleId)?.elementType ===
              'BLOCK'
            ) {
              sendGAEvent(
                'Chat Flow Edit',
                'Block connector',
                'Chat Flow Edit - connector- block - click',
                '',
              );
            } else {
              sendGAEvent(
                'Chat Flow Edit',
                'Cell connector',
                'Chat Flow Edit - connector- cell - drag',
                '',
              );
            }
          }
        }
      } else {
        // 尋找正在被 hover 的 block
        const onHoverNodeID = state.elements.find(
          (el) => el.id === state.onHoverNode,
        )?.id;
        if (activeNode?.nodeId && onHoverNodeID) {
          if (activeNode?.nodeId === onHoverNodeID) {
            // 不能自己連線自己
            return;
          }
          if (activeNode?.handleId) {
            state.connectElement(activeNode?.handleId, onHoverNodeID);
          } else {
            const handleID = state.getElement(activeNode?.nodeId)?.id;
            if (handleID) {
              state.connectElement(handleID, onHoverNodeID);
            }
          }
        }
      }
    },
    [project, activeNode, setNodes, setEdges, state],
  );

  const handleNodesDragEnd = useCallback(
    (nodeChanges: Node[]) => {
      if (nodeChanges.length <= 0) return;
      state.updateElementPosition(nodeChanges);
    },
    [state],
  );

  /** 選取 edge 並按下 backspace */
  const handleEdgeDelete = useCallback(
    (edges: Edge[]) => {
      const targets = edges.map((e) => {
        if (e.sourceHandle) {
          return e.sourceHandle;
        } else {
          return e.source;
        }
      });
      state.removeConnects(targets);
    },
    [state],
  );

  const handleNodesDelete = useCallback(
    (nodes: Node[]) => {
      nodes.forEach((n) => {
        state.removeElement(n.id);
      });
    },
    [state],
  );

  const handleEdgeUpdate = useCallback(
    (oldEdge: Edge, newConnection: Connection) => {
      // 相同 node 的 element 不能互相連接
      if (newConnection.source === newConnection.target) {
        return;
      }
      // 改變連接線段的事件
      setEdges((els) => updateEdge(oldEdge, newConnection, els));

      // 移除線段
      if (oldEdge.sourceHandle) {
        state.removeConnects([oldEdge.sourceHandle]);
      } else {
        state.removeConnects([oldEdge.source]);
      }

      // 從子層或是外層連線傳送的參數不同，子層的 source 會繼承父層 node 的 id，所以必須傳 souceHandle 才會是內層的 handle id = element input id
      if (newConnection.sourceHandle && newConnection.target) {
        state.connectElement(newConnection.sourceHandle, newConnection.target);
        // 從 node 外層的連線只會有 source = element id
      } else if (newConnection.source && newConnection.target) {
        state.connectElement(newConnection.source, newConnection.target);
      }
    },
    [setEdges, state],
  );

  const handleEdgesChange = useCallback(
    (edgeChange: EdgeChange[]) => {
      onEdgesChange(edgeChange);
    },
    [onEdgesChange],
  );

  const getSelectedNodeBound = useCallback(() => {
    const selectedNodes = nodes.filter((item) => item?.selected === true);

    if (selectedNodes.length > 0) {
      const bounds = getNodesBounds(selectedNodes);

      const flowPosition = flowToScreenPosition({
        x: bounds.x,
        y: bounds.y,
      });

      return {
        x: flowPosition.x - flowContainerCoordinate.x,
        y: flowPosition.y - flowContainerCoordinate.y, // 36 是 floating panel 的高度
      };
    }
    return { x: 0, y: 0 };
  }, [
    flowContainerCoordinate.x,
    flowContainerCoordinate.y,
    flowToScreenPosition,
    nodes,
  ]);

  const handleNodesChange = useCallback(
    (nodeChange: NodeChange[]) => {
      onNodesChange(nodeChange);
    },
    [onNodesChange],
  );

  const targetElement = useMemo(
    () => getElement(targetElementID ?? ''),
    [getElement, targetElementID],
  );

  const setNodesSelected = useCallback(
    (idList: string[]) => {
      const newNodes = nodes.map((item) => {
        if (idList.includes(item.id)) {
          return { ...item, selected: true };
        } else {
          return item;
        }
      });

      if (newNodes.length > 0) {
        addSelectedNodes(newNodes.map((item) => item.id));
      }

      store.setState({
        nodesSelectionActive: true,
        userSelectionActive: false,
        multiSelectionActive: true,
      });
    },
    [addSelectedNodes, nodes, store],
  );

  useEffect(() => {
    if (!isNull(document)) {
      const containerRect = document
        ?.querySelector('.react-flow')
        ?.getBoundingClientRect();

      setFlowContainerCoordinate({
        x: containerRect?.x ?? 0,
        y: containerRect?.y ?? 0,
      });
    }
  }, []);

  return {
    nodes,
    edges,
    zoom,
    targetElement,
    isConnecting,
    onConnectStart,
    onConnectEnd,
    handleNodesDragEnd: handleNodesDragEnd,
    onNodesChange: handleNodesChange,
    onEdgesChange: handleEdgesChange,
    handleOnSelectionContextMenu,
    handleOnNodeContextMenu,
    handleSelectionChange,
    handleNodesDelete,
    handleEdgeDelete,
    handleEdgeUpdate,
    getSelectedNodeBound,
    setNodesSelected,
  };
}

export default useCanvasFlow;

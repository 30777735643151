export const environment = {
  production: false,
  mode: 'sit',
  facebookAppID: '878990966065939',
  meadEndpoint: 'https://mead-sit.goskyai.com',
  googleStorageEndpoint: 'https://storage.googleapis.com/malasun-sit',
  tapPayAppID: 153261,
  googleClientID:
    '295607523408-9tstoj9outm34edkkq6lhunaiuoep570.apps.googleusercontent.com',
  gtmCode: 'GTM-PVSB5ML5',
};

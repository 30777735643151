import { globalTheme } from '@frontend/components/external-providers';
import { MaterialStyledProps } from '@frontend/components/interface';
import { OptionTypeV2, PublishedFlowSelect } from '@frontend/components/ui';
import {
  useCanvasGet,
  useCanvasRemove,
  useCanvasUpdate,
  useCanvasView,
  useGetProjectID,
} from '@frontend/editor/data-access';
import { JumpToFlowType } from '@frontend/editor/interface';
import { useGetFlowListWithPublishStatus } from '@frontend/sorghum/data-access';
import { PAGE_FLOW, usePath } from '@frontend/sorghum/utils';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import { Box, Link, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { get } from 'lodash';
import { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { NodeProps, Position, useViewport } from 'reactflow';
import EditorBlockContainer from '../editor-block-container/editor-block-container';
import EditorFloatPanel from '../editor-float-panel/editor-float-panel';
import HandlePoint from '../handle-point/handle-point';
import NodeHeader from '../node-header/node-header';

interface ContainerStyledProps extends MaterialStyledProps {
  $focus?: boolean;
  $zoom?: number;
}

const ContainerStyled = styled(Box)<ContainerStyledProps>(
  ({ theme, $focus, $zoom }) => ({
    background: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '11px',
    cursor: 'move',
    fontSize: `${$zoom ? 28 / $zoom : 28}px`,
    boxShadow:
      '0px 3px 6px rgba(69, 90, 100, 0.15), 0px 2px 3px rgba(69, 90, 100, 0.12)',
    ...($focus && { border: `2px solid ${theme.palette['orange']['600']}` }),
  }),
);

const BodyStyled = styled(Box)<ContainerStyledProps>(({ theme, $focus }) => ({
  width: '316px',
  borderRadius: '0 0 12px 12px',
  display: 'flex',
  justifyContent: 'end',
  flexDirection: 'column',
  padding: '12px',

  '& > div': {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    background: theme.palette['grey']['100'],
    borderRadius: '8px',
    padding: '12px',
    ...($focus && { border: `2px solid ${theme.palette['orange']['50']}` }),
  },
}));

// edit / view 共用
export const NodeJumpToFlow = ({ id }: NodeProps) => {
  const [t] = useTranslation();
  const { zoom } = useViewport();
  const { onFocusID } = useCanvasView();
  const { data: projectID } = useGetProjectID();
  const { data: flowData } = useGetFlowListWithPublishStatus({
    projectId: projectID as string,
  });
  const { getTargetElement } = useCanvasGet();
  const { canvasUpdateLabel, canvasUpdateData } = useCanvasUpdate();
  const { navigateToProjectPage } = usePath();
  const { canvasRemoveBlockWithValidation } = useCanvasRemove();

  const { id: flowID } = useParams();
  const [isHoverBlock, setIsHoverBlock] = useState<boolean>(false);

  const { header, inputID, targetFlowID } = useMemo(() => {
    const elementData = getTargetElement(id) as JumpToFlowType;
    const header = get(elementData, 'label', '');
    const inputID = get(elementData, 'inputID', '');
    const targetFlowID = get(elementData, 'data.flowID', '');
    return {
      header,
      inputID,
      targetFlowID,
    };
  }, [getTargetElement, id]);

  const handleChangeFlow = useCallback(
    (newValue: OptionTypeV2 | null) => {
      canvasUpdateData(id, 'data.flowID', newValue?.value);
    },
    [canvasUpdateData, id],
  );

  const targetFlowData = useMemo(() => {
    const target = flowData?.flows.find((flow) => flow.id === targetFlowID);
    if (target) {
      return {
        flowName: target.name,
        flowID: target.id,
      };
    } else {
      return null;
    }
  }, [flowData, targetFlowID]);

  const onRemoveButtonClick = useCallback(() => {
    canvasRemoveBlockWithValidation(id);
  }, [id, canvasRemoveBlockWithValidation]);

  return (
    <EditorBlockContainer nodeID={id}>
      <HandlePoint
        id={inputID}
        type="target"
        position={Position.Left}
        styles={{
          top: '5%',
        }}
        isConnected={false}
        setIsHoverBlock={setIsHoverBlock}
      />
      <ContainerStyled
        $zoom={zoom}
        $focus={onFocusID === id}
        onMouseEnter={() => setIsHoverBlock(true)}
        onMouseLeave={() => setIsHoverBlock(false)}
      >
        {/* Block 上方的刪除和 ... 按鈕 */}
        {(isHoverBlock || onFocusID === id) && (
          <EditorFloatPanel
            direction="row"
            backgroundColor="rgba(96, 125, 139, 0.1)"
            handleDelete={onRemoveButtonClick}
          />
        )}
        <NodeHeader
          background={get(globalTheme, 'palette.orange.50', '')}
          color={get(globalTheme, 'palette.orange.600', '')}
          icon={<AccountTreeOutlinedIcon fontSize="small" />}
          title={header}
          onBlur={(val) => canvasUpdateLabel(id, val)}
        />
        <BodyStyled $focus={onFocusID === id}>
          <Box>
            <Typography variant="subtitle2" color="grey.900">
              {t('canvas.jumpToFlow.title')}
            </Typography>
            <PublishedFlowSelect
              onChange={(newValue: OptionTypeV2 | null) => {
                handleChangeFlow(newValue);
              }}
              errorMessage={
                targetFlowData && targetFlowData.flowName ? undefined : '  '
              }
              value={targetFlowID}
              excludeFlow={flowID}
            />
            {targetFlowData && (
              <Typography variant="body2" color="grey.600">
                {t('canvas.jumpToFlow.jumpTo')}&nbsp;
                <Link
                  onClick={() =>
                    navigateToProjectPage(
                      `${PAGE_FLOW}/${targetFlowData?.flowID}`,
                    )
                  }
                  variant="notoSans"
                  color="info.main"
                >
                  {targetFlowData?.flowName}
                </Link>
              </Typography>
            )}
          </Box>
        </BodyStyled>
      </ContainerStyled>
    </EditorBlockContainer>
  );
};

export default memo(NodeJumpToFlow);

import { Button } from '@frontend/components/ui';
import {
  Breakpoint,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Dialog as MuiDialog,
  SvgIconProps,
} from '@mui/material';
import { styled } from '@mui/system';
import { FC } from 'react';

/* eslint-disable-next-line */
export interface DialogProps {
  size: Breakpoint;
  color?: 'error';
  title?: string;
  content?: string;
  cancelBtnText?: string;
  confirmBtnText?: string;
  open: boolean;
  children?: React.ReactNode;
  handleClose: () => void;
  handleConfirm?: () => void;
  titleIcon?: (props: SvgIconProps) => JSX.Element;
  confirmBtnID?: string;
}

const DialogWrapperStyled = styled(MuiDialog)(({ theme }) => ({
  '& .MuiDialogTitle-root': {
    padding: theme.spacing(3),
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(3),
  },
}));

const DialogActionsStyled = styled(DialogActions)(({ theme }) => ({
  padding: `${theme.spacing(2.5)} ${theme.spacing(3)}`,
}));

export const Dialog: FC<DialogProps> = ({
  size,
  color,
  title,
  content,
  open,
  cancelBtnText,
  confirmBtnText,
  children,
  handleClose,
  handleConfirm,
  confirmBtnID = '',
}: DialogProps) => {
  return (
    <DialogWrapperStyled
      maxWidth={size}
      fullWidth
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText color="grey.800" variant="body1">
          {content}
        </DialogContentText>
        {children}
      </DialogContent>
      {(cancelBtnText || confirmBtnText) && (
        <DialogActionsStyled>
          {cancelBtnText && (
            <Button size="large" color={color} onClick={handleClose}>
              {cancelBtnText}
            </Button>
          )}
          {confirmBtnText && (
            <Button
              id={confirmBtnID}
              color={color}
              variant="contained"
              size="large"
              onClick={handleConfirm}
              autoFocus
            >
              {confirmBtnText}
            </Button>
          )}
        </DialogActionsStyled>
      )}
    </DialogWrapperStyled>
  );
};

export default Dialog;

import {
  OptionTypeV2,
  Switch,
  Textarea,
  Typography,
} from '@frontend/components/ui';
import {
  useGetProjectID,
  useProjectPermission,
} from '@frontend/editor/data-access';
import { useGetEditorLabelItems } from '@frontend/sorghum/data-access';
import {
  GetEditorLabelItemLabelType,
  WelcomeMessageForm,
} from '@frontend/sorghum/interface';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { get } from 'lodash';
import { FC, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import FlowSelect from '../flow-select/flow-select';

const MESSAGE_LIMIT = 160;

const ContentWrapper = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(0)} ${theme.spacing(1)}`,
  width: '100%',
  wordBreak: 'break-all',
}));

interface WelcomeMessageFacebookProps {
  setStep: (nextStep: 'flow' | 'greeting') => void;
  onUpdateFlowID: (data: { flowId: string; name: string }) => void;
  setFormData: (val: WelcomeMessageForm) => void;
  formData: WelcomeMessageForm;
  onlineFlow?: string;
}

export const WelcomeMessageFacebook: FC<WelcomeMessageFacebookProps> = ({
  setStep,
  onUpdateFlowID,
  setFormData,
  formData,
  onlineFlow,
}: WelcomeMessageFacebookProps) => {
  const [t] = useTranslation();

  const { data: projectID } = useGetProjectID();
  const { data: parameterData } = useGetEditorLabelItems(projectID as string);
  const { isViewer } = useProjectPermission();

  const handleFlowChange = (_flowData: OptionTypeV2 | null) => {
    setFormData({ ...formData, flowID: _flowData?.value as string });
  };

  const handleClickTextarea = useCallback(() => {
    if (formData.status) {
      setStep('greeting');
    }
  }, [formData.status, setStep]);

  const handleClickFlowSelect = useCallback(() => {
    if (formData.flowEnable) {
      setStep('flow');
    }
  }, [formData.flowEnable, setStep]);

  const handleToggleFlowSelect = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setFormData({ ...formData, flowEnable: checked });
      if (checked) {
        setStep('flow');
      } else {
        setStep('greeting');
      }
    },
    [formData, setFormData, setStep],
  );

  const handleToggleGreeting = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setFormData({ ...formData, status: checked });
      if (checked) {
        setStep('greeting');
      } else {
        if (formData.flowEnable) {
          setStep('flow');
        }
      }
    },
    [formData, setFormData, setStep],
  );

  const labelList = get(parameterData, 'data', []);

  return (
    <ContentWrapper>
      <Box display="flex">
        <Box flexGrow="1">
          <Typography variant="subtitle1" color="grey.black">
            <Trans i18nKey="basicSetting.welcomeMessage.subtitle" />
          </Typography>
        </Box>
        {/*Greeting Switch Button */}
        <Box sx={{ mr: 1 }}>
          <Switch
            size="small"
            onChange={handleToggleGreeting}
            checked={formData.status}
          />
        </Box>
      </Box>
      <Box mt={1} mb={1}>
        <Typography variant="body2" color="grey.600">
          <Trans i18nKey="basicSetting.welcomeMessage.content" />
        </Typography>
      </Box>
      {/* welcome message 輸入框 */}
      <Box style={{ width: '100%' }} onClick={handleClickTextarea}>
        <Textarea
          height={120}
          border
          defaultValue={formData.message}
          disabled={!formData.status}
          labelItem={labelList.filter(
            (item: GetEditorLabelItemLabelType) => item.type === 1,
          )}
          placeholder={t('basicSetting.welcomeMessage.preview.default')}
          onChange={(text) => setFormData({ ...formData, message: text })}
          limit={MESSAGE_LIMIT}
        />
      </Box>
      <Box mt={2} mb={1} display="flex">
        <Box flexGrow="1">
          <Typography variant="subtitle1" color="grey.black">
            <Trans i18nKey="basicSetting.welcomeMessage.subtitle2" />
          </Typography>
        </Box>
        {/*Flow Switch Button */}
        <Box sx={{ mr: 1 }}>
          <Switch
            size="small"
            onChange={handleToggleFlowSelect}
            checked={formData.flowEnable}
          />
        </Box>
      </Box>
      <Box sx={{ marginTop: '8px', marginBottom: '16px' }}>
        <Typography variant="body2" color="grey.600">
          <Trans i18nKey="basicSetting.welcomeMessage.content2" />
        </Typography>
      </Box>
      <Box onClick={handleClickFlowSelect}>
        <FlowSelect
          {...(formData.flowEnable && !formData.flowID && { error: '   ' })}
          disabled={!formData.flowEnable}
          socialType={1}
          value={formData.flowID || null}
          onChange={handleFlowChange}
          onlineFlow={onlineFlow}
          onUpdateFlowID={onUpdateFlowID}
          placeholder={t('basicSetting.welcomeMessage.flowSelectPlaceholder')}
          label={t('basicSetting.welcomeMessage.flowSelectLabel')}
          isAllowAddNewFlow={!isViewer}
        />
      </Box>
    </ContentWrapper>
  );
};

export default WelcomeMessageFacebook;

import { globalTheme } from '@frontend/components/external-providers';
import { Button } from '@frontend/components/ui';
import {
  useGetCategoryValue,
  useGetProjectID,
  useIsDrawerDataUpdate,
  usePostProjectAttributeKey,
  usePostProjectAttributeValue,
  useProjectPermission,
} from '@frontend/editor/data-access';
import { EditorCtx, UICtx } from '@frontend/editor/external-providers';
import {
  ActionAttributeType,
  ActionCellTypesEnum,
  AutocompleteOptionType,
  CategoryValueTypesEnum,
  DrawerTypesEnum,
} from '@frontend/editor/interface';
import { useGetAttributeKey } from '@frontend/sorghum/data-access';
import {
  HOW_TO_USE_ATTRIBUTE_EN,
  HOW_TO_USE_ATTRIBUTE_ZH,
} from '@frontend/sorghum/utils';
import { Box, Link, Typography } from '@mui/material';
import { styled } from '@mui/system';
import i18next from 'i18next';
import { get, isEmpty } from 'lodash';
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Autocomplete from '../autocomplete/autocomplete';
import { EditorDrawer } from '../editor-drawer/editor-drawer';

const ContainerStyled = styled('div')(({ theme }) => ({
  whiteSpace: 'break-spaces',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  padding: '24px',
}));

const RuleWrapperStyled = styled('div')(({ theme }) => ({
  marginTop: '-8px',
  display: 'flex',
  justifyContent: 'space-between',
}));

const ListContainerStyled = styled(Box)(({ theme }) => ({
  marginTop: '12px',

  ul: {
    marginBlockStart: 0,
    paddingInlineStart: '24px',
  },
}));

// 使用者不可新增與本列表相同名稱的 attribute
const restrictAttributeList = [
  'user_first_name',
  'user_last_name',
  'user_full_name',
  'incentive_name',
  'incentive_code',
  'coupon_name',
];

export const DrawerActionAttribute = () => {
  const { data: projectID } = useGetProjectID();
  const [t] = useTranslation();
  const {
    onFocusCellID,
    isDrawerUpdate,
    getElement,
    updateElementData,
    setIsDrawerUpdate,
  } = useContext(EditorCtx);
  const uiState = useContext(UICtx);

  const { elementID, actionType, attributeID, valueID, isAttribute } =
    useMemo(() => {
      const element = getElement(onFocusCellID) as ActionAttributeType;

      const elementID = get(element, 'id');
      const actionType = get(element, 'actionType');
      const attributeID = get(element, 'attributeID', '');
      const valueID = get(element, 'valueID', '');
      const isAttribute =
        actionType === ActionCellTypesEnum.SET ||
        actionType === ActionCellTypesEnum.REMOVE;
      return {
        elementID,
        actionType,
        attributeID,
        valueID,
        isAttribute,
      };
    }, [getElement, onFocusCellID]);

  const [attributeValue, setAttributeValue] = useState<string>('');
  const [equalValue, setEqualValue] = useState<string>('');
  const [equalName, setEqualName] = useState<string>('');
  const [attributeOptions, setAttributeOptions] = useState<
    AutocompleteOptionType[]
  >([]);
  const [attributeValueOptions, setAttributeValueOptions] = useState<
    AutocompleteOptionType[]
  >([]);
  const { isViewer } = useProjectPermission();
  const { data: attributeData } = useGetAttributeKey(projectID as string);
  const { data: attributeValueData } = useGetCategoryValue({
    projectID: projectID as string,
    type: CategoryValueTypesEnum.ATTRIBUTE,
    attributeID: attributeValue,
  });

  const { mutate: addAttribute } = usePostProjectAttributeKey(
    projectID as string,
  );

  const { mutate: addAttributeValue } = usePostProjectAttributeValue(
    projectID as string,
  );
  const { isDrawerDataUpdate } = useIsDrawerDataUpdate();

  const handleAddOption = useCallback(
    (option: AutocompleteOptionType) => {
      addAttribute(
        { key: option.label },
        {
          onSuccess: (res) => {
            if (res.code === 20000 && res.data.id) {
              setAttributeOptions((prev) => [
                ...prev,
                { label: option.label, value: res.data.id },
              ]);
              setAttributeValue(res.data.id);
            }
          },
        },
      );
    },
    [addAttribute],
  );

  const handleAddValueOption = useCallback(
    (option: AutocompleteOptionType) => {
      addAttributeValue(
        { value: option.label, attributeID: attributeValue },
        {
          onSuccess: (res) => {
            if (res.code === 20000 && res.data.id) {
              setAttributeValueOptions((prev) => [
                ...prev,
                { label: option.label, value: res.data.id },
              ]);
              setEqualValue(res.data.id);
              setEqualName(option.label);
            }
          },
        },
      );
    },
    [addAttributeValue, attributeValue],
  );

  const handleApply = useCallback(() => {
    if (!attributeValue) {
      return;
    }
    if (actionType === ActionCellTypesEnum.SET && !equalValue) {
      return;
    }

    updateElementData(elementID, 'attributeID', attributeValue);
    updateElementData(elementID, 'valueID', equalValue);
    updateElementData(elementID, 'valueName', equalName);
    uiState.setDrawerType(DrawerTypesEnum.CLOSE);
  }, [
    actionType,
    attributeValue,
    elementID,
    equalName,
    equalValue,
    uiState,
    updateElementData,
  ]);

  useEffect(() => {
    const newOptions: AutocompleteOptionType[] = [];
    if (
      !isEmpty(attributeValueData) &&
      attributeValueData &&
      attributeValueData.length > 0
    ) {
      attributeValueData.forEach((item) => {
        newOptions.push({
          value: item.id,
          label: item.value,
        });
      });
    }
    setAttributeValueOptions(newOptions);
  }, [attributeValueData]);

  useEffect(() => {
    const newOptions: AutocompleteOptionType[] = [];
    if (!isEmpty(attributeData) && attributeData && attributeData?.length > 0) {
      attributeData.forEach((item) => {
        newOptions.push({
          value: item.id,
          label: item.key,
        });
      });
    }
    setAttributeOptions(newOptions);
  }, [attributeData]);

  useEffect(() => {
    //防止從 SET 轉成 REMOVE 的時候還留著上一個 drawer 的值
    setAttributeValue('');
    setAttributeValue(attributeID);
    setEqualValue(valueID);
  }, [attributeID, valueID]);

  // 判斷 drawer 內的資料是否有更新
  useEffect(() => {
    if (uiState.drawerType === DrawerTypesEnum.ACTION_ATTRIBUTE) {
      if (actionType === ActionCellTypesEnum.SET) {
        if (
          isDrawerDataUpdate(
            { attrID: attributeID, valueID },
            { attrID: attributeValue, valueID: equalValue },
          )
        ) {
          setIsDrawerUpdate(true);
        } else {
          setIsDrawerUpdate(false);
        }
      } else if (actionType === ActionCellTypesEnum.REMOVE) {
        if (
          isDrawerDataUpdate(
            { attrID: attributeValue },
            { attrID: attributeID },
          )
        ) {
          setIsDrawerUpdate(true);
        } else {
          setIsDrawerUpdate(false);
        }
      }
    }
  }, [
    actionType,
    attributeID,
    attributeValue,
    equalValue,
    isDrawerDataUpdate,
    setIsDrawerUpdate,
    uiState.drawerType,
    valueID,
  ]);

  if (elementID && isAttribute) {
    return (
      <EditorDrawer drawerType={DrawerTypesEnum.ACTION_ATTRIBUTE}>
        <ContainerStyled>
          <Box>
            <Typography
              sx={{
                mb: '12px',
              }}
              variant="h6"
              color="grey.900"
            >
              {actionType === ActionCellTypesEnum.SET
                ? t('canvas.action.set.title')
                : t('canvas.action.remove.title')}
            </Typography>
            {actionType === ActionCellTypesEnum.SET && (
              <Box>
                <Typography variant="body2">
                  {t('canvas.action.set.description')}{' '}
                  <Link
                    sx={{
                      color: get(globalTheme, 'palette.info.main', ''),
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      window.open(
                        i18next.language.includes('en')
                          ? HOW_TO_USE_ATTRIBUTE_EN
                          : HOW_TO_USE_ATTRIBUTE_ZH,
                        '_blank',
                      )
                    }
                  >
                    {t('canvas.action.set.descriptionLink')}
                  </Link>
                </Typography>
                <ListContainerStyled>
                  <Typography variant="body2" color="grey.900">
                    {t('canvas.action.set.example')}
                  </Typography>
                  <Typography variant="body2" color="grey.900" component="div">
                    <ul>
                      <li>{t('canvas.action.set.example1')}</li>
                      <li>{t('canvas.action.set.example2')}</li>
                    </ul>
                  </Typography>
                </ListContainerStyled>
              </Box>
            )}

            <Autocomplete
              required
              placeholder={
                actionType === ActionCellTypesEnum.SET
                  ? t('canvas.action.drawer.addAttribute.placeholder')
                  : t('canvas.action.drawer.removeAttribute.placeholder')
              }
              disabled={isViewer}
              noOptionsText={t('common.noOptions')}
              label={t('canvas.action.drawer.name')}
              value={attributeValue}
              options={attributeOptions}
              addOption={
                actionType === ActionCellTypesEnum.SET
                  ? handleAddOption
                  : undefined
              }
              onChange={(option) => {
                setAttributeValue(option?.value as string);
                setEqualName('');
                setEqualValue('');
              }}
              error={
                !attributeValue ? t('canvas.action.drawer.nameRequired') : ''
              }
              maxLength={100}
              pattern={/[{},@\s]/}
              isShowTooltip
              restrictList={restrictAttributeList}
              restrictText={t('canvas.action.drawer.duplicateParameter')}
            />
            {actionType === ActionCellTypesEnum.SET && (
              <RuleWrapperStyled>
                <Box>
                  <Autocomplete
                    options={[
                      {
                        label: t('canvas.action.drawer.is'),
                        value: 'is',
                      },
                    ]}
                    disabled
                    value="is"
                  />
                </Box>
                <Box
                  sx={{
                    flexGrow: 1,
                    ml: '8px',
                  }}
                >
                  <Autocomplete
                    required
                    noOptionsText=""
                    addOption={handleAddValueOption}
                    label={t('canvas.action.drawer.value')}
                    placeholder={t(
                      'canvas.action.drawer.addAttribute.placeholder',
                    )}
                    disabled={!attributeValue || isViewer}
                    value={equalValue}
                    pattern={/[{},@\s]/}
                    error={
                      attributeValue && !equalValue
                        ? t('canvas.action.drawer.valueRequired')
                        : ''
                    }
                    onChange={(newValue) => {
                      setEqualName(newValue?.label as string);
                      setEqualValue(newValue?.value as string);
                    }}
                    options={attributeValueOptions}
                    maxLength={100}
                    isShowTooltip
                  />
                </Box>
              </RuleWrapperStyled>
            )}
          </Box>
          <Button
            onClick={handleApply}
            size="large"
            variant="contained"
            fullWidth
            disabled={!isDrawerUpdate}
            sx={{ marginTop: '24px' }}
          >
            <Trans i18nKey="common.apply" />
          </Button>
        </ContainerStyled>
      </EditorDrawer>
    );
  } else {
    return null;
  }
};

export default memo(DrawerActionAttribute);

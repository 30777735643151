import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import {
  Box,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import EllipsisMenu from '../ellipsis-menu/ellipsis-menu';
import MenuItem, { MenuItemProps } from '../menu/item/item';

interface TableTreeProps {
  menuList?: MenuItemProps[];
  menuListDisabled?: boolean;
  firstLayerChildren?: React.ReactNode;
  firstRowTitle?: string;
  isShowWarning?: boolean;
  warningTooltips?: string;
  secondLayerChildren?: React.ReactNode;
  isOpenSecondLayer: boolean;
  setIsOpenSecondLayer: (isOpenSecondLayer: boolean) => void;
  onClickFirstLayerTitle?: () => void;
}

const rotateIconStyle = {
  transform: 'rotate(180deg)',
};

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  '.cell-container': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  '.flex-end': {
    justifyContent: 'flex-end',
  },

  '.second': {
    paddingLeft: '24px',
  },

  '&.clickable': {
    cursor: 'pointer',
  },

  '&.overflow-ellipsis': {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

const TableRowStyled = styled(TableRow)(({ theme }) => ({
  '& > td': {
    backgroundColor: theme.palette['grey'][50],
  },
}));

const TableRowMaskStyled = styled(TableRow)<{ $height: number }>(
  ({ theme, $height }) => ({
    position: 'absolute',
    width: '100%',
    zIndex: '5',
    backdropFilter: 'blur(5px)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: `${$height}px`,
  }),
);

export const TableTreeSecondLayer = ({
  children,
  isOpenSecondLayer,
  isOpenThirdLayer,
  setIsOpenThirdLayer,
  name,
  thirdLayerChildren,
  isShowWarning,
  thirdLayerListLength, // 用來計算遮罩的高度
  warningText,
}: {
  children: React.ReactNode;
  isOpenSecondLayer: boolean;
  isOpenThirdLayer: boolean;
  setIsOpenThirdLayer: (isOpen: boolean) => void;
  name: string;
  thirdLayerChildren: React.ReactNode;
  isShowWarning: boolean;
  thirdLayerListLength: number;
  warningText?: string;
}) => {
  return (
    <>
      <TableRowStyled
        onClick={() => setIsOpenThirdLayer(!isOpenThirdLayer)}
        sx={{
          visibility: isOpenSecondLayer ? 'visible' : 'collapse',
        }}
      >
        <TableCellStyled component="td">
          <Box className="cell-container second">
            <ArrowDropDownOutlinedIcon
              sx={isOpenThirdLayer ? rotateIconStyle : null}
            />
            <Typography
              variant="body2"
              color="grey.700"
              sx={{ marginLeft: '16px' }}
            >
              {name}
            </Typography>
          </Box>
        </TableCellStyled>
        {children}
      </TableRowStyled>
      {/* mask */}
      {isShowWarning &&
        isOpenThirdLayer &&
        isOpenSecondLayer &&
        thirdLayerListLength > 0 && (
          <TableRowMaskStyled $height={thirdLayerListLength * 65}>
            <Typography variant="body1" color="grey.700">
              {warningText}
            </Typography>
          </TableRowMaskStyled>
        )}
      {thirdLayerChildren}
    </>
  );
};

// 目前僅可展開兩層，未來有需要再迭代
export function TableTree({
  menuList,
  menuListDisabled,
  firstLayerChildren,
  firstRowTitle,
  isShowWarning = false,
  warningTooltips = '',
  secondLayerChildren,
  isOpenSecondLayer, // comment reply 需要判斷第二層打開的時機來 call 資料的 API
  setIsOpenSecondLayer,
  onClickFirstLayerTitle,
}: TableTreeProps) {
  return (
    <>
      {/* first layer */}
      <TableRow>
        <TableCellStyled
          className="clickable overflow-ellipsis"
          component="td"
          onClick={onClickFirstLayerTitle}
        >
          <Box className="cell-container">
            <ArrowDropDownOutlinedIcon
              onClick={(e) => {
                e.stopPropagation();
                setIsOpenSecondLayer(!isOpenSecondLayer);
              }}
              sx={isOpenSecondLayer ? rotateIconStyle : null}
            />
            {/* renderNode */}
            {isShowWarning && (
              <Tooltip placement="top" title={warningTooltips}>
                <ReportProblemOutlinedIcon
                  sx={{ color: 'error.main', marginLeft: '6px' }}
                />
              </Tooltip>
            )}
            <Typography
              variant="body2"
              color="grey.700"
              sx={{
                marginLeft: '16px',
                width: '200px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {firstRowTitle}
            </Typography>
          </Box>
        </TableCellStyled>
        {firstLayerChildren}

        {menuList && menuList.length > 0 && (
          <TableCellStyled
            component="td"
            className="more-btn-column sticky-end"
          >
            <Box className="cell-container flex-end">
              <EllipsisMenu disabled={menuListDisabled}>
                {menuList.map((i) => (
                  <MenuItem
                    key={i.id}
                    disabled={i.disabled}
                    onClick={i.onClick}
                    content={i.content}
                  />
                ))}
              </EllipsisMenu>
            </Box>
          </TableCellStyled>
        )}
      </TableRow>
      {secondLayerChildren}
    </>
  );
}

import { GetProjectOutcomeResponseType } from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { PROJECT_OUTCOME } from '../apis';

const defaultValue: GetProjectOutcomeResponseType = {
  flowsCount: 0,
  customersUniqCount: 0,
  engagementCount: 0,
};
async function getGetProjectOutcome(): Promise<GetProjectOutcomeResponseType> {
  return axios
    .get(PROJECT_OUTCOME)
    .then((res) => res.data.data)
    .catch((_) => defaultValue);
}
export function useGetProjectOutcome(projectID: string | undefined) {
  return useQuery<GetProjectOutcomeResponseType>({
    queryKey: ['get-project-outcome', projectID],
    queryFn: () => {
      if (projectID) {
        return getGetProjectOutcome();
      } else {
        return defaultValue;
      }
    },
  });
}

export default useGetProjectOutcome;

import {
  PostAccountQuestionProps,
  Response,
  UseApi,
} from '@frontend/sorghum/interface';
import { QueryClient, useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { ACCOUNT_QUESTION } from '../apis';

async function postAccountQuestion(
  data: PostAccountQuestionProps,
): Promise<Response> {
  return axios.post(ACCOUNT_QUESTION, data).then((res) => {
    if (res.data.code !== 20000) {
      throw Error(res.data.code);
    }
    return res.data;
  });
}

export function usePostAccountQuestion(
  userName: string,
  queryClient: QueryClient,
  options?: UseApi<Response, PostAccountQuestionProps>,
) {
  return useMutation({
    mutationKey: ['account-question-post'],
    mutationFn: (props: PostAccountQuestionProps) => postAccountQuestion(props),
    onSuccess: (data, variables) => {
      queryClient.setQueryData(['get-account-info'], {
        userName,
        step: 'complete',
      });
      if (options?.onSuccess) options?.onSuccess(data, variables);
    },
  });
}

export default usePostAccountQuestion;

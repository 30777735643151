import { queryClient } from '@frontend/sorghum/external-providers';
import { PatchContactAttributeRequestType } from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { CONTACTS_ATTRIBUTE } from '../apis';

async function patchContactAttribute(
  props: PatchContactAttributeRequestType,
): Promise<PatchContactAttributeRequestType> {
  return axios
    .patch(`${CONTACTS_ATTRIBUTE}`, props)
    .then((res) => res.data)
    .catch((err) => {
      return { code: 400, msg: err };
    });
}

export function usePatchContactAttribute() {
  return useMutation({
    mutationKey: ['patch-contact-attribute'],
    mutationFn: (props: PatchContactAttributeRequestType) =>
      patchContactAttribute(props),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['get-contacts']);
      queryClient.invalidateQueries(['get-project-attribute-key']);
      queryClient.invalidateQueries(['get-category-value']);
    },
  });
}

export default usePatchContactAttribute;

import { EditorCtx } from '@frontend/editor/external-providers';
import { BaseElement } from '@frontend/editor/interface';
import { useCallback, useContext } from 'react';

export function useCanvasGet() {
  const { getElement } = useContext(EditorCtx);

  const getTargetElement = useCallback(
    (id: string) => {
      const targetElement = getElement(id);

      if (targetElement) {
        return targetElement;
      }

      return {} as BaseElement;
    },
    [getElement],
  );

  return { getTargetElement };
}

export default useCanvasGet;

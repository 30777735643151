import { Textarea } from '@frontend/components/ui';
import {
  TextButtonCellErrorEnum,
  TextButtonCellType,
  TextButtonElementType,
} from '@frontend/sorghum/interface';
import CellButtonList from '../cell-button-list/cell-button-list';
import {
  DisabledTextButtonMaskStyled,
  TextButtonContainerStyled,
  TextButtonInnerContainerStyled,
} from './styles';

export interface TextButtonProps {
  buttonData: TextButtonElementType;
  textareaLabelItem: { type: number; name: string }[];
  disabled?: boolean;
  updateButtonData: (elementID: string, value: TextButtonElementType) => void;
  updateCellData: (
    elementID: string,
    id: string,
    data: TextButtonCellType,
  ) => void;
  errorList: {
    id: string;
    type: TextButtonCellErrorEnum;
    errorText?: string;
  }[];
  placeholder?: string;
  error?: boolean;
  deleteCell: (elementID: string, cellID: string) => void;
  addCell: (elementID: string) => void;
  updateCellListData: (elementID: string, data: TextButtonCellType[]) => void;
  elementID: string;
  setErrorList: (
    list: {
      id: string;
      type: TextButtonCellErrorEnum;
      errorText?: string;
    }[],
  ) => void;
  isDisableAddButton?: boolean;
}

export function TextButton({
  buttonData,
  textareaLabelItem,
  disabled = false,
  updateButtonData,
  updateCellData,
  errorList,
  setErrorList,
  placeholder,
  error = false,
  deleteCell,
  addCell,
  updateCellListData,
  elementID,
  isDisableAddButton,
}: TextButtonProps) {
  return (
    <TextButtonContainerStyled>
      {disabled && <DisabledTextButtonMaskStyled />}
      <TextButtonInnerContainerStyled>
        <Textarea
          maxHeight={196}
          overflow="scroll"
          border={error ? false : true}
          disabled={disabled}
          labelItem={textareaLabelItem}
          defaultValue={buttonData?.data?.text}
          onChange={(text) =>
            updateButtonData(elementID, { ...buttonData, data: { text } })
          }
          placeholder={placeholder}
          limit={640}
          publishError={error}
        />
        <CellButtonList
          elementID={elementID}
          list={buttonData?.cells ?? []}
          updateCellData={updateCellData}
          errorList={errorList}
          deleteCell={deleteCell}
          addCell={addCell}
          updateCellListData={updateCellListData}
          setErrorList={setErrorList}
          disabled={disabled}
          isDisableAddButton={isDisableAddButton}
        />
      </TextButtonInnerContainerStyled>
    </TextButtonContainerStyled>
  );
}

export default TextButton;

import { PROJECT } from '@frontend/sorghum/data-access';
import {
  PAGE_NOT_FOUND,
  PAGE_PERMISSION_DENIED,
} from '@frontend/sorghum/utils';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useParams } from 'react-router-dom';

async function getProject(id: string): Promise<string> {
  return axios
    .get(PROJECT, {
      params: {
        ...(id.length > 10 && { projectId: id }),
        ...(id.length <= 10 && { shortCode: id }),
      },
    })
    .then((res) => {
      if (res.data.code === 20000) {
        sessionStorage.setItem('project-id', res.data.data.projectId);
        localStorage.setItem('project-id', res.data.data.projectId);
        return res.data.data.projectId;
        // 40000 代表沒有專案權限
      } else if (res.data.code === 40000) {
        window.location.href = PAGE_PERMISSION_DENIED;
        return '';
      } else {
        sessionStorage.removeItem('project-id');
        window.location.href = PAGE_NOT_FOUND;
        return '';
      }
    })
    .catch((_) => {
      sessionStorage.removeItem('project-id');
      window.location.href = PAGE_NOT_FOUND;
      return '';
    });
}

export function useGetProjectID() {
  const { projectID } = useParams();

  return useQuery<string>({
    queryKey: ['get-project-id', { projectID }],
    queryFn: () => {
      if (projectID) return getProject(projectID);
      else return '';
    },
  });
}

export default useGetProjectID;
